/**
 * Double check confirmation modal component that allows user to confirm his actions  
*/
import React, { useState } from "react";
import Modal from "../Modal";
import Button from "../Button";
import Checkbox from "../Checkbox";

export interface ConfirmationModalProps {
    /**
     * Whether the modal is visible or not.
     */
    visible: boolean;

    /**
     * Whether the modal is disabled or not.
     */
    disabled: boolean;

    /**
     * Function for setting the visible state passed by the parent
     */
    visibleHandler?: (visible: boolean) => void;

    /**
     * Title to be displayed in the modal
     */
    heading: string;

    /**
     * Message to be displayed in the modal
     */
    message?: string;

    /**
     * Accept button text
     */
    acceptBtnText: string;

    /**
     * Cancel button text
     */
    cancelBtnText: string;

    /**
     * Callback for accepting modal function
     */
    onAccept?: () => void;

    /**
     * Callback for rejecting modal function
     */
    onCancel: () => void;

    /**
     * Callback handling if modal is disabled by user
     */
    onDisable: (disabled: boolean) => void;

    size?: "sm" | "md" | "lg";
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    visible,
    disabled,
    visibleHandler,
    heading,
    message,
    acceptBtnText,
    cancelBtnText,
    onAccept,
    onCancel,
    onDisable,
    size,
}) => {
    const [isDoubleCheckDisabled, setIsdoubleCheckDisabled] = useState<boolean>(disabled);
    return (
        <Modal visible={visible} visibleHandler={visibleHandler} size={size}>
            {" "}
            <div className="p-8 imanol">
                <h2 className="text-2xl text-bold">{heading}</h2>
                <p className="text-bold py-4">{message}</p>
                <Checkbox
                    label="No volver a mostrar"
                    onChange={() => {
                        setIsdoubleCheckDisabled(!isDoubleCheckDisabled);
                        onDisable(isDoubleCheckDisabled);
                    }}
                    checked={isDoubleCheckDisabled}
                />
                <div className="flex flex-row w-full items-center justify-end pt-8">
                    <div>
                        <Button label={cancelBtnText} type="btn_transparent" onClick={onCancel} />
                    </div>

                    <div className="ml-2">
                        <Button label={acceptBtnText} onClick={onAccept} type="btn_dark" />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
