/* eslint-disable import/no-extraneous-dependencies */
import "../mokenCalendar.css";

import React from "react";
import Flatpickr, { DateTimePickerProps } from "react-flatpickr";
import classNames from "classnames";

export interface DateRangePickerProps {
    idStart?: string;
    idEnd?: string;
    /**
     * Text describing the start/end inputs.
     */
    labelStart?: string;
    labelEnd?: string;
    /**
     * Placeholder text shown in the input, in a lighter color.
     */
    placeholderStart?: string;
    placeholderEnd?: string;
    /**
     * Whether the text input is required in the form it lives in.
     */
    required?: boolean;
    /**
     * Whether the input is disabled or not.
     */
    disabledStart?: boolean;
    disabledEnd?: boolean;
    /**
     * Default value of the input, in case it should be pre-filled.
     */
    defaultValueStart?: Date;
    defaultValueEnd?: Date;
    /**
     * Value of the input, in case it should be managed by the parent.
     */
    valueStart?: Date;
    valueEnd?: Date;
    /**
     * Whether the input field is editable directly
     */
    editableStart?: boolean;
    editableEnd?: boolean;
    /**
     * Optional onChange handler.
     */
    onChangeStart?: DateTimePickerProps["onChange"];
    onChangeEnd?: DateTimePickerProps["onChange"];
}

/**
 * Input field which the user can use to select dates, times and datetimes.
 */
const DateRangePicker: React.FC<DateRangePickerProps> = ({
    idStart,
    idEnd,
    labelStart,
    labelEnd,
    placeholderStart,
    placeholderEnd,
    required,
    disabledStart,
    disabledEnd,
    defaultValueStart,
    defaultValueEnd,
    valueStart,
    valueEnd,
    editableStart,
    editableEnd,
    onChangeStart,
    onChangeEnd,
}) => {
    const format = "d/m/Y";

    const formatDateForInput = (date: Date | undefined): string => {
        let dateStr = "";
        if (date) {
            // Dates could be passed as strings and JS would not complain until it tries to call the functions below,
            // so make it a date explicitly just in case
            const dateVal = new Date(date);
            dateStr = `${dateVal.getDate().toString().padStart(2, "0")}/${(dateVal.getMonth() + 1)
                .toString()
                .padStart(2, "0")}/${dateVal.getFullYear()}`;
        }
        return dateStr;
    };

    const optsStart = {
        wrap: true,
        allowInput: editableStart,
        dateFormat: format,
    };

    const optsEnd = {
        wrap: true,
        allowInput: editableEnd,
        dateFormat: format,
    };

    const commonClasses = classNames(
        "appearance-none border w-full py-2 px-4 shadow",
        "placeholder-grey-light-1 placeholder-opacity-50 leading-tight resize-none font-sans",
        "focus:outline-none focus:border-yellow focus:shadow-outline",
        "bg-white focus:bg-white border-blue-light-3 hover:border-blue-light-1 cursor-pointer"
    );

    return (
        <div className="flex w-full">
            <Flatpickr options={optsStart} onChange={onChangeStart} className="basis-1/2 h-full">
                <div className="flex flex-col relative">
                    {labelStart && (
                        <label
                            htmlFor={idStart}
                            className="absolute left-2 -top-3 bg-white px-2 font-sans text-xs text-blue-light-2"
                        >
                            <div className="inline-block space-x-1.5">
                                <span>{labelStart}</span>
                            </div>
                        </label>
                    )}

                    <input
                        id={idStart}
                        name={idStart}
                        type="text"
                        placeholder={placeholderStart}
                        required={required}
                        disabled={disabledStart}
                        data-input
                        defaultValue={!valueStart ? formatDateForInput(defaultValueStart) : undefined}
                        value={valueStart && formatDateForInput(valueStart)}
                        className={classNames(
                            commonClasses,
                            "rounded-l-md h-[56px] sm:h-10",
                            disabledStart ? "text-grey cursor-default" : "text-blue-dark"
                        )}
                    />
                </div>
            </Flatpickr>

            <Flatpickr options={optsEnd} onChange={onChangeEnd} className="basis-1/2">
                <div className="flex flex-col relative">
                    {labelEnd && (
                        <label
                            htmlFor={idEnd}
                            className="absolute left-2 -top-3 bg-white px-2 font-sans text-xs text-gray-400"
                        >
                            <div className="inline-block space-x-1.5">
                                <span>{labelEnd}</span>
                            </div>
                        </label>
                    )}

                    <input
                        id={idEnd}
                        name={labelEnd}
                        type="text"
                        placeholder={placeholderEnd}
                        required={required}
                        disabled={disabledEnd}
                        data-input
                        value={valueEnd ? formatDateForInput(valueEnd) : formatDateForInput(defaultValueEnd)}
                        className={classNames(
                            commonClasses,
                            "rounded-r-md h-[56px] sm:h-10",
                            disabledEnd ? "text-grey cursor-default" : "text-blue-dark"
                        )}
                    />
                </div>
            </Flatpickr>
        </div>
    );
};

export default DateRangePicker;
