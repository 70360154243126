/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import Icon from "@mui/material/Icon";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";

export interface FileUploadZoneProps {
    /**
     * Types of files that the file upload accepts.
     *
     * For example, "image/*" will accept any image file, and "image/png" will accept only images
     * in the PNG format. It can have multiple types separated by commas, for example to accept
     * images in PNG or JPEG format, this could be "image/png, image/jpeg".
     *
     * The type specifiers have to be one of the following:
     * - A file extension starting with the STOP character (U+002E). (e.g. .jpg, .png, .doc).
     * - A valid MIME type with no extensions.
     * - audio/* representing sound files.
     * - video/* representing video files.
     * - image/* representing image files.
     *
     * If not set, it will accept all files by default.
     */
    accept?: string;

    /**
     * makes upload form horizontal if present
     */
    horizontal?: boolean;

    /**
     * Allows to add custom text to the file uploader
     */
    customText?: string;

    /**
     * Maximum accepted file size, in bytes.
     * Trying to upload a file over the max size will not show a rejected outline in the
     * box, but after dropping it, it will not be uploaded, i.e., not passed into `acceptedFiles`.
     *
     * If not set, there is no limit by default (infinity).
     */
    maxSize?: number;

    /**
     * Whether to allow upload of multiple files at once.
     * By default it is false.
     */
    multiple?: boolean;

    /**
     * Custom message to show in the drag and drop zone.
     * If not set it will show a default message.
     */
    message?: string;

    /**
     * Handler that is called every time the list of accepted files changes.
     */
    onChangeFiles: (files: File[]) => void;
}

/**
 * Zone in which files can be uploaded by drag and dropping them, or alternatively,
 * the zone can be clicked for a file selector to pop up.
 */
const FileUploadZone: React.FC<FileUploadZoneProps> = ({
    accept,
    maxSize,
    message,
    horizontal,
    customText,
    onChangeFiles,
    multiple = false,
}) => {
    const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        accept,
        maxSize,
        multiple,
    });

    useEffect(() => {
        acceptedFiles.length && onChangeFiles(acceptedFiles);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptedFiles]);

    // TODO: Translate default text
    let boxText;
    if (isDragReject) {
        boxText = "Alguno de los ficheros que intentas subir no está permitido";
    } else if (message === undefined) {
        boxText = customText || "Arrastra tu documento aqui o haz clic para buscarlo";
    } else {
        boxText = message;
    }

    return (
        <section className="container">
            <div
                {...getRootProps({
                    className: classNames(
                        "w-full flex items-center bg-white p-10 cursor-pointer font-bold",
                        "rounded-md border-2",
                        isDragActive ? "border-solid" : "border-platinum-dark-2 border-dashed text-platinum-dark-2",
                        isDragAccept ? "border-yellow text-platinum" : null,
                        isDragReject ? "border-red text-platinum-dark-2" : null,
                        !horizontal ? "flex-col" : "gap-4"
                    ),
                })}
            >
                <input {...getInputProps()} />
                <Icon baseClassName="material-icons-outlined" sx={{ fontSize: "104px" }}>
                    {isDragReject ? "block" : "cloud_upload"}
                </Icon>
                <p className="pt-2">{boxText}</p>
            </div>
        </section>
    );
};

export default FileUploadZone;
