import React, { useState } from "react";
import { useAuth } from "../auth";
import Button from "../components/Button";
import TextInput from "../components/TextInput";
import useAlert from "../useAlert";

const PasswordReset: React.FC = () => {
    const auth = useAuth();
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const { addAlert } = useAlert();
    // Get token
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token") || "";

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        if (password !== passwordConfirm) {
            addAlert("Las contraseñas no coinciden", "error");
            return;
        }
        auth.resetConfirm(token, password).then((resetStatus) => {
            if (resetStatus.error) {
                addAlert(resetStatus.error, "error");
                return;
            }
            addAlert("Contraseña reiniciada correctamente", "success");
        });
    };

    return (
        <div className="h-screen w-full  font-sans">
            <div className="absolute top-12 left-12 w-40">
                <img src="/static/mokenapp/logo_grey.png" alt="service logo" />
            </div>
            <div className="flex flex-row h-full">
                <div className="flex flex-col items-start pt-28 sm:pt-0 sm:justify-center w-full md:w-1/2 h-full bg-blue-light-4 px-8 md:px-12 lg:px-24">
                    <p className="font-sans font-bold text-2xl text-blue">Restablecer contraseña</p>
                    <p className="font-sans font-thin mb-8 mr-24 text-blue">Introduce tu nueva contraseña.</p>
                    <form id="passwordrecoveryform" onSubmit={handleSubmit} className="w-full text-center">
                        <div className="w-full">
                            <TextInput
                                id="password"
                                type="password"
                                label="Contraseña"
                                placeholder="Nueva contraseña"
                                onChange={(ev) => setPassword(ev.currentTarget.value)}
                            />
                        </div>
                        <div className="w-full pt-8 pb-4">
                            <TextInput
                                id="passwordConfirm"
                                type="password"
                                label="Confirmar contraseña"
                                placeholder="Repetir contraseña"
                                onChange={(ev) => setPasswordConfirm(ev.currentTarget.value)}
                            />
                        </div>
                        <hr className="mt-4 pb-8 border-blue-light-3" />
                        <div className="w-full flex justify-end">
                            <Button submit type="btn_yellow" label="Restablecer contraseña" extraClass="w-full sm:w-auto h-[56px] sm:h-auto"/>
                        </div>
                    </form>
                    <p className="font-sans text-center text-blue mt-8 w-full text-xl sm:text-base">
                        Volver a
                        <a className=" ml-2 text-yellow hover:text-yellow-dark-2" href="/login">
                            Log in
                        </a>
                    </p>
                </div>
                <div className="hidden md:flex flex-col items-center justify-center w-1/2 h-full px-8 lg:px-16 bg-gradient-to-t from-yellow-dark-2 to-yellow">
                    <img src="/static/mokenapp/login_history.png" alt="requests" />
                    <p className="font-sans text-white font-bold text-2xl mt-12">Un histórico de verdad</p>
                    <p className="font-sans text-white">
                        Consulta todo lo que ha pasado con tu propuesta desde el primer momento y de forma rápida.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PasswordReset;
