import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "@mui/material/Icon";
import SlideDown from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export interface SidebarMenuItemProps {
    /**
     * Label shown in the sidebar for the menu item.
     */
    label: string;
    /**
     * Icon which will appear to the left of the label.
     */
    icon?: string;
    /**
     * Optional number which will be shown in a yellow box to the right of the menu
     * item, and can be used for example to notify the user of things to review in the
     * related screen, like new messages in the inbox.
     */
    numberBox?: number;
    /**
     * A menu item can optionally have a submenu with other items. In that case, clicking
     * on the menu item will show/collapse the submenu.
     */
    children?: React.ReactNode;
    /**
     *
     */
    isSubmenuItem?: boolean;
    /**
     * Path that this menu item links to in the router.
     */
    linkTo?: string;
    /**
     * Click handler, managed by the parent.
     */
    onClick?: () => void;
}

/**
 * An clickable item in the sidebar, which will usually correspond to a specific model
 * and show the relevant screen upon clicking.
 *
 * Simple menu items have a label, optionally an icon, and will be highlighted when the
 * user is seeing the associated page.
 *
 * Menu items can also contain submenus. In this case, the menu item does not correspond
 * to a page directly, and clicking it will just open/collapse the submenu. Selecting an
 * item in the submenu which does correspond to a page will highlight the whole submenu,
 * but only show as "selected" (with white text) the actual selected submenu item and
 * the parent menu.
 *
 * A menu item can also show a number in a box to the right. These can correspond, for example,
 * to the number of new messages in the inbox menu. Items which have a submenu can't hold
 * a specific number to show, instead they will show the sum of the numbers of all the submenu
 * items when the submenu is collapsed, and will show nothing when the submenu is open, as in
 * that case the numbers are already shown by each individual submenu item.
 */
const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({
    label,
    icon,
    numberBox,
    children,
    linkTo,
    onClick,
    isSubmenuItem = false,
}) => {
    const [submenuExpanded, setSubmenuExpanded] = useState(false);
    const [submenuPaths, setSubmenuPaths] = useState<Set<string>>(new Set<string>());

    const location = useLocation();
    const pathname = location.pathname;
    const currentScreen = pathname.split("/")[1];

    const selected = currentScreen === linkTo?.split("/")[1];

    const hasSubmenu = children !== undefined;
    const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isOpen);

    // For items with a submenu, get the paths of the submenu items so that we can identify when a submenu item
    // is selected to properly highlight the parent element
    useEffect(() => {
        const submenuPathsAux = new Set<string>();
        React.Children.map(children, (child) => {
            const linkToChild = (child as React.ReactElement<SidebarMenuItemProps>).props.linkTo;
            if (linkToChild !== undefined) {
                submenuPathsAux.add(linkToChild);
            }
        });
        setSubmenuPaths(submenuPathsAux);
    }, [children]);
    const highlighted = submenuPaths.has(pathname);

    const handleClick = () => {
        if (hasSubmenu) {
            // Expand or collapse the submenu
            setSubmenuExpanded(!submenuExpanded);
        } else {
            onClick && onClick();
        }
    };

    const itemClickable = (
        <div
            role="menuitem"
            tabIndex={0}
            className={classNames(
                "flex grow items-center mx-6 px-4 py-4  my-1 sm:py-2.5 rounded-md font-bold hover:text-yellow",
                selected && !hasSubmenu
                    ? "bg-gradient-to-r from-white-transparent to-blue-transparent bg-opacity-20"
                    : null,
                highlighted && hasSubmenu ? "text-white" : null,
                selected ? "text-yellow" : "text-grey-light-1",
                !isSidebarOpen && "px-1 lg:px-1 mx-0 sm:mx-6 "
            )}
            onClick={handleClick}
            onKeyPress={handleClick}
        >
            <div className="flex grow items-center">
                <div className={classNames("flex-none w-7 justify-center", isSubmenuItem && isSidebarOpen && "pl-10")}>
                    {icon ? (
                        <div className="flex flex-col items-center">
                            <Icon sx={{ fontSize: "21px sm:19px" }}>{`${icon}`}</Icon>
                        </div>
                    ) : null}
                </div>
                {isSidebarOpen && (
                    <span
                        className={classNames(
                            "grow ml-2 text-md sm:text-sm",
                            isSubmenuItem && "pl-4 relative top-[2px]"
                        )}
                    >
                        {label}
                    </span>
                )}
            </div>
            <div
                className={classNames(
                    "px-2 py-1",
                    numberBox &&
                        "flex shrink h-6 items-center justify-center font-sans text-xs text-blue rounded-full bg-yellow"
                )}
            >
                {numberBox && numberBox > 0 ? numberBox : null}
            </div>
            <div className="flex items-center flex-none w-4 pl-2">
                {hasSubmenu ? (
                    <Icon sx={{ fontSize: "19px" }}>{`${submenuExpanded ? "expand_more" : "chevron_right"}`}</Icon>
                ) : null}
            </div>
        </div>
    );

    return (
        <div className="flex w-full cursor-pointer">
            <div className={`${linkTo?.split("/")[1]} flex-col w-full`}>
                {isSidebarOpen ? (
                    <>
                        {linkTo ? <Link to={linkTo}>{itemClickable}</Link> : itemClickable}
                        {hasSubmenu ? (
                            <SlideDown closed={!submenuExpanded && isSidebarOpen}>
                                {submenuExpanded || !isSidebarOpen
                                    ? React.Children.map(children, (child) =>
                                          React.cloneElement(child as React.ReactElement<SidebarMenuItemProps>, {
                                              isSubmenuItem: true,
                                          })
                                      )
                                    : null}
                            </SlideDown>
                        ) : null}
                    </>
                ) : (
                    <>
                        {linkTo && !hasSubmenu && <Link to={linkTo}>{itemClickable}</Link>}
                        {React.Children.map(children, (child) =>
                            React.cloneElement(child as React.ReactElement<SidebarMenuItemProps>, {
                                isSubmenuItem: true,
                            })
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default SidebarMenuItem;
