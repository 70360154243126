import axios from "axios";

/**
 * Name of the string key with which the JWT token will be stored in the local storage.
 */
export const TOKEN_KEY = "token";

const axiosApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 900000,
});

axiosApi.interceptors.request.use(
    async (config) => {
        const newConfig = config;
        newConfig.headers = {
            accept: "application/json",
            "Content-Type": "application/json",
        };
        const token = localStorage.getItem(TOKEN_KEY);
        if (token) {
            const { access } = JSON.parse(token);
            newConfig.headers.Authorization = `Bearer ${access}`;
        }
        return newConfig;
    },
    (error) => {
        Promise.reject(error);
    }
);

axiosApi.interceptors.response.use(
    (response) => response,
    (err) => {
        // For errors unrelated to authorization, just reject promise
        // Also do this if the error comes from a token refresh, to avoid an infinite request loop,
        // since this means the refresh token is also expired
        if (
            !err.response ||
            err.response.status !== 401 ||
            err.response.config.url === "/token/refresh/" ||
            window.location.pathname === "/login"
        ) {
            return Promise.reject(err);
        }

        // Try to refresh the token
        const userString = localStorage.getItem(TOKEN_KEY);
        if (!userString) {
            // Redirect to login page
            // TODO: Show notification telling the user their session has expired before redirecting to login page
            window.location.href = "/login";
            return Promise.reject(err);
        }
        const user = JSON.parse(userString);

        return axiosApi
            .post("/token/refresh/", { refresh: user.refresh })
            .then((response) => {
                const { config } = err.response;
                const { access, refresh } = response.data;
                if (access && refresh) {
                    const updatedUser = { ...user, access, refresh };
                    localStorage.setItem(TOKEN_KEY, JSON.stringify(updatedUser));
                    config.headers.Authorization = `Bearer ${access}`;
                } else {
                    // Refresh worked but something is missing
                    localStorage.removeItem(TOKEN_KEY);
                    window.location.href = "/login";
                    return Promise.reject();
                }
                // Call the original query again with the new access token
                return axiosApi(config);
            })
            .catch((error) => {
                localStorage.removeItem(TOKEN_KEY);
                // Redirect to login page
                // TODO: Show notification telling the user their session has expired before redirecting to login page
                // TODO: Find a better way to redirect, maybe custom history object?
                window.location.href = "/login";
                return Promise.reject(error);
            });
    }
);

export default axiosApi;
