/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface HeaderState {
    /**
     * ID of the model that is open, for example, a reservation, to show it in the header next to the model type.
     */
    modelId: number;

    /**
     * Name, title or description of the open model, in case we want to show it instead of the ID, for example for clients.
     */
    modelName: string | null;

    nameVisibility: boolean;
}

const initialState: HeaderState = {
    modelId: -1,
    modelName: null,
    nameVisibility: false,
};

export const headerSlice = createSlice({
    name: "header",
    initialState,
    reducers: {
        openModel: (
            state,
            {
                payload: { modelId = -1, modelName = null },
            }: PayloadAction<{ modelId: number; modelName?: string | null }>
        ) => {
            state.modelId = modelId;
            state.modelName = modelName;
        },
        closeModel: (state) => {
            state.modelId = -1;
            state.modelName = null;
        },
        showName: (state) => {
            state.modelId = -1;
            state.nameVisibility = true;
        },
        hideName: (state) => {
            state.nameVisibility = false;
        },
    },
});

export const { openModel, closeModel, showName, hideName } = headerSlice.actions;

export default headerSlice.reducer;
