import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth";
import Button from "../components/Button";
import TextInput from "../components/TextInput";
import useAlert from "../useAlert";

const Login: React.FC = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token") || "";
    const navigate = useNavigate();
    const auth = useAuth();
    const { addAlert } = useAlert();

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        auth.logIn(username, password).then((loginStatus) => {
            if (loginStatus.success) {
                navigate("/");
            } else if (loginStatus.error) {
                addAlert(loginStatus.error, "error");
            }
        });
    };

    useEffect(() => {
        if (token.length > 0) {
            auth.emailConfirm(token).then((confirmStatus) => {
                if (confirmStatus.error) {
                    addAlert(confirmStatus.error, "error");
                    return;
                }
                addAlert("Tu e-mail ha sido verificado", "success");
            });
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [token]);

    return (
        <div className="h-screen w-full  font-sans">
            <div className="absolute top-12 left-12 w-40">
                <img src="/static/mokenapp/logo_grey.png" alt="service logo" />
            </div>
            <div className="block  sm:flex sm:flex-col lg:flex-row h-full min-h-[540px]">
                <div className="flex flex-col items-start pt-28 sm:pt-0 sm:justify-center w-full lg:w-1/2 h-full bg-blue-light-4 px-8 px-8 md:px-16">
                    <p className="font-sans font-bold text-2xl text-blue">Log in</p>
                    <p className="font-sans font-thin mb-8 text-blue">
                        Bienvenido a Moken. Introduce tus credenciales para acceder a la plataforma.
                    </p>
                    <form id="loginform" onSubmit={handleSubmit} className="w-full text-right">
                        <div className="flex flex-col w-full sm:flex-row items-center justify-between gap-8 sm:gap-4 pb-8 w-full">
                            <div className="w-full basis-1/2">
                                <TextInput
                                    id="username"
                                    type="text"
                                    label="Usuario"
                                    onChange={(ev) => setUsername(ev.currentTarget.value)}
                                />
                            </div>
                            <div className="w-full basis-1/2">
                                <TextInput
                                    id="password"
                                    type="password"
                                    label="Contraseña"
                                    onChange={(ev) => setPassword(ev.currentTarget.value)}
                                />
                            </div>
                        </div>
                        <a className="font-sans text-blue font-thin" href="/recovery">
                            ¿Contraseña olvidada?
                        </a>
                        <hr className="mt-8 pb-4 border-blue-light-3" />
                        <div className="flex flex-row items-center justify-end pb-8 w-full">
                           
                                <Button submit label="Acceder" type="btn_yellow" extraClass="w-full sm:w-auto h-[56px] sm:h-auto"/>
                            
                        </div>
                    </form>
                    <div className="font-sans flex flex-col sm:flex-row justify-center items-center text-blue w-full pt-8">
                        <span>¿Todavía no tienes cuenta Moken?</span>
                        
                        <a className="text-yellow hover:text-yellow-dark-2  pt-8 sm:pl-4 sm:pt-0" href="/register">
                            Regístrate
                        </a>
                    </div>
                </div>
                <div className="hidden lg:flex flex-col items-center justify-center w-full lg:w-1/2 h-full p-12 bg-gradient-to-t from-yellow-dark-2 to-yellow">
                    <img src="/static/mokenapp/login_dash.png" alt="dashboard" />
                    <p className="font-sans text-white text-center font-bold text-2xl mt-12">
                        Controla tu agencia de viajes desde un único espacio
                    </p>
                    <p className="font-sans text-white text-center pt-2">
                        Moken te ayuda a automatizar la gestión de presupuestos a medida de una forma rápida e
                        intuitiva.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;
