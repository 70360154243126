/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export interface ReservationsState {
    /**
     *
     */
    isdataPending: boolean;
}

const initialState: ReservationsState = {
    isdataPending: false,
};

export const rervationsSlice = createSlice({
    name: "Reservations",
    initialState,
    reducers: {
        saveData: (state) => {
            state.isdataPending = false;
        },
        dataPending: (state) => {
            state.isdataPending = true;
        },
    },
});

export const { saveData, dataPending } = rervationsSlice.actions;

export default rervationsSlice.reducer;
