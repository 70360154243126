/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Modal from "../Modal";
import Button from "../Button";

export interface CountrySelectModalProps {
    /**
     * Whether the modal is visible or not.
     */
    visible: boolean;

    /**
     * Function for setting the visible state passed by the parent
     */
    visibleHandler?: (visible: boolean) => void;

    /**
     * Callback for deleting client's profile
     */
    onDelete: () => void;
}

const DeleteProfileModal: React.FC<CountrySelectModalProps> = ({ visible, visibleHandler, onDelete }) => {
    const handleCloseModalEvent = (e: Event) => {
        e.stopPropagation();
        visibleHandler && visibleHandler(false);
    };

    useEffect(() => {
        window.addEventListener("closeProfileModal", handleCloseModalEvent);

        return () => window.removeEventListener("closeProfileModal", handleCloseModalEvent);
    }, []);

    return (
        <Modal visible={visible} visibleHandler={visibleHandler} size="md">
            <div className="p-12">
                <h2 className="text-2xl font-bold font-sans">Eliminar perfil</h2>
                <p className="pt-4">
                    Estás seguro de que quieres eliminar tu perfil? Todos tus datos se perderán y te echaremos mucho de
                    menos :(
                </p>
                <div className="flex justify-end mt-20">
                    <div className="mr-4">
                        <Button
                            label="Cancelar"
                            type="btn_transparent"
                            onClick={() => window.dispatchEvent(new Event("closeProfileModal"))}
                        />
                    </div>
                    <div>
                        <Button type="btn_red" label="Eliminar" onClick={onDelete} />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default DeleteProfileModal;
