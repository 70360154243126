import React from "react";
import Icon from "@mui/material/Icon";

export interface StarProps {
    /**
     * Determines how the star icon will appear.
     * "full" means the star will be completely filled in yellow, "half" means only
     * half of the star will be filled in yellow, "empty" means the star will have no
     * fill, just a grey outline.
     */
    type: "empty" | "half" | "full";
}

/**
 * A star icon which can be filled in yellow completely or just half of it,
 * or empty (grey outline).
 *
 * Useful for representing ratings or marking something as important.
 */
const Star: React.FC<StarProps> = ({ type = "empty" }) => {
    let icon;
    let fill;
    switch (type) {
        case "full":
            icon = "star";
            fill = "yellow";
            break;
        case "half":
            icon = "star_half";
            fill = "yellow";
            break;
        case "empty":
        default:
            icon = "star_border";
            fill = "platinum";
            break;
    }
    return <Icon className={`text-${fill}`}>{`${icon}`}</Icon>;
};

export default Star;
