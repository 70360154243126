import React from "react";
import classNames from "classnames";

export interface TabButtonProps {
    /**
     * Title shown in the tab button.
     */
    title: string;

    /**
     * Whether the tab associated to this button is the currently selected tab,
     * in which case a different style will be applied to the button.
     */
    selected: boolean;

    /**
     * Determines what happens when this tab button is clicked..
     * This is handled by the tab controller and it usually just changes the visible view
     * to the corresponding tab.
     */
    onClick?: () => void;

    /**
     * Defines the coloring of the button, primary means yellow rounded background like a primary
     * button, default means just a normal tab
     */
    type?: "primary" | "default";
}

/**
 * Clickable control shown on top of a tab view which allows switching between tabs.
 * It will show the tab's title.
 */
const TabButton: React.FC<TabButtonProps> = ({ title, onClick, selected = false, type = "default" }) => (
    <button
        className={classNames("flex items-center py-2 pr-10 pb-8", type === "primary" && "bg-yellow rounded-xl px-2.5")}
        onClick={onClick}
        type="button"
    >
        <span
            className={classNames(
                "font-bold px-1.5 border-b-2 hover:text-blue-dark",
                selected ? "text-blue-dark border-yellow" : "text-platinum-dark-2 border-transparent",
                type === "primary" && "text-black"
            )}
        >
            {title}
        </span>
    </button>
);

export default TabButton;
