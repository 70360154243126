/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, FocusEvent, MouseEvent } from "react";
import Fuse from "fuse.js";
import Dropdown from "./Dropdown/Dropdown";
import DropdownItem, { DropdownItemProps } from "./Dropdown/DropdownItem";
import TextInput from "./TextInput";
import { ModelRef } from "../types";

export interface Many2oneProps {
    /**
     * Identifier for the field.
     */
    id: string;
    /**
     * Label for the field.
     */
    label?: string;
    /**
     * Placeholder text for the underlying TextInput
     */
    placeholder?: string;
    /**
     * Whether the field is required in the form it lives in.
     */
    required?: boolean;
    /**
     * Whether the input is disabled or not.
     */
    disabled?: boolean;
    /**
     * The current value of the field, if any.
     */
    defaultValue?: ModelRef;
    /**
     * List of all possible choices.
     */
    data: ModelRef[];
    /**
     * Callback to be called whenever a DropdownItem is selected, used mostly for the data layer.
     */
    onClick?: (selected: ModelRef) => void;
    /**
     * Callback to be called whenever losing focus on the component or its children,
     * used mostly for the data layer.
     */
    onBlur?: (selected: ModelRef) => void;
    /**
     * Max amount of entries to show, 5 by default
     */
    maxEntries?: number;
    /**
     * Optional error message to display below the field, in red, should be a state so that
     * it can be dynamically change the status of the field (error/no error)
     */
    errorMessage?: string;
}

/**
 * Clickable and searchable component for selecting dynamic values (database records)
 */
const Many2one: React.FC<Many2oneProps> = ({
    id,
    label,
    placeholder,
    required,
    disabled,
    defaultValue,
    data = [],
    onClick,
    onBlur,
    maxEntries = 5,
    errorMessage,
}) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [selected, setSelected] = useState<ModelRef>(defaultValue || { id: 0, name: "" });
    const [dropdownItems, setDropdownItems] = useState<React.ReactElement<DropdownItemProps>[]>([]);
    const [internalErrorMsg, setInternalErrorMsg] = useState("");

    const getClosestMatches = (value: string): ModelRef[] => {
        if (!value) {
            // Don't do any filtering if value is falsy, simply show all possible values
            return data.slice(0, maxEntries);
        }

        // Fuzzy search the list of ModelRefs by name
        const fuse = new Fuse(data, { keys: ["name"], ignoreLocation: true });
        return fuse
            .search(value)
            .slice(0, maxEntries)
            .map((entry) => entry.item);
    };

    const onClickHandler = (m2o: ModelRef) => {
        setSelected(m2o);
        setDropdownVisible(false);
        onClick && onClick(m2o);
    };

    const generateDropdownItems = (value: string) => {
        setDropdownItems(
            getClosestMatches(value).map((d) => (
                <DropdownItem
                    key={d.id}
                    label={d.name}
                    onClick={() => onClickHandler(d)}
                    // this lets us call onClick before onBlur
                    onMouseDown={(e: MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                />
            ))
        );
    };

    const onFocusHandler = () => {
        // Clear the error message, only show it if the user is out of focus
        setInternalErrorMsg("");
        setDropdownVisible(true);
        generateDropdownItems(selected.name);
    };

    const onBlurHandler = (e: FocusEvent<HTMLDivElement>) => {
        const currentTarget = e.currentTarget;

        setTimeout(() => {
            if (!currentTarget.contains(document.activeElement)) {
                setDropdownVisible(false);
            }
        }, 0);

        if (selected.id === 0 && selected.name) {
            // The contents of the field entered by the user are not valid, display an error message
            // so as to force the user to correct the mistake
            setInternalErrorMsg(`Invalid value ${selected.name}`);
        } else if (selected.id === 0 && !selected.name) {
            onBlur && onBlur(selected);
        }
    };

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDropdownVisible(true);
        generateDropdownItems(e.target.value);
        setSelected({ id: 0, name: e.target.value });
    };

    const anchor = (
        <TextInput
            id={id}
            type="text"
            label={label}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            value={selected.name}
            errorMessage={internalErrorMsg || errorMessage}
            autocomplete={false}
        />
    );

    return (
        <Dropdown
            anchor={anchor}
            sameWidth
            visible={dropdownVisible}
            onFocus={onFocusHandler}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
        >
            {dropdownItems}
        </Dropdown>
    );
};

export default Many2one;
