import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import MokenSidebar from "../components/Sidebar/MokenSidebar";
import DashboardScreen from "./DashboardScreen";
import ClientsScreen from "./ClientsScreen";
import InboxScreen from "./InboxScreen";
import ReservationScreen from "./ReservationScreen";
import TemplateScreen from "./TemplateScreen";
import SettingsScreen from "./SettingsScreen";

const Platform: React.FC = () => (
    <div className="flex w-full h-full">
        <MokenSidebar />
        <Routes>
            <Route path="/dashboard" element={<DashboardScreen />} />
            <Route path="/clients" element={<ClientsScreen />} />
            <Route path="/providers" element={<ClientsScreen provider />} />
            <Route path="/inbox" element={<InboxScreen />} />
            <Route path="/quotations" element={<ReservationScreen type="quotation" />} />
            <Route path="/reservations" element={<ReservationScreen type="reservation" />} />
            <Route path="/templates" element={<TemplateScreen />} />
            <Route path="/settings" element={<SettingsScreen />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
    </div>
);

export default Platform;
