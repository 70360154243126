import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";


export interface SearchBoxProps {
    /**
     * Handler for when the text in the searchbox changes.
     */
    onChange?: (query: string) => void;

    /**
     * Custom text placeholder
     */
    placeholder?: string;

    /**
     * Handler for when the user submits the contents of the searchbox.
     */
    onSubmit?: () => void;

    searchActive?: boolean;

    hiddenIcon?: boolean;
}

/**
 * A search box in which the user can type. It can either respond to change events as the user types,
 * or when the user submits the search (by pressing enter or clicking on the search icon).
 */
const SearchBox: React.FC<SearchBoxProps> = ({ onChange, onSubmit, placeholder, hiddenIcon}) => (
    <form
    className="w-full"
        onSubmit={(e) => {
            e.preventDefault();
            onSubmit && onSubmit();
        }}
    >
        <Box sx={{ position: "relative", width: "100%" }}>
            <TextField
                id="standard-basic"
                label={placeholder || "Buscar"}
                variant="outlined"
                color="primary"
                sx={{ minWidth: 300, width: "100%" }}
                tabIndex={0}
                onChange={(e) => {
                    onChange && onChange(e.target.value);
                }}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        onSubmit && onSubmit();
                    }
                }}
                
                size="small"
                InputProps={{
                    endAdornment: (
                        !hiddenIcon &&
                            <InputAdornment position="end" onClick={() => {onSubmit && onSubmit();}} >
                                <SearchIcon className="cursor-pointer"/>
                            </InputAdornment>
                    ),
                }}
            />
        </Box>
    </form>
);
export default SearchBox;
