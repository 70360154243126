import Icon from "@mui/material/Icon";
import classNames from "classnames";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch } from "react-redux";
import { dataPending } from "../redux/reservations";
import axiosApi from "../axiosApi";
import Button from "../components/Button";
import Container from "../components/Container";
import IconButton from "../components/IconButton";
import List from "../components/List/List";
import { getAvatarWithName } from "../utils";
import CollapsableSection from "../components/CollapsableSection";
import {
    DayBlockData,
    ReservationDetailData,
    TemplateDetailData,
    TemplateRequestContainer,
    TemplateRequestData,
} from "../types";
import useAlert from "../useAlert";
import Tag from "../components/Tag";
import ImportTemplateModal from "../components/modals/ImportTemplateModal";
import LoadingModal from "../components/modals/LoadingModal";
import RequestModal from "../components/modals/RequestModal";

interface DayBlockRow {
    id: number;
    destinations: string;
    title: string;
    days: number;
    external?: React.ReactNode;
    provider?: string | React.ReactNode;
    actions: React.ReactNode;
}

interface TemplateRequestRow {
    id: number;
    start_date: Date;
    destinations: string | React.ReactNode;
    days: number;
    state: React.ReactNode;
    provider: string;
    actions: React.ReactNode;
}

const dayBlockFields: {
    key: keyof DayBlockRow;
    title: string;
}[] = [
    {
        key: "title",
        title: "Título",
    },
    {
        key: "destinations",
        title: "Destino",
    },
    {
        key: "days",
        title: "Días",
    },
    {
        key: "external",
        title: "Gestión",
    },
    {
        key: "provider",
        title: "Proveedor",
    },
    {
        key: "actions",
        title: "Acciones",
    },
];

const dayBlockFieldsTemplate: {
    key: keyof DayBlockRow;
    title: string;
}[] = [
    {
        key: "title",
        title: "Título",
    },
    {
        key: "destinations",
        title: "Destino",
    },
    {
        key: "days",
        title: "Días",
    },
    {
        key: "actions",
        title: "Acciones",
    },
];

const templateRequestFields: {
    key: keyof TemplateRequestRow;
    title: string;
}[] = [
    {
        key: "start_date",
        title: "Fecha",
    },
    {
        key: "destinations",
        title: "Destino",
    },
    {
        key: "days",
        title: "Días",
    },
    {
        key: "state",
        title: "Estado",
    },
    {
        key: "provider",
        title: "Proveedor",
    },
    {
        key: "actions",
        title: "Acciones",
    },
];

interface DestinationsTabProps {
    itinerary: DayBlockData[];
    setItinerary: (newItinerary: DayBlockData[]) => void;

    templateRequests?: Array<TemplateRequestContainer>;

    reservation: ReservationDetailData | TemplateDetailData;
    setReservation?: (newReservation: ReservationDetailData) => void;
    type: "reservations" | "templates";

    selectedVersion?: number;
}

const DestinationsTab: React.FC<DestinationsTabProps> = ({
    itinerary,
    setItinerary,
    templateRequests,
    reservation,
    setReservation,
    type,
    selectedVersion,
}) => {
    const [selectedItineraryRows, setSelectedItineraryRows] = useState(new Set<number>());
    const [openTemplateRequest, setOpenTemplateRequest] = useState<TemplateRequestData | null>(null);
    const [isRequestItineraryModalVisible, setIsRequestItineraryModalVisible] = useState(false);
    const [isRequestedItineraryModalVisible, setIsRequestedItineraryModalVisible] = useState(false);
    const [isImportTemplateModalVisible, setIsImportTemplateModalVisible] = useState(false);
    const [templateRequestsList, setTemplateRequestsList] = useState(templateRequests);
    const [selectedTemplate, setSelectedTemplate] = useState<string | null>();
    const [selectedProvider, setSelectedProvider] = useState<string | null>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorElProviders, setAnchorElProviders] = React.useState<null | HTMLElement>(null);
    const [selectedItineraryMenu, setSelectedItineraryMenu] = useState<string | null>();
    const [selectedProvidersMenu, setSelectedProvidersMenu] = useState<string | null>();
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);
    const openProviders = Boolean(anchorElProviders);
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget);
        setSelectedItineraryMenu(e.currentTarget.closest(".listrow-item")?.id);
        e.stopPropagation();
    };
    const handleClickProviders = (e: React.MouseEvent<HTMLElement>) => {
        setSelectedTemplate(e.currentTarget.closest(".listrow-item")?.id);
        setSelectedProvider(e.currentTarget.closest(".provider-item")?.id);
        setSelectedProvidersMenu(e.currentTarget.closest(".listrow-item")?.id);
        setAnchorElProviders(e.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCloseProviders = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setAnchorElProviders(null);
    };

    const selectedItinerary = selectedVersion
        ? itinerary.filter((block) => block.version === selectedVersion)
        : itinerary;

    const { addAlert } = useAlert();

    const handleItinerarySort = (sorted: DayBlockRow[]) => {
        setItinerary(sorted.map((block) => itinerary.find((blockAux) => block.id === blockAux.id) || itinerary[0]));

        // Retrieve the new block sorting and save it on the server
        const sorting = [];
        let sortingChanged = false;
        for (let i = 0; i < sorted.length; i += 1) {
            if (i !== itinerary.find((block) => block.id === sorted[i].id)?.position) {
                sortingChanged = true;
            }
            sorting.push({ id: sorted[i].id, position: i });
        }

        if (sortingChanged) {
            // Need to update the new sorting to the server
            axiosApi
                .post(`/${type}/${reservation.id}/sort_itinerary/`, {
                    sorting,
                    type: "block",
                })
                .then((response) => {
                    setItinerary(response.data);
                })
                .catch(() => addAlert("No se ha podido mover el bloque del itinerario.", "error"));
        }
    };

    const handleGroup = (blocks: Array<number>) => {
        axiosApi
            .post(`/${type}/${reservation.id}/group/`, { blocks })
            .then((response) => {
                addAlert("Los días se han agrupado con éxito.", "success");
                setItinerary(response.data);
                setSelectedItineraryRows(new Set());
                dispatch(dataPending());
            })
            .catch(() => addAlert("No se han podido agrupar los bloques del itinerario.", "error"));
    };

    const handleDeleteItinerary = () => {
        const position = Number(anchorEl?.id);
        const block = selectedItinerary.at(position);
        axiosApi
            .post(`/${type}/${reservation.id}/delete_block/`, { position })
            .then((response) => {
                addAlert("El bloque se ha eliminado.", "success");
                setItinerary(response.data);
                if (block) {
                    selectedItineraryRows.delete(block.id);
                }
            })
            .catch(() => addAlert("No se han podido eliminar el bloque.", "error"));
    };

    const handleSplit = (id: number) => {
        const block = itinerary.find((dayBlock) => dayBlock.id === id);
        if (!block || block.days.length < 2) {
            return;
        }

        axiosApi
            .post(`/${type}/${reservation.id}/split/`, { id })
            .then((response) => {
                addAlert("El bloque se ha separado.", "success");
                setItinerary(response.data);
                dispatch(dataPending());
            })
            .catch(() => addAlert("No se han podido separar el bloque.", "error"));
    };

    const buildDayBlockRow = (block: DayBlockData, id: number): DayBlockRow => {
        const blockDestinations = new Set<string>();
        const currentId = id;
        block.days.forEach((day) => {
            if (day.country) {
                blockDestinations.add(day.country.name);
            }
        });

        const MuiDeleteImportMenuItem = React.forwardRef(() => (
            <MenuItem
                id={id?.toString()}
                classes="flex justify-between"
                onClick={() => {
                    handleDeleteItinerary();
                    handleClose();
                }}
            >
                <span>
                    <IconButton icon="delete" iconSize="27px" color="red" extraClass="text-[22px]" />
                </span>
                <span className="pl-2 text-sm">Eliminar</span>
            </MenuItem>
        ));
        const MuiDivideMenuItem = React.forwardRef(() => (
            <MenuItem
                id={currentId?.toString()}
                classes="flex justify-between"
                onClick={() => {
                    handleSplit(block.id);
                    handleClose();
                }}
            >
                <span className="pl-1">
                    <IconButton icon="folder" extraClass="text-[22px]" />
                </span>
                <span className="pl-2">Dividir</span>
            </MenuItem>
        ));
        MuiDeleteImportMenuItem.displayName = "MuiDeleteImportMenuItem";
        MuiDivideMenuItem.displayName = "MuiDivideMenuItem";

        const actions = (
            <div>
                <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                    <IconButton icon="more_vert" onClick={handleClick} extraClass="text-[20px]" id={currentId} />
                </Box>
                <Menu
                    id={currentId.toString()}
                    anchorEl={anchorEl}
                    open={selectedItineraryMenu === currentId.toString() && open}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: "visible",
                            border: "1px solid #DDD",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            "&:before": {
                                content: "''",
                                display: "block",
                                position: "absolute",
                                borderLeft: "1px solid #DDD",
                                borderTop: "1px solid #DDD",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                    <MuiDeleteImportMenuItem />

                    {block.days.length > 1 && <MuiDivideMenuItem />}
                </Menu>
            </div>
        );
        // For single-day blocks, use the title of the day
        let title = "";
        if (!block.provider && block.days.length === 1) {
            title = block.days[0].title;
        } else if (block.provider) {
            title = block.title;
        }
        return {
            id: block.id,
            days: block.days.length,
            destinations: Array.from(blockDestinations).join(" "),
            external: (
                <div className="flex items-center justify-start space-x-3">
                    <Icon className={classNames(block.provider ? "text-blue" : "text-yellow")}>
                        {`${block.provider ? "play_for_work" : "error_med"}`}
                    </Icon>
                    <p>{block.provider ? "Importado" : "Interna"}</p>
                </div>
            ),
            provider: getAvatarWithName(block.provider?.full_name, null, block.provider?.profile_image?.image) || "",
            title,
            actions,
        };
    };

    const buildTemplateRequestRow = (req: TemplateRequestData, id: number): TemplateRequestRow => {
        const received = req.reservation && req.is_sent;
        const currentId = id;
        const reclaimed = req.reclaimed;
        const destinations = (
            <div className="flex space-x-1 items-center">
                {req.destinations.length === 0 && <p className=" text-grey-light-2">-</p>}
                {req.destinations.length > 0 && <Tag label={req.destinations[0].name} type="yellow" />}
                {req.destinations.length > 1 && <span>{`+${req.destinations.length - 1}`}</span>}
            </div>
        );
        const MuiImportMenuItem = React.forwardRef(() => (
            <MenuItem
                classes="flex justify-between"
                onClick={() => {
                    if (
                        selectedItinerary.filter((block) => block.template_request && block.template_request === req.id)
                            .length > 0
                    ) {
                        addAlert("Esta reserva ya se ha importado.", "warning");
                        return;
                    }

                    axiosApi
                        .get(`/reservations/${reservation.id}/import_reservation/${req.reservation}/`)
                        .then((response) => {
                            // Update the state with the new itinerary info
                            setItinerary(response.data.itinerary);
                        })
                        .catch(() => {
                            // TODO
                        });
                }}
            >
                <span>
                    <IconButton icon="drive_folder_upload" extraClass="text-[22px]" />
                </span>
                <span className="px-2">Importar</span>
            </MenuItem>
        ));
        const MuiDownloadMenuItem = React.forwardRef(() => (
            <MenuItem
                classes="flex justify-between"
                onClick={() => {
                    if (
                        selectedItinerary.filter((block) => block.template_request && block.template_request === req.id)
                            .length > 0
                    ) {
                        addAlert("Esta reserva ya se ha importado.", "warning");
                        return;
                    }

                    axiosApi
                        .get(`/reservations/${reservation.id}/import_reservation/${req.reservation}/`)
                        .then((response) => {
                            // Update the state with the new itinerary info
                            setItinerary(response.data.itinerary);
                        })
                        .catch(() => {
                            // TODO
                        });
                }}
            >
                <span>
                    <IconButton icon="drive_folder_upload" extraClass="text-[22px]" />
                </span>
                <span className="px-2">Importar</span>
            </MenuItem>
        ));
        const MuiReclaimMenuItem = React.forwardRef(() => (
            <MenuItem
                classes="flex justify-between"
                onClick={() => {
                    axiosApi
                        .get(`/reservations/${reservation.id}/reclaim_request/${req.id}/`)
                        .then(() => {
                            // TODO: Use reservation's title
                            addAlert("Se ha enviado la reclamación al proveedor.", "success");
                        })
                        .catch(() => {
                            addAlert("Ha habido un error enviado la reclamación.", "error");
                        });
                }}
            >
                <span>
                    <IconButton icon="assignment" extraClass="text-[22px]" />
                </span>
                <span className="px-2">Reclamar</span>
            </MenuItem>
        ));
        const MuiDeleteRequestMenuItem = React.forwardRef(() => (
            <MenuItem
                classes="flex justify-between"
                onClick={() => {
                    axiosApi
                        .get(`/reservations/${reservation.id}/delete_request/${req.id}/`)
                        .then(() => {
                            // TODO: Use reservation's title

                            templateRequests &&
                                selectedProvider &&
                                templateRequests[Number(selectedProvider)].requests.splice(Number(selectedTemplate), 1);

                            templateRequests && setTemplateRequestsList(templateRequests);
                            addAlert("Se ha eliminado la solicitud.", "success");
                        })
                        .catch(() => {
                            addAlert("Ha habido un error eliminando la solicitud.", "error");
                        });
                }}
            >
                <span>
                    <IconButton icon="delete" extraClass="text-[22px] text-red" />
                </span>
                <span className="px-2 text-red">Eliminar</span>
            </MenuItem>
        ));
        MuiImportMenuItem.displayName = "MuiImportMenuItem";
        MuiDownloadMenuItem.displayName = "MuiDownloadMenuItem";
        MuiReclaimMenuItem.displayName = "MuiReclaimMenuItem";
        MuiDeleteRequestMenuItem.displayName = "MuiDeleteRequestMenuItem";
        const actions = (
            <div>
                <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                    <IconButton icon="more_vert" onClick={handleClickProviders} extraClass="text-[20px]" />
                </Box>
                <Menu
                    anchorEl={anchorElProviders}
                    open={selectedProvidersMenu === currentId.toString() && openProviders}
                    onClick={(e) => {
                        handleCloseProviders(e);
                    }}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: "visible",
                            border: "1px solid #DDD",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            "&:before": {
                                content: "''",
                                display: "block",
                                position: "absolute",
                                borderLeft: "1px solid #DDD",
                                borderTop: "1px solid #DDD",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                    {received && <MuiImportMenuItem />}
                    {received && <MuiDownloadMenuItem />}

                    {!reclaimed && <MuiReclaimMenuItem />}
                    <MuiDeleteRequestMenuItem />
                </Menu>
            </div>
        );
        return {
            id: req.id,
            start_date: req.travel_start_date,
            days: req.travel_days,
            destinations,
            // TODO: More states?
            state: received ? (
                <div className="flex space-x-2 bg-red bg-green-light-3 py-[4px] px-[8px] rounded-md w-[110px]">
                    <Icon sx={{ color: "green", fontSize: "18px" }}>check_circle</Icon>
                    <span>Recibido</span>
                </div>
            ) : (
                <div className="flex space-x-2 bg-yellow-light-2 py-[4px] px-[8px] rounded-md w-[110px]">
                    <Icon sx={{ color: "blue", fontSize: "18px" }}>schedule</Icon>
                    <span>Enviado</span>
                </div>
            ),
            provider: req.provider.name,
            actions,
        };
    };

    return (
        <Container
            numSelected={selectedItineraryRows.size}
            actionArea={
                <div className="flex gap-4">
                    <Button
                        label="Cancelar"
                        onClick={() => setSelectedItineraryRows(new Set())}
                        type="btn_transparent"
                    />
                    <Button
                        label="Agrupar"
                        onClick={() => handleGroup(Array.from(selectedItineraryRows))}
                        type="btn_dark"
                    />
                </div>
            }
        >
            <div className="flex flex-col space-y-8 mt-10">
                {type === "reservations" && (
                    <div className="flex flex-col space-y-5">
                        <div className="flex justify-between items-center">
                            <h2 className="text-2xl font-bold font-sans">Proveedores</h2>

                            <div>
                                <Button
                                    label="Solicitar"
                                    icon="add"
                                    title=" Solicitar destino"
                                    onClick={() => {
                                        setIsRequestItineraryModalVisible(true);
                                    }}
                                    type="btn_yellow"
                                />
                            </div>
                        </div>
                        {}

                        {templateRequestsList ? (
                            templateRequestsList.map((providerObject, index) => (
                                <div
                                    key={providerObject.provider.id}
                                    className="flex flex-row w-full pt-2 pb-6 provider-item"
                                    id={index?.toString()}
                                >
                                    <div className="w-[40px] mr-4 relative top-[4px]">
                                        <img
                                            src={
                                                providerObject.provider.profile_image?.image ||
                                                "/resources/img/img_no_encontrada.svg"
                                            }
                                            alt="logo proveedor"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <CollapsableSection title={providerObject.provider.name}>
                                            <List
                                                fields={templateRequestFields}
                                                listType={
                                                    type === "reservations" ? "askproviderlist" : "tempdestinationlist"
                                                }
                                                objects={providerObject.requests.map(
                                                    (req: TemplateRequestData, i: number) =>
                                                        buildTemplateRequestRow(req, i)
                                                )}
                                                onRowClick={(id) => {
                                                    if (!templateRequests) return;
                                                    setOpenTemplateRequest(
                                                        providerObject.requests.find(
                                                            (templateRequest: TemplateRequestData) =>
                                                                templateRequest.id === id
                                                        ) || null
                                                    );
                                                    setIsRequestedItineraryModalVisible(true);
                                                }}
                                            />
                                        </CollapsableSection>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <span className="text-sm text-grey">Todavía no has solicitado ningún presupuesto.</span>
                        )}
                    </div>
                )}
                <div className="flex flex-col space-y-5 mb-20 mt-10">
                    <div className="flex justify-between items-center">
                        <h2 className="text-2xl font-bold font-sans">Resumen Itinerario</h2>
                        {type === "reservations" && (
                            <div>
                                <Button
                                    label="Importar"
                                    icon="add"
                                    title="Importar destino"
                                    onClick={() => {
                                        setIsImportTemplateModalVisible(true);
                                    }}
                                    type="btn_yellow"
                                />
                            </div>
                        )}
                    </div>
                    <List
                        fields={type === "reservations" ? dayBlockFields : dayBlockFieldsTemplate}
                        objects={selectedItinerary.map((block, index) => buildDayBlockRow(block, index))}
                        multiselection
                        listType={type === "reservations" ? "destinationlist" : "tempdestinationlist"}
                        selectedRows={selectedItineraryRows}
                        onSelect={setSelectedItineraryRows}
                        onSort={handleItinerarySort}
                        items={selectedItinerary.map((block) =>
                            block.days.length > 1
                                ? block.days.map((day) => (
                                      <div className=" w-full border-b-2 border-grey-light-3" key={uuidv4()}>
                                          {day.title || "-"}
                                      </div>
                                  ))
                                : []
                        )}
                    />
                </div>
            </div>

            {/* Modals */}
            {type === "reservations" && (
                <>
                    <RequestModal
                        newRequest
                        visible={isRequestItineraryModalVisible}
                        visibleHandler={setIsRequestItineraryModalVisible}
                        requestData={{
                            amount_travelers: (reservation as ReservationDetailData).amount_travelers,
                            travel_type: (reservation as ReservationDetailData).travel_type,
                            traveler_type: reservation.traveler_type,
                            budget: (reservation as ReservationDetailData).budget,
                            interests: reservation.interests,
                            travel_start_date: new Date((reservation as ReservationDetailData).travel_start_date),
                            travel_days: 1,
                        }}
                        reservationId={reservation.id}
                        onSubmit={(data: ReservationDetailData) => {
                            setTemplateRequestsList(data.template_requests);
                            setReservation && setReservation(data);
                        }}
                    />
                    {openTemplateRequest && (
                        <RequestModal
                            visible={isRequestedItineraryModalVisible}
                            visibleHandler={(visible) => {
                                if (!visible) {
                                    setOpenTemplateRequest(null);
                                }
                                setIsRequestedItineraryModalVisible(visible);
                            }}
                            requestData={{
                                ...openTemplateRequest,
                            }}
                            reservationId={reservation.id}
                            defaultProvider={openTemplateRequest.provider}
                        />
                    )}
                    <ImportTemplateModal
                        visible={isImportTemplateModalVisible}
                        visibleHandler={setIsImportTemplateModalVisible}
                        onImport={(id) => {
                            axiosApi
                                .get(`/reservations/${reservation.id}/import_template/${id}/`)
                                .then((response) => {
                                    setReservation && setReservation(response.data);
                                    type === "reservations" && setItinerary(response.data.itinerary);
                                })
                                .catch(() => {
                                    // TODO
                                });
                        }}
                    />
                </>
            )}

            <LoadingModal visible={false} text="El PDF se está generando. La descarga comenzará en breve..." />
        </Container>
    );
};

export default DestinationsTab;
