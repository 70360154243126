import React from "react";
import Icon from "@mui/material/Icon";

export interface BackButtonProps {
    /**
     * Click handler.
     */
    onClick: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => (
    <button
        className="rounded-full w-10 h-10 bg-platinum-light-1 flex justify-center items-center"
        onClick={onClick}
        type="button"
    >
        <Icon className="text-blue-dark text-lg relative  bottom-[2px]">chevron_left</Icon>
    </button>
);

export default BackButton;
