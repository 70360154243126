import React from "react";
import classNames from "classnames";
import Container from "./Container";
import IconButton from "./IconButton";

export interface SidePanelProps {
    /**
     * Content of the panel.
     */
    children: React.ReactNode;
    /*
     * Chek if side panel is opened
     */
    isSidePanelOpened: boolean;

    /**
     * What happens when the cross button on the top right is clicked.
     */
    onClose?: () => void;
}

/**
 * A fixed width container which contains some info about a client or provider.
 */
const SidePanel: React.FC<SidePanelProps> = ({ children, isSidePanelOpened, onClose }) => (
    <div className={classNames("flex w-100 ml-8 h-full shrink-0", isSidePanelOpened === true ? "hidden sm:block" : "hidden")}>
        <Container padding={["b", "r", "t"]} bubble>
            <div className="fixed flex flex-col pl-8 pr-8 pb-8  pt-12 h-full overflow-y-scroll no-scrollbar border-l border-grey-light-2 h-[calc(100vh-250px)]">
                {onClose !== undefined && (
                    <div className="place-self-end mb-5">
                        <IconButton icon="close" onClick={onClose} />
                    </div>
                )}

                {children}
            </div>
        </Container>
    </div>
);

export default SidePanel;
