import React from "react";
import { Choice } from "../types";
import Checkbox from "./Checkbox";

export interface RadioGroupProps {
    values: Array<Choice>;
    selectedIdx: number;
    onChange: (idx: number) => void;
}

const RadioGroup: React.FC<RadioGroupProps> = ({ values, selectedIdx, onChange }) => (
    <div className="flex flex-wrap">
        {values.map((value, idx) => (
            <div key={value.value} className="mr-2 mb-2">
                <Checkbox
                    label={value.display}
                    onChange={() => {
                        onChange(idx);
                    }}
                    checked={selectedIdx === idx}
                />
            </div>
        ))}
    </div>
);

export default RadioGroup;
