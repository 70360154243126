/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import SidebarMenuItem from "./SidebarMenuItem";
import { useAuth } from "../../auth";
import axiosApi from "../../axiosApi";
import { ContactData } from "../../types";
import { close } from "../../redux/sidebar";
import { RootState } from "../../store";

const MokenSidebar: React.FC = () => {
    const auth = useAuth();
    const [inboxCount] = useState(0);
    const [contact, setContact] = useState<ContactData | null>(null);
    const dispatch = useDispatch();

    const isOpen = useSelector((state: RootState) => state.sidebar.isOpen);

    // TODO: Keep state somewhere else so that it is not fetched at every page change
    const fetchContact = useCallback(() => {
        if (auth.contact === null) return;

        axiosApi.get("/contacts/me/").then((response) => {
            const contactData = response.data as ContactData;

            setContact(contactData);
        });
    }, []);

    useEffect(() => {
        if (!contact) {
            fetchContact();
        }
    });

    return (
        <Sidebar headerImg={isOpen  ? "../logo-yellow.png" : "../logo-icon.png"} headerText="Moken">
            <SidebarMenuItem 
                label="Dashboard" 
                icon="dashboard" 
                linkTo="/dashboard" 
                onClick={() => {
                            window.innerWidth < 640 && dispatch(close());
                        }}
            />
            <SidebarMenuItem 
                label="Clientes" 
                icon="groups" 
                linkTo="/clients"
                onClick={() => {
                    window.innerWidth < 640 && dispatch(close());
                }}
            />
            <SidebarMenuItem 
                label="Proveedores" 
                icon="handshake" 
                linkTo="/providers"
                onClick={() => {
                    window.innerWidth < 640 && dispatch(close());
                }} 
            />
            {contact?.company_type === "provider" && (
                <SidebarMenuItem 
                    label="Bandeja de entrada" 
                    icon="mail" 
                    linkTo="/inbox" 
                    numberBox={inboxCount}
                    onClick={() => {
                        window.innerWidth < 640 && dispatch(close());
                    }} 
                />
            )}
            <SidebarMenuItem 
                label="Viajes" icon="flight">
                <SidebarMenuItem 
                    label="Presupuestos" 
                    icon="feed" 
                    linkTo="/quotations"
                    onClick={() => {
                        
                        window.innerWidth < 640 && dispatch(close());
                    }} 
                />
                <SidebarMenuItem 
                    label="Reservas" 
                    icon="luggage" 
                    linkTo="/reservations"
                    onClick={() => {
                        
                        window.innerWidth < 640 && dispatch(close());
                    }} 
                />
            </SidebarMenuItem>
            <SidebarMenuItem 
                label="Tarifario" 
                icon="topic" 
                linkTo="/templates"
                onClick={() => {
                    
                    window.innerWidth < 640 && dispatch(close());
                }} 
            />
            {auth.isCompanyAdmin && 
                <SidebarMenuItem 
                    label="Configuración" 
                    icon="settings" 
                    linkTo="/settings"
                    onClick={() => {
                        window.innerWidth < 640 && dispatch(close());
                    }} 
                />}
        </Sidebar>
    );
};

export default MokenSidebar;
