import React from "react";
import classNames from "classnames";
import Icon from "@mui/material/Icon";
import Avatar from "./Avatar";
import { formatTime, getInitials } from "../utils";
import { AttachmentData } from "../types";

export interface HistoryMessageProps {
    /**
     * Side on which the message will appear.
     */
    side: "left" | "right";

    /**
     * Optional URL of the image of the avatar to show on one side of the message.
     */
    image?: string;

    /**
     * Text shown within the message box in case of emails (corresponds to the email body but without HTML) or by itself
     * in notifications.
     */
    message: string;

    /**
     * Check if side panel is opened
     */
    isSidePanelOpened?: boolean;
    /**
     * Optional click handler.
     */
    type: "message" | "notification";

    /**
     * For messages, the full name of the sender.
     */
    senderName?: string;

    /**
     * For messages, the company of the sender, if any.
     */
    senderCompany?: string;

    /**
     * Date and time of the message, shown below the message itself.
     */
    timestamp: Date;

    /**
     * For messages of type "message", if it is highlighted then a yellow border is added to the message box.
     */
    highlighted?: boolean;

    /**
     * Any attachments linked to an email message.
     */
    attachments?: AttachmentData[];
}

/**
 * A history message is either an email from a company or individual linked to a reservation, or a notification linked to some
 * change in said reservation.
 *
 * Email messages are displayed similar to a chat, with the message in a box, the avatar on one side or the other depending on the current
 * user (current user sees its own messages and notifications to the right, everything else to the left). Messages corresponding to an email
 * may also contain the name of the sender, the company of the sender if any, and email attachments.
 *
 * All messages have a timestamp corresponding to the date and time they were created. In the case of notifications, they correspond to the date and
 * time at which they were registeded in the database. For emails, it is the date and time at which they were received and stored in the database.
 */
const HistoryMessage: React.FC<HistoryMessageProps> = ({
    side,
    image,
    message,
    type,
    timestamp,
    senderName,
    senderCompany,
    attachments,
    highlighted = false,
}) => (
    <div className={classNames("flex pt-6 w-full max-w-2xl", side === "right" && "place-self-end")}>
        {type === "message" && (
            <div className={classNames(side === "right" ? "order-last mx-4" : "mr-4")}>
                <Avatar image={image || ""} altText={senderName ? getInitials(senderName) : ""} />
            </div>
        )}
        <div className={classNames(side === "right", "w-full")}>
            <div
                className={classNames(
                    "flex flex-col p-5 whitespace-pre-line max-w-160 w-full border border-grey-light-3 border-1 text-grey",
                    type === "message" ? "bg-white rounded-md shadow space-y-6" : "space-y-2",
                    highlighted && "border-2 border-yellow"
                )}
            >
                {senderName && (
                    <p className="text-sm text-grey">
                        <span className="font-bold">{senderName}</span>
                        {senderCompany && (
                            <>
                                <span> desde </span>
                                <span className="font-bold">{senderCompany}</span>
                            </>
                        )}
                    </p>
                )}
                <p className={classNames(type === "notification" && "place-self-end")}>{message}</p>

                {attachments &&
                    attachments.map((attachment) => (
                        <div className="flex items-center space-x-2 p-2" key={attachment.id}>
                            <div className="flex items-center justify-center rounded-full bg-yellow w-9 h-9">
                                <Icon baseClassName="material-icons-outlined">descripton</Icon>
                            </div>
                            <p className="text-sm text-grey">{attachment.name}</p>
                        </div>
                    ))}

                <p className="text-sm text-grey place-self-end">
                    {timestamp.toLocaleDateString()} - {formatTime(timestamp)}
                </p>
            </div>
        </div>
    </div>
);

export default HistoryMessage;
