import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "@mui/material/styles/createPalette";

interface StyleProps {
    children: object;
}

declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        btn_yellow: true;
        btn_transparent: true;
        btn_dark: true;
        btn_disabled: true;
    }
}

declare module "@mui/material/styles" {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
    interface Palette {
        mokenYellow: Palette["primary"];
        mokenBlue: Palette["primary"];
        mokenBlack: Palette["primary"];
        mokenRed: Palette["primary"];
        mokenGreyLight_1: Palette["primary"];
        mokenGreyLight_2: Palette["primary"];
        mokenGreyLight_3: Palette["primary"];
    }

    interface PaletteOptions {
        mokenYellow: PaletteOptions["primary"];
        mokenBlue: PaletteOptions["primary"];
        mokenBlack: PaletteOptions["primary"];
        mokenRed: PaletteOptions["primary"];
        mokenGreyLight_1: PaletteOptions["primary"];
        mokenGreyLight_2: PaletteOptions["primary"];
        mokenGreyLight_3: PaletteOptions["primary"];
    }

    interface PaletteColor {
        darker?: string;
    }

    interface SimplePaletteColorOptions {
        darker?: string;
    }
}

/**
 * MOKEN CUSTOM COLORS
 */
let theme = createTheme({
    palette: {
        mokenYellow: {
            main: "#ffbe00",
        },
        mokenRed: {
            main: "#FF5653",
        },
        mokenBlue: {
            main: "rgb(0,0,35)",
        },
        mokenBlack: {
            main: "#4a4a4a",
        },
        mokenGreyLight_1: {
            main: "#97999b",
        },
        mokenGreyLight_2: {
            main: "rgba(0, 0, 0, 0.23)",
        },
        mokenGreyLight_3: {
            main: "#ff4747",
        },
    },
});

theme = createTheme(theme, {
    palette: {
        primary: {
            main: theme.palette.mokenYellow.main,
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: "btn_yellow" },
                    style: {
                        textTransform: "none",
                        backgroundColor: theme.palette.mokenYellow.main,
                        color: "#FFF",
                        border: `1px solid ${theme.palette.mokenYellow.main}`,
                        "&:hover": {
                            background: theme.palette.mokenYellow.main,
                            border: `1px solid ${theme.palette.mokenYellow.main}`,
                        },
                    },
                },
                {
                    props: { variant: "btn_transparent" },
                    style: {
                        textTransform: "none",
                        backgroundColor: "transparent",
                        color: theme.palette.mokenBlue.main,
                        border: `1px solid ${theme.palette.mokenBlue.main}`,
                        "&:hover": {
                            background: "transparent",
                            color: theme.palette.mokenBlue.main,
                            border: `1px solid ${theme.palette.mokenBlue.main}`,
                        },
                    },
                },
                {
                    props: { variant: "btn_dark" },
                    style: {
                        textTransform: "none",
                        backgroundColor: theme.palette.mokenBlue.main,
                        color: "#FFF",
                        border: `1px solid ${theme.palette.mokenBlue.main}`,
                        "&:hover": {
                            background: theme.palette.mokenBlue.main,
                            color: "#FFF",
                            border: `1px solid ${theme.palette.mokenBlue.main}`,
                        },
                    },
                },
                {
                    props: { variant: "btn_red" },
                    style: {
                        textTransform: "none",
                        backgroundColor: theme.palette.mokenRed.main,
                        color: "#FFF",
                        border: `1px solid ${theme.palette.mokenRed.main}`,
                        "&:hover": {
                            background: theme.palette.mokenRed.main,
                            color: "#FFF",
                            border: `1px solid ${theme.palette.mokenRed.main}`,
                        },
                    },
                },
                {
                    props: { variant: "btn_disabled" },
                    style: {
                        textTransform: "none",
                        backgroundColor: theme.palette.mokenGreyLight_2.main,
                        color: "#FFF",
                        border: `1px solid ${theme.palette.mokenGreyLight_2.main}`,
                        "&:hover": {
                            background: theme.palette.mokenGreyLight_2.main,
                            color: "#FFF",
                            border: `1px solid ${theme.palette.mokenGreyLight_2.main}`,
                        },
                    },
                },
            ],
        },
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    "&:hover": {
                        background: "transparent",
                    },
                },
            },
        },
    },
});

const StyleTheme: React.FC<StyleProps> = ({ children }: StyleProps) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default StyleTheme;
