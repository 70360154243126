/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Button from "../Button";
import Modal from "../Modal";
import TextInput from "../TextInput";

export interface TextEditModalProps {
    /**
     * Whether the modal is visible or not.
     */
    visible: boolean;

    /**
     * Function for setting the visible state passed by the parent
     */
    visibleHandler?: (visible: boolean) => void;

    onAccept: (text: string) => void;
    defaultValue?: string;
}

/**
 * A simple modal whose only purpose is to show a list of values from a m2o field,
 * for the user to choose one or more from, with a search box to filter by name.
 *
 * A list with a single column is shown, which should be the value of the ModelRef.
 */
const TextEditModal: React.FC<TextEditModalProps> = ({ visible, visibleHandler, onAccept, defaultValue = "" }) => {
    const [text, setText] = useState<string>(defaultValue);

    // Reset state when we open a new edit modal
    useEffect(() => {
        if (visible) {
            setText(defaultValue);
        }
    }, [visible]);

    return (
        <Modal visible={visible} visibleHandler={visibleHandler}>
            <div className="flex flex-col items-center justify-center w-full h-full p-10 space-y-5">
                <h2>Edita tu texto</h2>
                <TextInput id="modal" value={text} onChange={(e) => setText(e.target.value)} />
                <div className="flex flex-row w-full items-center justify-end space-x-2 w-full">
                    <Button
                        label="Cancelar"
                        type="btn_transparent"
                        onClick={() => window.dispatchEvent(new Event("closeModal"))}
                    />
                    <Button label="Guardar" onClick={() => onAccept(text)} type="btn_dark"/>
                </div>
            </div>
        </Modal>
    );
};

export default TextEditModal;
