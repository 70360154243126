/* eslint-disable react/display-name */
import Icon from "@mui/material/Icon";
import classNames from "classnames";
import React from "react";

export interface PaginationProps {
    /**
     * What happens when the "Next" button is clicked.
     */
    onNext: () => void;

    /**
     * What happens when the "Previous" button is clicked.
     */
    onPrevious: () => void;

    /**
     * The current page, starting from 1.
     */
    currentPage: number;

    /**
     * The number of results per page.
     */
    resultsPerPage: number;

    /**
     * Number of total results.
     */
    totalResults: number;
}

/**
 * Used to switch between pages in a paginated list view.
 */
const Pagination: React.FC<PaginationProps> = ({ onNext, onPrevious, currentPage, resultsPerPage, totalResults }) => {
    const maxPage = Math.ceil(totalResults / resultsPerPage);

    return (
        <div className="flex items-center justify-end pt-8 ">
            <Icon
                color="primary"
                onClick={currentPage > 1 ? () => onPrevious() : undefined}
                className={classNames("cursor-pointer", (currentPage > 1 && "text-yellow") || "text-transparent")}
            >
                chevron_left
            </Icon>
            {!(maxPage < 2) && (
                <p className="px-3 text-yellow">
                    Página {currentPage} de {maxPage}
                </p>
            )}

            <Icon
                color="primary"
                onClick={currentPage < maxPage ? () => onNext() : undefined}
                className={classNames("cursor-pointer", (currentPage < maxPage && "text-yellow") || "text-transparent")}
            >
                chevron_right
            </Icon>
        </div>
    );
};

export default Pagination;
