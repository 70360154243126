import React, { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { Choice } from "../types";

export interface SelectionProps {
    /**
     * Id for the selection field
     */
    id: string;
    /**
     * Label that will be displayed on top of the selection box
     */
    label?: string;
    /**
     * Selection options and their values.
     */
    choices: Choice[];
    /**
     * Optional map with a style for the box for each or some of the choices, using Choice values as keys.
     * For example, if a specific choice is selected, we may want to color the background of the Selection red, and
     * make the text a darker red.
     */
    choiceStyles?: Map<string, string>;
    /**
     * Size of the selector field
     */
    size?: "small" | "medium";
    /**
     * Optional map similar to choiceStyles but with an icon for the selection instead of a style.
     * The icon will be styled by the choiceStyles too.
     */
    choiceIcons?: Map<string, string>;
    /**
     * Default selected value when the user hasn't explicitly selected a value, empty by default
     */
    defaultValue?: Choice;
    /**
     * Whether the field is disabled in the form
     */
    disabled?: boolean;
    /**
     * Optional onChange handler.
     */
    onChange?: (choice: Choice) => void;

    /**
     * Optional error message
     */
    errorMessage?: string;
}

const Selection: React.FC<SelectionProps> = ({
    label,
    choices,
    disabled,
    size,
    defaultValue,
    onChange,
    errorMessage,
}) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [fieldSize, setFieldSize] = React.useState<"medium" | "small">(window.innerWidth < 640 ? "medium" : "small");

    const onClickHandler = (choice: Choice) => {
        setDropdownVisible(false);
        onChange && onChange(choice);
    };

    const optionElements = choices.map((choice) => (
        <MenuItem key={choice.value} value={choice.value} onClick={() => onClickHandler(choice)}>
            {choice.thumbnail && <img src={choice.thumbnail} alt={choice.display} className="h-[32px] mr-4 inline" />}
            {choice.display && <span className="py-5 sm:py-2 text-blue">{choice.display}</span>}
        </MenuItem>
    ));

    useEffect(() => {
        function reportWindowSize() {
            if (window.innerWidth < 640) {
                setFieldSize("medium");
            } else {
                setFieldSize("small");
            }
        }

        window.addEventListener("resize", reportWindowSize);

        return () => {
            window.removeEventListener("resize", reportWindowSize);
        };
    }, []);

    return (
        <FormControl fullWidth size={size}>
            <InputLabel id="select-label">{label}</InputLabel>
            {errorMessage && <p className="text-xs text-red pt-1 absolute top-10">{errorMessage}</p>}
            <Select
                size={fieldSize}
                labelId="select-label"
                className="shadow"
                value={defaultValue && defaultValue.value}
                label={label}
                onChange={disabled ? undefined : () => setDropdownVisible(!dropdownVisible)}
                onBlur={(e) => {
                    const currentTarget = e.currentTarget;
                    setTimeout(() => {
                        if (!currentTarget.contains(document.activeElement)) {
                            setDropdownVisible(false);
                        }
                    }, 0);
                }}
            >
                {optionElements}
            </Select>
        </FormControl>
    );
};

export default Selection;
