import React from "react";
import classNames from "classnames";
import IconButton from "./IconButton";

export interface ThumbnailSelectProps {
    image: string;
    altText: string;
    onClick: () => void;
    onDelete: () => void;
    selected?: boolean;
    customName?: string;
    isMokenTemplate?: boolean;
}

const ThumbnailSelect: React.FC<ThumbnailSelectProps> = ({
    image,
    altText,
    onClick,
    onDelete,
    selected,
    customName,
    isMokenTemplate,
}) => (
    <div
        className={classNames(
            "aspect-video flex items-center justify-center rounded-md shadow-inner relative group",
            selected && "outline outline-4 outline-yellow"
        )}
        onClick={onClick}
        onKeyPress={onClick}
        role="button"
        tabIndex={0}
    >
        {!isMokenTemplate && (
            <div className="absolute right-5 top-3 w-7 p-1">
                <IconButton icon="delete" iconSize="27px" color="red" onClick={() => onDelete()} animated />
            </div>
        )}
        <img
            src={image}
            alt={altText}
            className="flex items-center justify-center w-full h-full rounded-md object-cover pointer-events-none"
        />
        {!isMokenTemplate && (
            <div className="absolute left-4 bottom-3 text-lg">
                <span className="text-grey"> Custom Template: </span> {customName && <span>{customName}</span>}
            </div>
        )}
    </div>
);

export default ThumbnailSelect;
