/* eslint-disable react/display-name */
import React from "react";
import Icon from "@mui/material/Icon";
import MuiIconButton from "@mui/material/IconButton";
import classNames from "classnames";

export interface IconButtonProps {
    /**
     * Icon for the button.
     */
    icon: string;

    /**
     * Size of icon's fontsize
     */
    iconSize?: string;
    /**
     * Tooltip of the button
     */
    title?: string;

    /**
     * Optional number to be shown in a small red badge on the top right of the icon.
     */
    numberBadge?: number;

    /**
     * Optional click handler.
     */
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;

    /**
     * Defines the color style of the button.
     */
    color?: "grey" | "yellow" | "red" | "white" | "greylight";

    /**
     * If true, the button scales up by 25% on gover with an animation.
     */
    animated?: boolean;

    /**
     * Extra class slot for custom styles or any other requirements
     */
    extraClass?: string;

    /**
     * Custom Id for multimple identification purposes
     */
    id?: number;
}

/**
 * Button which consists just of a large icon.
 * It can optionally have a number badge on one corner.
 */
const IconButton = React.forwardRef<HTMLDivElement, IconButtonProps>(
    ({ icon, iconSize, numberBadge, onClick, title, color = "grey", animated = false, extraClass, id }, ref) => (
        <div ref={ref} className="w-full text-center">
            <MuiIconButton
                id={id?.toString()}
                className={classNames("relative w-full", animated && "hover:scale-125 transition-all ")}
                title={title}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    onClick && onClick(e);
                }}
            >
                <Icon
                    baseClassName="material-icons-outlined"
                    sx={{ weight: 300, fontSize: iconSize }}
                    className={classNames(
                        color === "grey" && "text-grey-dark-4",
                        color === "greylight" && "text-grey-light-3",
                        color === "yellow" && "text-yellow",
                        color === "red" && "text-red",
                        color === "white" && "text-white",
                        extraClass
                    )}
                >{`${icon}`}</Icon>
                {numberBadge ? (
                    <span className="absolute inset-0 object-right-top -mr-5 -mt-3">
                        <div className="inline-flex items-center px-1.5 py-0.5 rounded-full text-xs font-semibold leading-4 bg-red text-white">
                            {numberBadge}
                        </div>
                    </span>
                ) : null}
            </MuiIconButton>
        </div>
    )
);

export default IconButton;
