import React from "react";
import Modal from "../Modal";
import { ContactData } from "../../types";
import ConnectionRequestCard from "../ConnectionRequestCard";
import IconButton from "../IconButton";

// const PAGE_SIZE = 10;

export interface ConnectionRequestModalProps {
    /**
     * Whether the modal is visible or not.
     */
    visible: boolean;

    /**
     * Function for setting the visible state passed by the parent
     */
    visibleHandler?: (visible: boolean) => void;

    onAccept: (id: number) => void;
    onIgnore: (id: number) => void;

    /**
     * Contact info of the companies that made a connection request.
     */
    contacts: ContactData[];
}

const ConnectionRequestModal: React.FC<ConnectionRequestModalProps> = ({
    visible,
    visibleHandler,
    contacts,
    onAccept,
    onIgnore,
}) => (
    <Modal visible={visible} visibleHandler={visibleHandler}>
        <div className="flex flex-col w-360 h-160 p-6 space-y-5">
            <div className="place-self-end -mb-5">
                <IconButton icon="close" onClick={() => visibleHandler && visibleHandler(false)} />
            </div>
            <h2 className="text-2xl font-bold font-sans pl-5">Solicitudes de contacto</h2>

            {contacts.length > 0 ? (
                <div className="grid grid-cols-6 gap-4 w-full p-5 overflow-auto">
                    {contacts.map((con) => (
                        <ConnectionRequestCard
                            key={con.id}
                            name={con.full_name}
                            commonConnections={con.common_connections?.length || 0}
                            image={con.profile_image?.image}
                            rating={con.average_rating}
                            numRatings={con.num_ratings}
                            onAccept={() => onAccept(con.id)}
                            onIgnore={() => onIgnore(con.id)}
                        />
                    ))}
                </div>
            ) : (
                <div className="flex items-center justify-center h-full w-full">
                    <p>¡Felicidades! Ya no tienes más solicitudes de contacto :)</p>
                </div>
            )}
        </div>
    </Modal>
);

export default ConnectionRequestModal;
