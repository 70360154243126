import React, { createContext, useState, useContext, useMemo } from "react";
import { AlertType } from "./types";

export interface AlertContent {
    message: string;
    type: AlertType;
}

interface AlertContextType {
    alert: AlertContent | null;
    addAlert: (message: string, type: AlertType, lifetime?: number) => void;
    removeAlert: () => void;
}

const AlertContext = createContext<AlertContextType>({
    alert: null,
    addAlert: () => {},
    removeAlert: () => {},
} as AlertContextType);

interface AlertProviderProps {
    children: React.ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
    const [alert, setAlert] = useState<AlertContent | null>(null);
    const [alertTimeout, setAlertTimeout] = useState<NodeJS.Timeout | null>(null);

    /**
     * Removes the current alert from the screen.
     */
    const removeAlert = () => setAlert(null);

    /**
     * Shows an alert at the top of the screen.
     *
     * @param message The message to show in the alert.
     * @param type The type of the alert, which will define its styling.
     */
    const addAlert = (message: string, type: AlertType) => setAlert({ message, type });

    const contextValue = useMemo(
        () =>
            ({
                alert,
                addAlert: (message: string, type: AlertType, lifetime = 5000) => {
                    addAlert(message, type);
                    if (lifetime) {
                        // Clear previous timeout if it exists so that it doesn't conflict with the new one
                        if (alertTimeout) {
                            clearTimeout(alertTimeout);
                        }
                        setAlertTimeout(setTimeout(removeAlert, lifetime));
                    }
                },
                removeAlert,
            } as AlertContextType),
        [alert, alertTimeout]
    );

    return <AlertContext.Provider value={contextValue}>{children}</AlertContext.Provider>;
};

const useAlert = (): AlertContextType => {
    const { alert, addAlert, removeAlert } = useContext(AlertContext);
    return { alert, addAlert, removeAlert };
};

export default useAlert;
