/* eslint-disable no-param-reassign */
/* eslint-disable react/display-name */
import React, { ChangeEvent, FocusEvent, MouseEvent, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Fuse from "fuse.js";
import classNames from "classnames";
import Dropdown from "./Dropdown/Dropdown";
import DropdownItem, { DropdownItemProps } from "./Dropdown/DropdownItem";

export interface TextInputProps {
    /**
     * Identifies the input, and allows it to be referenced
     * when contained in a form.
     */
    id?: string;

    /**
     * Type of the input field.
     */
    type?: "text" | "number" | "email" | "password";

    /**
     * Text describing the input field, shown on top of it.
     */
    label?: string;

    /**
     * Placeholder text shown in the input, in a lighter color.
     */
    placeholder?: string;

    /**
     * Number of vertical lines that the input spans. Usually 1,
     * except for fields that can contain large amounts of text.
     */
    lines?: number;

    /**
     * Whether the text input is required in the form it lives in.
     */
    required?: boolean;

    /**
     * Minimum number of characters that the user is required to enter.
     */
    minLength?: number;

    /**
     * Maximum number of characters that the user is allowed to enter.
     */
    maxLength?: number;

    /**
     * Whether the input is disabled or not.
     */
    disabled?: boolean;

    /**
     * Default value of the text input, in case it should be pre-filled.
     */
    defaultValue?: string;

    /**
     * Optional onChange handler.
     */
    onChange?: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
    /**
     * Optional onFocus handler.
     */
    onFocus?: () => void;
    /**
     * Optional handler which reacts to clicks and key presses.
     */
    onInteract?: () => void;
    /**
     * Optional value attribute.
     */
    value?: string;
    /**
     * Optional error message to display below the field, in red, should be a state so that
     * it can be dynamically change the status of the field (error/no error)
     */
    errorMessage?: string;
    /**
     * Whether this text input supports autocompletion or not
     */
    autocomplete?: boolean;
    /**
     * Optional onClick handler.
     */
    onClick?: () => void;
    /**
     * Whether the component should behave like a selection option
     */
    selected?: boolean;
    /**
     * Size of text field
     */
    showMax?: boolean;
    /**
     * A set of possible values that will be suggested to the user as it types, showing only a few
     * of the closest matches, adding predictive text functionality to the input.
     */
    predictions?: Set<string>;
    /**
     * If predictive text is enabled, this sets the maximum number of predictions that are suggested
     * to the user at the same time in the dropdown.
     */
    maxPredictionEntries?: number;
}

/**
 * Input field where the user can introduce text.
 */
const TextInput = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, TextInputProps>(
    (
        {
            id,
            type = "text",
            label,
            placeholder,
            lines = 1,
            required,
            minLength,
            maxLength,
            disabled,
            defaultValue,
            onChange,
            onFocus,
            onInteract,
            value,
            errorMessage,
            autocomplete = true,
            onClick,
            selected = false,
            showMax = false,
            predictions,
            maxPredictionEntries = 5,
        },
        ref
    ) => {
        const [dropdownVisible, setDropdownVisible] = useState(false);
        const [fieldSize, setFieldSize] = React.useState<"medium" | "small">(
            window.innerWidth < 640 ? "medium" : "small"
        );
        const [dropdownItems, setDropdownItems] = useState<React.ReactElement<DropdownItemProps>[]>([]);
        const [showPassword, setShowPassword] = React.useState(false);
        const handleClickShowPassword = () => setShowPassword((show) => !show);
        const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
        };

        const inputClasses = classNames(
            errorMessage && "border-red",
            "appearance-none border rounded-xl w-full py-2 px-4 shadow",
            "placeholder-grey-light-1 placeholder-opacity-50 leading-tight resize-none font-sans",
            "focus:outline-none focus:border-yellow focus:shadow-outline",
            disabled ? "text-grey cursor-default" : "text-blue-dark",
            onInteract && !disabled ? "cursor-pointer" : "",
            /* eslint-disable no-nested-ternary */

            onClick && "cursor-pointer",
            selected
                ? "bg-yellow-light-1 focus:bg-yellow-light-1 border-yellow"
                : "bg-white focus:bg-white border-blue-light-3 hover:border-blue-light-1"
        );

        const onClickNoFocus = (e: React.MouseEvent | React.KeyboardEvent) => {
            // Do not give focus to the input when handling an input with onClick
            if (onInteract) {
                e.stopPropagation();
                e.preventDefault();
                onInteract();
            }
        };

        const getPredictions = (text: string): string[] => {
            if (!predictions || !text) {
                // Don't show any predictions if predictive text is not being used or if the user has not started typing
                return [];
            }

            // Fuzzy search the list of predictions by name
            const fuse = new Fuse(Array.from(predictions), { ignoreLocation: true });
            return fuse
                .search(text)
                .slice(0, maxPredictionEntries)
                .map((entry) => entry.item);
        };

        const generateDropdownItems = (text: string) => {
            if (predictions) {
                setDropdownItems(
                    getPredictions(text).map((s) => (
                        <DropdownItem
                            key={s}
                            label={s}
                            onClick={() => {
                                if (onChange) {
                                    const event = { currentTarget: { value: s } } as ChangeEvent<HTMLInputElement>;
                                    onChange(event);
                                }
                                setDropdownVisible(false);
                            }}
                            // this lets us call onClick before onBlur
                            onMouseDown={(e: MouseEvent<HTMLButtonElement>) => e.preventDefault()}
                        />
                    ))
                );
            }
        };

        const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
            if (predictions) {
                setDropdownVisible(true);
                generateDropdownItems(e.target.value);
            }
            onChange && onChange(e);
        };

        const onFocusHandler = () => {
            // Clear the error message, only show it if the user is out of focus
            if (predictions && value) {
                setDropdownVisible(true);
                generateDropdownItems(value);
            }
            onFocus && onFocus();
        };

        const onBlurHandler = (e: FocusEvent<HTMLDivElement>) => {
            const currentTarget = e.currentTarget;
            setTimeout(() => {
                if (!currentTarget.contains(document.activeElement)) {
                    setDropdownVisible(false);
                }
            }, 0);
        };

        useEffect(() => {
            function reportWindowSize() {
                if (window.innerWidth < 640) {
                    setFieldSize("medium");
                } else {
                    setFieldSize("small");
                }
            }

            window.addEventListener("resize", reportWindowSize);

            return () => {
                window.removeEventListener("resize", reportWindowSize);
            };
        }, []);

        const textArea = (
            <TextField
                id={id}
                name={id}
                label={label}
                variant="outlined"
                placeholder={placeholder}
                rows={lines}
                className={inputClasses}
                multiline
                required={required}
                inputProps={{ maxLength: { maxLength }, minLength: { minLength } }}
                disabled={disabled}
                defaultValue={defaultValue}
                onChange={onChange}
                onFocus={onFocus}
                onMouseDown={onClickNoFocus}
                onKeyPress={onClickNoFocus}
                value={value !== null ? value : ""}
                inputRef={ref as React.ForwardedRef<HTMLTextAreaElement>}
                autoComplete={autocomplete ? "on" : "off"}
            />
        );
        let textInput = (
            <TextField
                id={id}
                label={label}
                variant="outlined"
                size={fieldSize}
                placeholder={placeholder}
                name={id}
                type={type}
                className={inputClasses}
                required={required}
                disabled={disabled}
                defaultValue={defaultValue}
                data-input
                onChange={onChangeHandler}
                onFocus={onFocusHandler}
                onMouseDown={onClickNoFocus}
                onKeyPress={onClickNoFocus}
                value={value !== null ? value : ""}
                ref={ref as React.ForwardedRef<HTMLInputElement>}
                autoComplete={autocomplete ? "on" : "off"}
                onClick={onClick}
            />
        );

        const MuiInputLabel = React.forwardRef(() => (
            <InputLabel classes="!leading-3 overflow-visible" htmlFor="outlined-adornment-password" size="small">
                {`${label}${required ? "*" : ""}`}
            </InputLabel>
        ));
        MuiInputLabel.displayName = "MuiInputLabel";

        const passWordInput = (
            <FormControl variant="outlined">
                <MuiInputLabel />
                <OutlinedInput
                    id="outlined-adornment-password"
                    size={fieldSize}
                    type={showPassword ? "text" : "password"}
                    sx={{ background: "rgb(232,240,254)" }}
                    onChange={onChange}
                    onFocus={onFocus}
                    onMouseDown={onClickNoFocus}
                    onKeyPress={onClickNoFocus}
                    ref={ref as React.ForwardedRef<HTMLInputElement>}
                    value={value !== null ? value : undefined}
                    placeholder={defaultValue}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Password"
                />
            </FormControl>
        );

        if (predictions) {
            // Create a dropdown for the predictions
            textInput = (
                <Dropdown
                    anchor={textInput}
                    sameWidth
                    visible={dropdownVisible}
                    onFocus={onFocusHandler}
                    onChange={onChangeHandler}
                    onBlur={onBlurHandler}
                >
                    {dropdownItems}
                </Dropdown>
            );
        }
        if (type === "password") {
            return (
                <div className="flex flex-col relative w-full relative">
                    {passWordInput}
                    {errorMessage && <p className="text-xs text-left text-red pt-1 absolute top-10">{errorMessage}</p>}
                    {maxLength && showMax && (
                        <p className="absolute bottom-2 right-3 text-blue-light-2">
                            {value?.length || defaultValue?.length || 0}/{maxLength}
                        </p>
                    )}
                </div>
            );
        }
        return (
            <div className="flex flex-col relative w-full">
                {lines > 1 ? textArea : textInput}
                {errorMessage && <p className="text-xs text-left text-red pt-1 absolute top-10">{errorMessage}</p>}
                {maxLength && showMax && (
                    <p className="absolute bottom-2 right-3 text-blue-light-2">
                        {value?.length || defaultValue?.length || 0}/{maxLength}
                    </p>
                )}
            </div>
        );
    }
);

export default TextInput;
