import React, { createContext, useState, useContext, useMemo, useCallback } from "react";
import axiosApi from "./axiosApi";
import { ModelRef, Choice } from "./types";
import useAlert from "./useAlert";

interface RefCache {
    choiceRefs: Map<string, Array<Choice>>;
}

interface RefCacheContextType {
    getModelRef: (name: string) => Promise<Array<ModelRef> | undefined>;
    getChoiceRef: (name: string) => Array<Choice>;
}

const RefCacheContext = createContext<RefCacheContextType>({
    refCache: {} as RefCache,
    getModelRef: () =>
        new Promise<Array<ModelRef>>((resolve) => {
            resolve(new Array<ModelRef>());
        }),
    getChoiceRef: () => new Array<Choice>(),
} as RefCacheContextType);

interface RefCacheProviderProps {
    children: React.ReactNode;
}

export const RefCacheProvider: React.FC<RefCacheProviderProps> = ({ children }) => {
    const [refCache, setRefCache] = useState<RefCache>({
        choiceRefs: new Map<string, Array<Choice>>(),
    } as RefCache);

    const { addAlert } = useAlert();

    /**
     * Query the server for the model ref and return the parsed result.
     * If the ref can't be obtained from the server for some reason (for example if
     * the keyword is not a valid ref) it returns undefined, and shows an error alert.
     *
     * @param keyword Keyword of the model, which is equal to the API endpoint for that model, e.g.
     * for the Country model we would query "countries"
     * @returns The full array of refs for the queried model, or undefined if it could not be retrieved from the server.
     */
    const getModelRef = useCallback(
        (keyword: string): Promise<Array<ModelRef> | undefined> =>
            axiosApi
                .get(`/${keyword}/ref/`)
                .then((response) => response.data)
                .catch(() => {
                    addAlert("An unexpected error occured while fetching some data from the server.", "error");
                    return undefined;
                }),
        [addAlert]
    );

    /**
     * Gets the choice list from the cache, or if it is not in the cache, it queries the server for all choices and stores them
     * in the cache for future queries. If the choices can't be obtained from the server or if the choice is invalid, it returns
     * undefined, and shows an error alert.
     *
     * Note that the main difference with this method and getModelRef is that if a choice is not in the cache, this will retrieve
     * all choices from the server with a single query, as we don't have API endpoints for every choice field.
     *
     * @param keyword Keyword of the choice field, which should be equivalent to the choice field name in the server, in camelCase.
     * For example, for the TravelType field, the keyword would be "travelType".
     *
     * @returns The full array of choices for the given keyword, or undefined if it could not be retrieved from the server.
     */
    const getChoiceRef = useCallback(
        (keyword: string): Array<Choice> | undefined => {
            const refs = refCache.choiceRefs.get(keyword);

            if (refs !== undefined) {
                return refs;
            }

            axiosApi
                .get("/choices/")
                .then((response) => {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const [field, data] of Object.entries(response.data)) {
                        const choicesArray = data as Array<[string, string]>;
                        const choices: Choice[] = [];
                        for (let i = 0; i < choicesArray.length; i += 1) {
                            choices.push({ value: choicesArray[i][0], display: choicesArray[i][1] });
                        }
                        refCache.choiceRefs.set(field, choices as Choice[]);
                    }
                    setRefCache({ ...refCache });
                    return response.data;
                })
                .catch(() => {
                    addAlert("An unexpected error occured while fetching some data from the server.", "error");
                });

            return undefined;
        },
        [addAlert, refCache]
    );

    const contextValue = useMemo(
        () =>
            ({
                getModelRef: (keyword) => getModelRef(keyword),
                getChoiceRef: (keyword) => getChoiceRef(keyword),
            } as RefCacheContextType),
        [getChoiceRef, getModelRef]
    );

    return <RefCacheContext.Provider value={contextValue}>{children}</RefCacheContext.Provider>;
};

/**
 * Hook used to cache static reference data from the server, like model refs (which are just {id, name}) or
 * choice field options. This info only needs to be requested once, since it never changes.
 */
const useModelRef = (): RefCacheContextType => {
    const { getModelRef, getChoiceRef } = useContext(RefCacheContext);
    return { getModelRef, getChoiceRef };
};

export default useModelRef;
