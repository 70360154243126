import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import Icon from "@mui/material/Icon";
import { close, open } from "../../redux/sidebar";
import { RootState } from "../../store";

export interface SidebarProps {
    /**
     * Path to the image to be used in the header, namely the Moken logo.
     */
    headerImg: string;
    /**
     * Text to be shown in the header in case the image cannot be loaded correctly.
     */
    headerText: string;
    /**
     * Components that make up the sidebar, mainly menus.
     */
    children?: React.ReactNode;
}

/**
 * The sidebar is the main means of navigation through the platform.
 *
 * The sidebar contains a header, which can hold an image or some text, and the rest are menu items
 * used for navigation. Menu items can also have submenus. For more info see SidebarMenuItem.
 */
const Sidebar: React.FC<SidebarProps> = ({ headerImg, headerText, children }) => {
    const isOpen = useSelector((state: RootState) => state.sidebar.isOpen);
    const dispatch = useDispatch();

    return (
        <div
            className={classNames(
                "fixed top-0 bottom-0 bg-blue-dark transition-left ease-in-out duration-200 z-50 overflow-hidden",
                isOpen ? "w-full absolute sm:fixed sm:w-80 " : " w-0 sm:w-20"
            )}
        >
            {/* Header */}
            {isOpen ? (
                <div className="flex flex-row items-center justify-between mx-7 min-h-[115px]">
                    <div className="w-40 mt-10 mb-10 font-sans text-xl font-bold text-yellow">
                        <img src={headerImg} className="object-contain" alt={headerText} />
                    </div>
                    <button
                        className="rounded-full w-8 h-8 items-center"
                        onClick={() => {
                            dispatch(close());
                        }}
                        type="button"
                    >
                        <Icon className="text-grey-light-1">keyboard_double_arrow_left</Icon>
                    </button>
                </div>
            ) : (
                <div className="flex flex-row items-center justify-between mx-7 min-h-[115px]">
                    <button
                        type="button"
                        className="w-40 mt-10 mb-10 font-sans text-xl font-bold text-yellow "
                        onClick={() => {
                            dispatch(open());
                        }}
                    >
                        <img src={headerImg} className="object-contain" alt={headerText} />
                    </button>
                </div>
            )}
            {/* Menu elements */}
            <ul className="grow h-[calc(100vh-130px)] overflow-y-scroll no-scrollbar scroll-smooth">{children}</ul>
        </div>
    );
};

export default Sidebar;
