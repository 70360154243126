import React from "react";
import Icon from "@mui/material/Icon";
import IconButton from "./IconButton";

export interface AttachmentProps {
    name: string;
    onDelete: () => void;
}

const Attachment: React.FC<AttachmentProps> = ({ name, onDelete }) => (
    <div className="flex items-center justify-around rounded-xl w-112 h-10 bg-platinum px-5 py-6 min-w-min group">
        <div className="flex items-center space-x-3">
            <Icon className="font-bold text-blue-dark text-lg">attachment_file</Icon>
            <p className="pr-7">{name}</p>
        </div>
        <div className="invisible group-hover:visible justify-self-end flex-1 flex-shrin flex justify-end">
            <IconButton icon="close" onClick={onDelete} />
        </div>
    </div>
);

export default Attachment;
