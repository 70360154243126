import React, { useState } from "react";
import Icon from "@mui/material/Icon";
import SlideDown from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import classNames from "classnames";

export interface CollapsableSectionProps {
    /**
     * Title of the collapsable section, shown on top, next to the toggle button.
     */
    title: string;

    /**
     * Whether the section is collapsed by default or not.
     * By default it is false.
     */
    closedByDefault?: boolean;

    /**
     * Children components of the section.
     */
    children: React.ReactNode;

    /**
     * Whether the open/close button is on the left or right of the title.
     * By default it's on the left.
     */
    buttonSide?: "left" | "right";

    /**
     * The size of the title. Normal is equivalent to normal sized text, while
     * big is equivalent to text-2xl.
     */
    titleSize?: "normal" | "big";

    /**
     * Optional node to appear on the right of the title.
     * Mainly used for edit/delete buttons.
     */
    extraNode?: React.ReactNode;
    /**
     * Size of the arrow that will translate to fontsize values
     */
    arrowSize?: "xs" | "sm" | "md" | "lg" | "xl";
}

/**
 * A section which can be collapsed under a row.
 * Has a button to toggle whether the content is collapsed or not, and a title.
 */
const CollapsableSection: React.FC<CollapsableSectionProps> = ({
    title,
    children,
    closedByDefault = false,
    arrowSize,
    buttonSide = "left",
    titleSize = "normal",
    extraNode,
}) => {
    const [collapsed, setCollapsed] = useState(closedByDefault);
    return (
        <section className="flex flex-col">
            <div
                className={classNames(
                    "items-center space-x-4",
                    buttonSide === "left" ? "flex" : "flex flex-row-reverse justify-between"
                )}
            >
                <div className="flex items-center space-x-5">
                    <p className={classNames("font-bold font-sans text-grey", titleSize === "big" && "text-2xl")}>
                        {title}
                    </p>
                    {extraNode}
                </div>
                <button type="button" className="relative rounded-full  py-2 top-1" onClick={() => setCollapsed(!collapsed)}>
                    <Icon className={`text-${arrowSize} text-grey`}>
                        {`${collapsed ? "chevron_right" : "expand_more"}`}
                        
                    </Icon>
                </button>
            </div>
            <SlideDown closed={collapsed} transitionOnAppear={false}>
                {children}
            </SlideDown>
        </section>
    );
};

export default CollapsableSection;
