/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import MuiAvatar from "@mui/material/Avatar";
import { MokenImage } from "../types";

export interface AvatarProps {
    /**
     * Optional URL of the image to show in the avatar.
     */
    image?: string | MokenImage;

    /**
     * Tooltip
     */
    title?: string;

    /**
     * Text to show in case no image could be loaded.
     * It is meant to be just 2 or 3 letters, i.e. something that can fit
     * within the avatar circle.
     */
    altText: string;

    /**
     * Optional click handler.
     */
    onClick?: () => void;

    /**
     * By default an avatar has w-11 and h-11, if set to small the size will be 7 instead, and big has size 20.
     * TODO: Make size configurable in a way that doesn"t get lost when building for production, as doing w-${size} does not work.
     */
    size?: "md" | "sm" | "lg" | "xl";
}

/**
 * An avatar is a circle with an image or some text. It can be clickable and it is
 * usually used to represent users.
 */
const Avatar = React.forwardRef<HTMLDivElement, AvatarProps>(
    ({ image, altText, onClick, size = "md", title}, ref) => {
        const [validImage, setValidImage] = useState(image !== undefined);
        let avatarSize: number;

        switch (size) {
            case "sm":
                avatarSize = 28;
                break;

            case "md":
                avatarSize = 32;
                break;

            case "lg":
                avatarSize = 36;
                break;
        
            default:
                avatarSize = 40;
                break;
        }

        useEffect(() => {
            setValidImage(image !== undefined);
        }, [image]);
        return (
            <MuiAvatar
                ref={ref}
                title={title}
                sx={{
                    width: avatarSize,
                    height: avatarSize
                }}
            >
                {onClick && (
                    <div
                        className="w-full h-full absolute"
                        onClick={onClick}
                        onKeyPress={onClick}
                        role="button"
                        tabIndex={0}
                        aria-label="Avatar"
                    />
                )}
                {validImage ? (
                    <img
                        src={image}
                        alt={altText}
                        className="flex items-center justify-center w-full h-full rounded-full object-cover border border-grey-light-3"
                        onError={() => setValidImage(false)}
                    />
                ) : (
                    <span className="flex items-center justify-center w-full h-full text-sm">{altText}</span>
                )}
            </MuiAvatar>
        );
    }
);

export default Avatar;
