import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveData } from "../redux/reservations";
import axiosApi from "../axiosApi";
import Button from "../components/Button";
import Container from "../components/Container";
import DatetimePicker from "../components/DatetimePicker";
import List from "../components/List/List";
import Pagination from "../components/Pagination";
import ScreenTemplate from "../components/ScreenTemplate";
import SearchBox from "../components/SearchBox";
import Selection from "../components/Selection";
import Spinner from "../components/Spinner";
import TagGroup from "../components/TagGroup";
import TextInput from "../components/TextInput";
import { ContactData, ModelRef } from "../types";
import useAlert from "../useAlert";
import useModelRef from "../useModelRef";
import { getChoice, getFullName } from "../utils";
import { openModel, closeModel, hideName } from "../redux/header";

const PAGE_SIZE = 10;

export interface ReservationRequestData {
    id: number;
    reference: string;
    destinations: Array<ModelRef>;
    comments: string;
    travel_start_date: Date;
    travel_days: number;
    amount_travelers: number;
    travel_type: string;
    budget: number;
    // Contact info
    name: string;
    surname: string;
    email: string;
    phone: string;
    interests: Array<ModelRef>;
    traveler_type: string;
    contact?: ContactData;
}

interface ReservationRequestRow {
    id: number;
    full_name: string;
    reference: string;
    destinations: string;
    travel_start_date: string;
    travel_days: number;
    amount_travelers: number;
}

const inboxFields: {
    key: keyof ReservationRequestRow;
    title: string;
}[] = [
    {
        key: "full_name",
        title: "Nombre",
    },
    {
        key: "reference",
        title: "Referencia",
    },
    {
        key: "destinations",
        title: "Destinos",
    },
    {
        key: "travel_start_date",
        title: "Fecha",
    },
    {
        key: "travel_days",
        title: "Días",
    },
    {
        key: "amount_travelers",
        title: "Pax",
    },
];

const InboxScreen: React.FC = () => {
    const [reservationData, setReservationData] = useState<Array<ReservationRequestData> | null>(null);
    const [openReservation, setOpenReservation] = useState<ReservationRequestData | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(1);
    const [selectedRows, setSelectedRows] = useState(new Set<number>());
    const firstinit = useRef<boolean>(true);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const { addAlert } = useAlert();
    const { getChoiceRef } = useModelRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const travelTypes = getChoiceRef("travelType");
    const travelerTypes = getChoiceRef("travelerType");

    const screenTitle = "Bandeja de entrada";
    const [searchParams] = useSearchParams();
    const inboxFile = searchParams.get("exp");

    const buildInboxRow = (reservation: ReservationRequestData): ReservationRequestRow => ({
        id: reservation.id,
        full_name: getFullName(reservation.name, reservation.surname),
        reference: reservation.reference,
        destinations: reservation.destinations.map((dest) => dest.name).join(" "),
        travel_start_date: reservation.travel_start_date?.toString(),
        travel_days: reservation.travel_days,
        amount_travelers: reservation.amount_travelers,
    });

    const fetchInbox = useCallback(() => {
        axiosApi
            .get(`/inbox/?limit=${PAGE_SIZE}&offset=${(currentPage - 1) * PAGE_SIZE}`)
            .then((response) => {
                setTotalRecords(response.data.count);
                // TODO: For every record, check if contact data is present. If so, fill out the contact info (name,
                // surname, etc) from it. Otherwise just grab whatever comes in these fields.
                const data = (response.data.results as Array<ReservationRequestData>).map((req) => {
                    if (!req.contact) {
                        return req;
                    }
                    return {
                        ...req,
                        name: req.contact.name,
                        surname: req.contact.surname || "",
                        phone: req.contact.phone || "",
                        email: req.contact.email,
                    };
                });
                setReservationData(data);
                setIsLoaded(true);
            })
            .catch(() => {
                addAlert("Error al recuperar las solicitudes.", "error");
            });
    }, [currentPage, addAlert]);

    const handleDelete = (id: number) => {
        // TODO: Show confirmation dialog
        axiosApi
            .delete(`/inbox/${id}/`)
            .then(() => {
                setReservationData(null);
                setCurrentPage(1);
                setReservationData(null);
                fetchInbox();
            })
            .catch(() => {
                // TODO
            });
    };

    const handleDeleteMultiple = () => {
        if (openReservation || selectedRows.size === 0) return;
        // TODO: Allow deleting multiple items with a single query via the API
        // TODO: Show confirmation dialog
        const idArray = Array.from(selectedRows);
        for (let i = 0; i < idArray.length; i += 1) {
            handleDelete(idArray[i]);
        }
        setSelectedRows(new Set());
    };

    /**
     * Called after the user has selected a client from the client modal to be linked to the reservation, after having clicked
     * the create reservation button.
     * Creates a new reservation with data from the request, and then opens the reservation details view.
     * The current request is also automatically deleted after creating a reservation from it.
     */
    const createReservation = () => {
        if (!openReservation) return;

        axiosApi
            .get(`/inbox/${openReservation.id}/convert_to_reservation/`)
            .then((response) => {
                dispatch(closeModel());
                navigate("/quotations/", { state: { reservation: response.data, keepOpen: true } });
            })
            .catch(() => {
                addAlert("Error al crear la reserva.", "error");
            });
    };

    useEffect(() => {
        setReservationData(null);
        fetchInbox();
    }, [currentPage, fetchInbox]);

    const goToLinkedRequest = useCallback(() => {
        if (firstinit.current) {
            const waitForStateFullyLoaded: Promise<ReservationRequestData | null> = new Promise((resolve) => {
                const matchedResdervationRequest =
                    (reservationData && reservationData.find((r) => r.id === Number(inboxFile))) || null;
                resolve(matchedResdervationRequest);
            });
            waitForStateFullyLoaded.then((fullReservationData) => {
                setOpenReservation(fullReservationData);
            });

            firstinit.current = false;
        }
    }, [reservationData, inboxFile]);
    isLoaded && goToLinkedRequest();

    // When a request is open/closed, update the ID value shown in the header
    useEffect(() => {
        if (openReservation) {
            dispatch(openModel({ modelId: openReservation.id }));
        } else {
            dispatch(closeModel());
        }
    }, [openReservation, dispatch]);

    dispatch(saveData());
    dispatch(hideName());

    const loadingScreen = (
        <ScreenTemplate title={screenTitle}>
            <Container>
                <div className="flex items-center justify-center h-full w-full">
                    <Spinner />
                </div>
            </Container>
        </ScreenTemplate>
    );
    if (!reservationData && !openReservation) {
        return loadingScreen;
    }

    const listView = (
        <div className="flex flex-col h-full p-10 space-y-10">
            <SearchBox placeholder="Buscar nombre, destino o fecha..." onChange={() => {}} />
            <div className="w-full h-full">
                {reservationData && (
                    <div className="flex flex-col h-full">
                        <div className="grow">
                            <List
                                objects={reservationData.map((reservation) => buildInboxRow(reservation))}
                                fields={inboxFields}
                                listType="inboxlist"
                                multiselection
                                onRowClick={(id) =>
                                    setOpenReservation(reservationData.find((r) => r.id === id) || null)
                                }
                                selectedRows={selectedRows}
                                onSelect={setSelectedRows}
                            />
                        </div>
                        <div className="self-end shrink">
                            <Pagination
                                currentPage={currentPage}
                                resultsPerPage={PAGE_SIZE}
                                totalResults={totalRecords}
                                onPrevious={() => setCurrentPage(currentPage - 1)}
                                onNext={() => setCurrentPage(currentPage + 1)}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );

    const inboxDetailView = openReservation && (
        <div className="flex flex-col h-full p-10 space-y-10">
            <h2 className="text-2xl font-bold font-sans">Datos de la solicitud</h2>
            <div className="grid grid-cols-4 gap-6">
                <div className="col-span-1">
                    <TextInput
                        id="id"
                        type="text"
                        label="Nº Solicitud"
                        defaultValue={openReservation.id ? openReservation.id.toString() : ""}
                        disabled
                    />
                </div>
                <div className="col-span-1">
                    <TextInput id="creation_date" type="text" label="Fecha de creación" disabled />
                </div>
                <div className="col-span-1">
                    <DatetimePicker
                        id="travel_start_date"
                        label="Fecha de viaje"
                        defaultValue={new Date(openReservation.travel_start_date)}
                        disabled
                    />
                </div>
                <div className="col-span-1">
                    <TextInput
                        id="travel_days"
                        type="text"
                        label="Nº Días"
                        defaultValue={openReservation.travel_days ? openReservation.travel_days.toString() : ""}
                        disabled
                    />
                </div>
                <div className="col-span-1">
                    <TextInput id="name" type="text" label="Nombre" defaultValue={openReservation.name} />
                </div>
                <div className="col-span-1">
                    <TextInput id="surname" type="text" label="Apellidos" defaultValue={openReservation.surname} />
                </div>
                <div className="col-span-1">
                    <TextInput id="phone" type="text" label="Teléfono" defaultValue={openReservation.phone} disabled />
                </div>
                <div className="col-span-1">
                    <TextInput id="email" type="text" label="Email" defaultValue={openReservation.email} disabled />
                </div>
                <div className="col-span-1">
                    <TextInput
                        id="amount_travelers"
                        type="text"
                        label="Nº Personas"
                        defaultValue={
                            openReservation.amount_travelers ? openReservation.amount_travelers.toString() : ""
                        }
                    />
                </div>
                <div className="col-span-1">
                    <Selection
                        id="traveler_type"
                        label="Tipo de viajero"
                        size="small"
                        choices={travelerTypes}
                        defaultValue={getChoice(openReservation.traveler_type, travelerTypes)}
                        disabled
                    />
                </div>
                <div className="col-span-1">
                    <TextInput
                        id="travel_type"
                        type="text"
                        label="Tipo de viaje"
                        defaultValue={getChoice(openReservation.travel_type, travelTypes)?.display}
                        disabled
                    />
                </div>
                <div className="col-span-1">
                    <TextInput
                        id="budget"
                        type="text"
                        label="Presupuesto por persona"
                        defaultValue={openReservation.budget ? openReservation.budget.toString() : ""}
                        disabled
                    />
                </div>
                <div className="col-span-2">
                    <TextInput
                        id="reference"
                        type="text"
                        label="Asunto / Referencia"
                        defaultValue={openReservation.reference}
                        disabled
                    />
                </div>
                <div className="col-span-2">
                    <TagGroup title="Destinos" values={openReservation.destinations} />
                </div>
                <div className="col-span-2">
                    <TextInput
                        id="comments"
                        type="text"
                        label="Comentarios cliente"
                        lines={6}
                        defaultValue={openReservation.comments}
                        disabled
                    />
                </div>
                <div className="col-span-2">
                    <TagGroup
                        title="Gustos"
                        values={openReservation.interests && openReservation.interests.map((interest) => interest.name)}
                        type="yellow"
                    />
                </div>
            </div>
            <div className="flex items-center justify-between">
                <div className="w-max">
                    <Button label="Cancelar" type="btn_dark" onClick={() => setOpenReservation(null)} />
                </div>
                <div className="w-max">
                    <Button label="Crear presupuesto" icon="add" type="btn_yellow" onClick={createReservation} />
                </div>
            </div>
        </div>
    );

    return (
        <ScreenTemplate
            title={screenTitle}
            backButtonVisible={openReservation !== null}
            onBack={() => {
                fetchInbox();
                setOpenReservation(null);
            }}
        >
            <Container
                numSelected={!openReservation ? selectedRows.size : undefined}
                actionArea={
                    <Button
                        label={`Eliminar ${selectedRows.size} ${selectedRows.size < 2 ? "item" : "items"}`}
                        onClick={handleDeleteMultiple}
                        type="btn_red"
                    />
                }
            >
                {openReservation ? inboxDetailView : listView}
            </Container>
        </ScreenTemplate>
    );
};

export default InboxScreen;
