import React, { useEffect } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import Container from "./Container";

export interface ModalProps {
    /**
     * Content of the modal
     */
    children?: React.ReactNode;
    /**
     * Whether the modal is visible or not
     */
    visible: boolean;
    /**
     * Function for setting the visible state passed by the parent
     */
    visibleHandler?: (visible: boolean) => void;

    size?: "sm" | "md" | "lg";
}

const Modal: React.FC<ModalProps> = ({ children, visible, visibleHandler, size }) => {
    const isSidebarOpen = useSelector((state: RootState) => state.sidebar.isOpen);
    const handleCloseModalEvent = (e: Event) => {
        e.stopPropagation();
        visibleHandler && visibleHandler(false);
    };

    let modalSize = "basis-10/12";

    switch (size) {
        case "sm":
            modalSize = "basis-[300px]";
            break;
        case "md":
            modalSize = "basis-5/12";
            break;
        case "lg":
            modalSize = "basis-8/12";
            break;

        default:
            modalSize = "basis-full sm:basis-10/12";
            break;
    }

    // Add event handler only once, and remove it on componentDidUnmount. Implementors will have
    // to create a `myEvent = new Event('closeModal')` and then call `window.dispatchEvent(myEvent)`
    useEffect(() => {
        window.addEventListener("closeModal", handleCloseModalEvent);

        return () => window.removeEventListener("closeModal", handleCloseModalEvent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return visible ? (
        <div
            className={classNames(
                "fixed block sm:flex justify-center items-center top-0 left-0 bottom-0 right-0 overflow-y-scroll z-40 transparent-black h-[100vh] sm:h-auto no-scrollbar sm:show-scrollbar",
                isSidebarOpen ? "sm:left-80 sm:w-[calc(100%-20rem)]" : "xs:left-0 sm:left-20 sm:w-[calc(100%-100px)] "
            )}
        >
            <div className={`sm:my-10 ${modalSize}`}>
                <Container bg={false} fullscreen={false} bubble>
                    {children}
                </Container>
            </div>
        </div>
    ) : null;
};

export default Modal;
