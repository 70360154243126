import React from "react";
import { getInitials } from "../utils";
import Avatar from "./Avatar";
import Button from "./Button";
import Rating from "./Rating";

export interface ConnectionRequestCardProps {
    image?: string;

    name: string;

    commonConnections: number;

    numRatings?: number;
    rating?: number;

    onAccept?: () => void;
    onIgnore?: () => void;
}

const ConnectionRequestCard: React.FC<ConnectionRequestCardProps> = ({
    image,
    name,
    commonConnections,
    numRatings,
    rating,
    onAccept,
    onIgnore,
}) => (
    <div className="rounded-lg shadow-lg flex flex-col items-center space-y-5 p-3">
        <div className="flex flex-col items-center space-y-5 h-48">
            <Avatar altText={getInitials(name)} image={image} size="xl" />
            <div className="flex flex-col space-y-1 items-center">
                <p className="font-bold">{name}</p>
                <p className="text-sm">{commonConnections} contactos en común</p>
            </div>
            {rating && numRatings && <Rating numRatings={numRatings} rating={rating} />}
        </div>
        <div className="flex flex-col w-full space-y-3 h-24">
            <Button label="Aceptar" onClick={onAccept} />
            <Button label="Ignorar" type="btn_transparent" onClick={onIgnore} />
        </div>
    </div>
);
export default ConnectionRequestCard;
