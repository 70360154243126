import React from "react";
import { useDispatch } from "react-redux";
import { dataPending } from "../redux/reservations";
import { ReservationDetailData, TemplateDetailData } from "../types";
import { getLowestInvalidId } from "../utils";
import Button from "./Button";
import CollapsableSection from "./CollapsableSection";
import Container from "./Container";
import IconButton from "./IconButton";
import TextInput from "./TextInput";

interface TermsTabProps {
    reservationData: ReservationDetailData | TemplateDetailData;
    setReservationData: (data: ReservationDetailData | TemplateDetailData) => void;
}

/**
 * Tab for the page with the terms and conditions, cancellation policy, and other extra policies
 * the user may add.
 */
const TermsTab: React.FC<TermsTabProps> = ({ reservationData, setReservationData }) => {
    const dispatch = useDispatch();
    let textInputTimer: ReturnType<typeof setTimeout>;
    const onAddPolicy = () => {
        const newPolicies = reservationData.extra_policies.slice();
        newPolicies.push({ id: getLowestInvalidId(newPolicies), title: "", text: "" });
        setReservationData({ ...reservationData, extra_policies: newPolicies });
    };

    return (
        <Container>
            <div className="flex flex-col pt-4">
                <CollapsableSection title="1. Incluido / No incluido" buttonSide="left" titleSize="big" arrowSize="md">
                    <div className="flex  w-full justify-between mt-5 flex-wrap lg:flex-nowrap gap-4">
                        <div className="w-full">
                            <TextInput
                                id="included"
                                type="text"
                                label="Incluido"
                                maxLength={7800}
                                showMax
                                defaultValue={reservationData.included}
                                lines={9}
                                onChange={(ev) => {
                                    const value = ev.currentTarget.value;
                                    clearTimeout(textInputTimer);
                                    textInputTimer = setTimeout(() => {
                                        setReservationData({ ...reservationData, included: value });
                                        dispatch(dataPending());
                                    }, 600);

                                    return () => clearTimeout(textInputTimer);
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <TextInput
                                id="not_included"
                                type="text"
                                label="No incluido"
                                maxLength={7800}
                                showMax
                                defaultValue={reservationData.not_included}
                                lines={9}
                                onChange={(ev) => {
                                    const value = ev.currentTarget.value;
                                    clearTimeout(textInputTimer);
                                    textInputTimer = setTimeout(() => {
                                        setReservationData({ ...reservationData, not_included: value });
                                        dispatch(dataPending());
                                    }, 600);

                                    return () => clearTimeout(textInputTimer);
                                }}
                            />
                        </div>
                    </div>
                </CollapsableSection>
                <div className="pt-12">
                    <CollapsableSection
                        title="2. Condiciones de cancelación"
                        buttonSide="left"
                        titleSize="big"
                        arrowSize="md"
                    >
                        <div className="flex w-full mt-5">
                            <div className="w-full">
                                <TextInput
                                    id="cancel_conditions"
                                    type="text"
                                    defaultValue={reservationData.cancellation_policy}
                                    lines={9}
                                    maxLength={3800}
                                    showMax
                                    onChange={(ev) => {
                                        const value = ev.currentTarget.value;
                                        clearTimeout(textInputTimer);
                                        textInputTimer = setTimeout(() => {
                                            setReservationData({
                                                ...reservationData,
                                                cancellation_policy: value,
                                            });
                                            dispatch(dataPending());
                                        }, 600);

                                        return () => clearTimeout(textInputTimer);
                                    }}
                                />
                            </div>
                        </div>
                    </CollapsableSection>
                </div>

                {reservationData.extra_policies.map((policy, idx) => (
                    <div className="flex flex-col space-y-2 w-full mt-5" key={policy.id}>
                        <div className="items-center space-x-5 grid grid-cols-12">
                            <div className="w-full col-span-11">
                                <TextInput
                                    id="policy_title"
                                    type="text"
                                    defaultValue={policy.title}
                                    onChange={(ev) => {
                                        const newPolicies = reservationData.extra_policies.slice();
                                        newPolicies[idx].title = ev.currentTarget.value;
                                        clearTimeout(textInputTimer);
                                        textInputTimer = setTimeout(() => {
                                            setReservationData({
                                                ...reservationData,
                                                extra_policies: newPolicies,
                                            });
                                            dispatch(dataPending());
                                        }, 600);

                                        return () => clearTimeout(textInputTimer);
                                    }}
                                />
                            </div>
                            <div className="min-w-max border border-red rounded-md col-span-1 flex justify-center">
                                <IconButton
                                    icon="delete"
                                    iconSize="27px"
                                    color="red"
                                    onClick={() => {
                                        setReservationData({
                                            ...reservationData,
                                            extra_policies: reservationData.extra_policies.filter(
                                                (p) => p.id !== policy.id
                                            ),
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="w-full">
                            <TextInput
                                id="policy_text"
                                type="text"
                                defaultValue={policy.text}
                                lines={12}
                                onChange={(ev) => {
                                    const newPolicies = reservationData.extra_policies.slice();
                                    newPolicies[idx].text = ev.currentTarget.value;
                                    clearTimeout(textInputTimer);
                                    textInputTimer = setTimeout(() => {
                                        setReservationData({
                                            ...reservationData,
                                            extra_policies: newPolicies,
                                        });
                                        dispatch(dataPending());
                                    }, 600);

                                    return () => clearTimeout(textInputTimer);
                                }}
                            />
                        </div>
                    </div>
                ))}

                <div className="w-full pt-6 flex justify-end mb-20">
                    <Button label="Añadir" icon="add" onClick={onAddPolicy} type="btn_yellow" title="Añadir término" />
                </div>
            </div>
        </Container>
    );
};

export default TermsTab;
