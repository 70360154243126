import React, { useState } from "react";
import { ReactSortable } from "react-sortablejs";
import TravelDay from "./TravelDay";
import { ModelRef } from "../types";

export interface DayIdentifier {
    /**
     * Unique ID of the TravelDay.
     */
    id: number;

    /**
     * Position of the block in which the day lives.
     */
    block: number;

    /**
     * ID of the block in which the day lives.
     */
    block_id: number;

    /**
     * Position within the block.
     */
    position: number;

    /**
     * Title of the day.
     */
    title: string;
    /**
     * Country of the day
     */
    country?: ModelRef;

    /**
     * Location of the day
     */
    location?: ModelRef;
}

export interface ItineraryProps {
    /**
     *  Date of first itinerary item
     */
    startDate: string | undefined;
    /**
     * List of days for a given Itinerary.
     */
    days?: DayIdentifier[];
    /**
     * Handler that will be called when the user adds a day at the given position.
     */
    onAdd?: () => void;
    /**
     * Handler that will be called when the user clicks on the delete button of the
     * currently selected day.
     */
    onDelete?: (id: number) => void;
    /**
     * Handler that will be called when the user sorts the itinerary days.
     */
    onSort?: (sorted: DayIdentifier[]) => void;
    /**
     * Whether days can be added/moved/removed, should be set to true once a trip is completed.
     */
    readOnly?: boolean;
    /**
     * Which of the days is currently selected (block position and day position), 0-based index
     */
    selectedBlock?: number;
    selectedDay?: number;
    /**
     * Function to be called whenever a day of the itinerary is selected/clicked
     */
    onSelect?: (position: number, block: number, positionInitinerary: number) => void;
}

const Itinerary: React.FC<ItineraryProps> = ({
    startDate,
    days = [],
    onAdd,
    onDelete,
    onSelect,
    onSort,
    readOnly = false,
    selectedBlock,
    selectedDay,
}) => {
    const getDateForItineraryDay = (dateString: string, position: number) => {
        const newdate = new Date(dateString);
        newdate.setDate(newdate.getDate() + position);
        const date = `0${newdate.getDate()}`;
        const month = `0${newdate.getMonth() + 1}`;
        return `${date.slice(-2)}/${month.slice(-2)}`;
    };

    const [isDoubleCheckDisabled, setIsdoubleCheckDisabled] = useState<boolean>(false);

    const handleDisable = () => {
        setIsdoubleCheckDisabled(true);
    };

    return (
        <div className="relative pr-20 h-full flex mx-auto">
            <ReactSortable
                className="relative flex flex-row items-end justify-center top-1 h-full"
                direction="horizontal"
                list={days}
                setList={onSort}
            >
                {days.map((day, i) => (
                    <TravelDay
                        doublecheckDisabled={isDoubleCheckDisabled}
                        date={startDate && getDateForItineraryDay(startDate, i)}
                        key={day.id}
                        label={day.title}
                        selected={selectedBlock === day.block && selectedDay === day.position}
                        position={i}
                        onClick={() => onSelect && onSelect(day.position, day.block, i)}
                        onAdd={onAdd}
                        onDelete={() => onDelete && onDelete(day.id)}
                        onDisable={() => handleDisable()}
                        lastInBlock={i + 1 === days.length || days[i + 1].block !== day.block}
                        readOnly={readOnly}
                        country={day.country}
                        location={day.location}
                    />
                ))}
            </ReactSortable>
            {!days.length && !readOnly && (
                <div className="flex flex-col items-center justify-center text-center w-full">
                    <button
                        type="button"
                        onClick={onAdd}
                        className="focus:outline-none border-yellow border-2 rounded-full h-12 w-12 items-center justify-center text-center text-grey"
                    >
                        +
                    </button>
                    <p className="text-grey-dark-3 mt-2">Añadir</p>
                </div>
            )}
        </div>
    );
};

export default Itinerary;
