import React, { PropsWithChildren } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactSortable } from "react-sortablejs";
import ListHeader from "./ListHeader";
import Row from "./Row";

export interface ListProps<Type> {
    /**
     * List of objects to show in the table.
     */
    objects: Type[];
    /**
     * List of fields from each object that will be shown in the table.
     * Each field is represented by a key, which is usually the name of the field,
     * and a title, which is what will be shown in the header of the list for that
     * field and is usually translatable.
     *
     * For example, given an object definition such as
     * ```
     * type Object { id: number; name: string; age: number; }
     * ```
     * if we wanted a table that shows the name and age, we could pass as properties
     * ```
     * [
     *   { key: "name", title: "Name" },
     *   { key: "age", title: "Age" }
     * ]
     * ```
     * The order in which the fields will appear in the list is the order in which
     * they are defined in the fields prop.
     */
    fields: {
        key: keyof Type;
        title: string;
    }[];

    /**
     * Whether multiple selection is enabled for this list.
     * If enabled, each row will have a circle on the left that when clicked will select the row, allowing
     * to select multiple rows and then performing some action on all the selected rows.
     */
    multiselection?: boolean;

    /**
     * The IDs corresponding to the rows (objects) in the list that are selected.
     */
    selectedRows?: Set<number>;

    /**
     * Handler for what happens when a row is clicked (the row itself, not the select button).
     */
    onRowClick?: (id: number) => void;

    /**
     * Used to notify the parent of the selected rows.
     */
    onSelect?: (ids: Set<number>) => void;

    /**
     * If this callback is present, the list will use an infinite scroll, calling this function when reaching the bottom
     * of the scrolling area. Usually used to fetch more data to keep the infinite scroll going.
     */
    onScrollToEnd?: () => void;

    /**
     * Used in conjunction with onScrollToEnd. to determine whether there is more data to be fetched. If true, then
     * onScrollToEnd will be called, otherwise the list has reached its end and no more records will be loaded.
     */
    hasMore?: boolean;

    /**
     * ID of the element which is scrollable and in which the infinite scroll list lives. Usually the parent div.
     */
    scrollableTarget?: string;

    /**
     * If present, the list will be sortable, allowing the user to drag rows and drop them in a different order.
     * Upon dropping the row, this callback will be called with the new order of the list as its parameter, so it
     * could be used to set the state that handles the row objects in the parent.
     */
    onSort?: (obj: Type[]) => void;

    /**
     * Nodes to list under the row.
     * If there are items, the row will be made expandable by an arrow button placed on the left. It is collapsed by
     * default, and upon expanding, the items will be shown.
     */
    items?: React.ReactNode[][];

    /**
     * Sets the number and of columns of the table getting parameters dro tailwind.config.js file
     */
    listType?:string
}

/**
 * A List is used to visualize data from any model or object in a list view, selecting the fields we want.
 *
 * It can be made into an infinite scroll list by using the props onScrollEnd, hasMore and scrollableTarget.
 */
function List<Type extends { id: number }>({
    objects,
    fields,
    onRowClick,
    onSelect,
    onScrollToEnd,
    hasMore,
    listType,
    scrollableTarget,
    onSort,
    items,
    selectedRows = new Set(),
    multiselection = false,
}: PropsWithChildren<ListProps<Type>>): React.ReactElement {
    const allSelected = objects.length > 0 && selectedRows.size === objects.length;

    const handleRowSelect = (id: number) => {
        let newSelected;
        if (selectedRows.has(id)) {
            const arr = Array.from(selectedRows);
            newSelected = new Set(arr.filter((n) => n !== id));
        } else {
            newSelected = new Set(selectedRows);
            newSelected.add(id);
        }
        // Notify parent component of the selected ids
        onSelect && onSelect(newSelected);
    };

    const handleSelectAll = () => {
        if (!objects) return;
        let newSelected = new Set<number>();
        // If not all rows are selected, select all rows. Otherwise, unselect all rows.
        if (objects.length !== selectedRows.size) {
            newSelected = new Set(Array.from(objects, (obj) => obj.id));
        }
        onSelect && onSelect(newSelected);
    };

    let rows = objects && (
        <>
            {objects.map((object, i) => (
                <Row<Type>
                    id={i}
                    key={object.id}
                    listType={listType}
                    object={object}
                    fields={fields}
                    selected={selectedRows.has(object.id)}
                    hasSelectButton={multiselection}
                    onClick={
                        onRowClick &&
                        (() => {
                            onRowClick(object.id);
                        })
                    }
                    cursorGrab={!!onSort}
                    onSelect={() => handleRowSelect(object.id)}
                    items={items && i < items.length ? items[i] : undefined}
                />
            ))}
        </>
    );

    if (onScrollToEnd) {
        rows = (
            <InfiniteScroll
                dataLength={objects.length}
                next={onScrollToEnd}
                hasMore={hasMore || false}
                loader={<p>Cargando...</p>}
                scrollableTarget={scrollableTarget}
            >
                {rows}
            </InfiniteScroll>
        );
    }

    if (onSort) {
        rows = (
            <ReactSortable list={objects} setList={onSort}>
                {rows}
            </ReactSortable>
        );
    }

    return (
        <div className="w-full">
            <ListHeader
                fields={fields}
                listType={listType}
                hasSelectButton={multiselection}
                someSelected={selectedRows.size > 0}
                allSelected={allSelected}
                onSelect={handleSelectAll}
                hasDropdownButton={!!items}
            />
            <div className="w-full flex-col">{rows}</div>
        </div>
    );
}

export default List;
