import classNames from "classnames";
import React from "react";

export interface ImageSquareProps {
    children: React.ReactNode;
    onClick: () => void;
    onKeyPress: () => void;
    selected?: boolean;

    /**
     * Shown at the bottom of the image on a semi transparent background on hover.
     */
    description?: string;
    /**
     * Optional link in the image description, used for Unsplash images.
     */
    linkTo?: string;
}

const ImageSquare: React.FC<ImageSquareProps> = ({ children, onClick, onKeyPress, selected, description, linkTo }) => {
    let descriptionBlock = <p className="text-white p-2">{description}</p>;
    if (linkTo !== undefined) {
        descriptionBlock = (
            <a href={linkTo} target="_blank" rel="noreferrer">
                {descriptionBlock}
            </a>
        );
    }

    return (
        <div
            className={classNames(
                "aspect-square w-full flex items-center justify-center rounded-xl relative group",
                selected && "border border-4 border-yellow"
            )}
            onClick={onClick}
            onKeyPress={onKeyPress}
            role="button"
            tabIndex={0}
        >
            {children}
            {description && (
                <div
                    className={classNames(
                        "items-center justify-center bg-black bg-opacity-50 absolute",
                        "bottom-0 h-14 w-full rounded-b-2xl",
                        "hidden group-hover:flex"
                    )}
                >
                    {descriptionBlock}
                </div>
            )}
        </div>
    );
};

export default ImageSquare;
