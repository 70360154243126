import React from "react";
import { Choice, ContactType, DayBlockData, ModelRef, ReservationDetailData, TemplateDetailData } from "./types";
import Avatar from "./components/Avatar";

export const INVALID_MODELREF = { id: 0, name: "" } as ModelRef;

/**
 * Given a name composed of two or more words, return the first letters of the first
 * and second words, capitalized. For example, if fullName is "One More Destination", it returns
 * "OM". If the full name is only one word, it returns just its first letter.
 */
export const getInitials = (fullName: string): string => {
    if (fullName === undefined) return "";

    const names = fullName.split(" ");
    if (!names || !names[0]) return "";
    if (names.length === 1) {
        return names[0][0].toUpperCase();
    }
    let initials = names[0][0].toUpperCase();
    if (names[1]) initials += names[1][0].toUpperCase();
    return initials;
};

/**
 * Given a choice keyword and a choice list, return the full choice, including the display name.
 */
export const getChoice = (keyword: string, choices: Choice[]): Choice | undefined =>
    choices.find((choice) => choice.value === keyword);

/**
 * Given a name and optionally a surname, returns the full name.
 */
export const getFullName = (name: string, surname: string | undefined): string =>
    surname ? `${name} ${surname}` : name;

/**
 * Formats the amount of bytes as a string in the appropriate multiplier.
 * An amount of decimals can optionally be defined.
 *
 * Examples:
 *  formatBytes(1000) == "1000 Bytes"
 *  formatBytes(10000) == "9.77 KB"
 *  formatBytes(10411783781, 3) == "9.697 GB"
 */
export const formatBytes = (bytes: number, decimals = 2): string => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Extracts the time from a Date object and returns it in either HH:MM (if seconds == false)
 * or HH:MM:SS (if seconds == true) format.
 */
export const formatTime = (date: Date, seconds = false): string =>
    `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}${
        seconds ? `:${date.getSeconds().toString().padStart(2, "0")}` : ""
    }`;

/**
 * Formats the datetime as a human readable string and using local time,
 * with the full name for the month from the locale.
 * If time == false, then only the date is formatted.
 * If day == false, then the day is omitted.
 *
 * Examples:
 *  formatDate("2021-11-07T21:42:21.584912Z") == "7 November 2021 - 22:42:21" in an UTC+1 locale
 *  formatDate("2021-11-07T21:42:21.584912Z", false, false) == "November 2021"
 */
export const formatDate = (date: Date | undefined, time = true, day = true): string => {
    if (!date) {
        return "";
    }
    return `${day ? `${date.getDate()} ` : ""}${date.toLocaleDateString("default", {
        month: "long",
    })} ${date?.getFullYear().toString()}${time ? ` - ${formatTime(date, true)}` : ""}`;
};

/**
 * Returns a date (without time) as a string in ISO format, i.e., YYYY-MM-DD
 */
export const formatDateISO = (date: Date): string => {
    // Date objects always include time, even if we are treating it as just a date
    // If we only work with dates, and have a Date such as "Sat Nov 13 2021 00:00:00 GMT+0100",
    // then the UTC date is 12 (because of the timezone).
    // To avoid depending on the timezone when we are only working with dates, we translate the date by an offset
    // equal to the current timezone, so in the previous example we would have "Sat Nov 13 2021 01:00:00 GMT+0100"
    // which correctly has UTC date 13.
    date.setMinutes(-date.getTimezoneOffset());
    return `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`;
};

/**
 * Get current date and allow output in two formats
 */
export const getCurrentDate = (
    ...args: [string | null | undefined, string | null | undefined, string | null | undefined]
) => {
    const [inputDate, isDayFirst, isSlashedMode] = args;
    const today = inputDate ? new Date(inputDate) : new Date();
    const date = `0${today.getDate()}`;
    const month = `0${today.getMonth() + 1}`;
    const year = today.getFullYear();

    if (isSlashedMode === "slashed" && isDayFirst !== "dayFirst") {
        return `${date.slice(-2)}/${month.slice(-2)}/${year}`;
    }
    if (isSlashedMode !== "slashed" && isDayFirst !== "dayFirst") {
        return `${year}-${month.slice(-2)}-${date.slice(-2)}`;
    }
    if (isSlashedMode === "slashed" && isDayFirst === "dayFirst") {
        return `${date.slice(-2)}/${month.slice(-2)}/${year}`;
    }

    return `${date.slice(-2)}-${month.slice(-2)}-${year}`;
};
/**
 * Given a time in format HH:MM:SS or HH:MM formats, return a Date object with the current date and the given time.
 * This is useful e.g. in the DatetimePicker component, which uses Date objects, but can also work in time mode.
 * However, JS doesn't have a Time object, only Date which is actually a datetime.
 */
export const getDateFromTime = (time: string | undefined): Date | undefined => {
    if (!time) return undefined;

    const matches = time.match(/(\d+):(\d+):?(\d+)?/);
    const date = new Date();
    if (matches && matches.length >= 3) {
        date.setHours(parseInt(matches[1], 10)); // matches[0] is the full string
        date.setMinutes(parseInt(matches[2], 10));
        date.setSeconds(matches.length === 4 ? parseInt(matches[3], 10) : 0);
    }
    return date;
};

/**
 * Function that adds Avatar image to name/title column for List and table applications
 */
export const getAvatarWithName = (
    fullName: string | undefined,
    initials: string | null = null,
    imageUrl: string | null = null
) => (
    <div className="flex items-center space-x-2">
        <Avatar image={imageUrl || ""} altText={initials || getInitials(fullName || "")} size="sm" />
        <p className="truncate min-w-[0] max-w-[100%]">{fullName}</p>
    </div>
);

export const getItineraryDays = (itinerary: DayBlockData[]): number =>
    itinerary.reduce((acc, block) => acc + block.days.length, 0);

export const getItineraryTitles = (itinerary: DayBlockData[], version: number): string[] => {
    const titles: string[] = [];
    const itinerarySorted = itinerary.filter((b) => b.version === version);
    itinerarySorted.sort((a, b) => a.position - b.position);
    for (let i = 0; i < itinerarySorted.length; i += 1) {
        const daysSorted = itinerarySorted[i].days.slice();
        daysSorted.sort((a, b) => a.position - b.position).map((day) => titles.push(day.title));
    }
    return titles;
};

/**
 * Given an array of objects that have an ID, return the first available "invalid" ID, that is,
 * an ID like 0, -1, -2...
 * Invalid IDs are used for new elements which haven't been saved to the database yet.
 * If an array has IDs [2, 5, 0, -1] then the first available invalid ID is -2, which is what this
 * method will return.
 */
export const getLowestInvalidId = <T extends { id: number }>(objects: T[]): number => {
    if (objects.length === 0) return 0;
    const lowest = objects.reduce((prev, curr) => (prev.id < curr.id ? prev : curr)).id;
    return lowest <= 0 ? lowest - 1 : 0;
};

/**
 * Itinerary PDFs are downloaded with the name: ID - first client name - title - days
 */
export const getItineraryPdfName = (data: ReservationDetailData | TemplateDetailData): string => {
    let name = data.id.toString();
    const reservationData = data as ReservationDetailData;
    if (reservationData && reservationData.clients && reservationData.clients.length > 0) {
        name += ` - ${reservationData.clients[0].full_name}`;
    }
    if (data.title) {
        name += ` - ${data.title}`;
    }
    name += ` - ${data.itinerary_days} días`; // TODO: i10n
    return name;
};

export const getContactTypeLabel = (contactType: ContactType) => {
    // TODO: Translate names
    switch (contactType) {
        case "retail_agency":
            return "Agencia minorista";
        case "wholesale_agency":
            return "Agencia mayorista";
        case "provider":
            return "Proveedor";
        case "traveler":
        default:
            return "Particular";
    }
};

export const truncate = (str: string, n: number) => (str.length > n ? `${str.slice(0, n - 1)}…` : str);
