import React from "react";
import MuiAlert from "@mui/material/Alert";
import useAlert from "../useAlert";

const Alert: React.FC = () => {
    const { alert } = useAlert();
    if (!alert) {
        return null;
    }
    const { message, type } = alert;

    return (
        <div className="fixed flex w-full justify-end bottom-10 right-10 pointer-events-none">
            <MuiAlert
                severity={type}
            >
                <span className="font-bold">{message}</span>
            </MuiAlert>
        </div>
    );
};

export default Alert;
