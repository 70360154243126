/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import download from "downloadjs";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios, { AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { dataPending, saveData } from "../redux/reservations";
import Container from "../components/Container";
import Button from "../components/Button";
import ScreenTemplate from "../components/ScreenTemplate";
import List from "../components/List/List";
import Rating, { RatingProps } from "../components/Rating";
import SearchBox from "../components/SearchBox";
import axiosApi from "../axiosApi";
import {
    ContactType,
    contactTypes,
    RecursivePartial,
    ReservationState,
    ModelRef,
    EmployeeData,
    ContactData,
    AttachmentData,
    AttachmentRow,
    OfficeData,
    ClientTemplate,
    ContactFullData,
} from "../types";
import ClientPreview from "../components/ClientPreview";
import { formatBytes, formatDate, getContactTypeLabel, getInitials, getAvatarWithName } from "../utils";
import Tag from "../components/Tag";
import TextInput from "../components/TextInput";
import FileUploadZone from "../components/FileUploadZone";
import Tab from "../components/Tab/Tab";
import TagGroup from "../components/TagGroup";
import TabController from "../components/Tab/TabController";
import DropdownItem from "../components/Dropdown/DropdownItem";
import Dropdown from "../components/Dropdown/Dropdown";
import Spinner from "../components/Spinner";
import useAlert from "../useAlert";
import Many2one from "../components/Many2one";
import useModelRef from "../useModelRef";
import IconButton from "../components/IconButton";
import CollapsableSection from "../components/CollapsableSection";
import LoadingModal from "../components/modals/LoadingModal";
import StateBadge from "../components/StateBadge";
import { openModel, closeModel, showName } from "../redux/header";

// TODO: Make this customizable in the webpage
const PAGE_SIZE = 10;

interface ClientData {
    id: number;
    contact: ContactData;
    observations?: string;
    num_reservations: number;
    num_confirmed_reservations: number;
    latest_reservation_date?: Date;
    attachments: Array<AttachmentData>;
}

interface ClientRow {
    id: number;
    name: React.ReactNode;
    moken: string;
    type: string;
    email: string;
    rating: React.ReactElement<RatingProps>;
}

interface DiscoverRow {
    id: number;
    name: React.ReactNode;
    rating: React.ReactElement<RatingProps>;
    actions: React.ReactNode;
}

interface ClientReservationData {
    id: number;
    travel_start_date?: Date;
    reference?: string;
    state: ReservationState;
    destinations: Array<string>;
}

interface ClientReservationRow {
    id: number;
    travel_start_date: string;
    reference: string;
    destinations: React.ReactNode;
    state: React.ReactNode;
}

interface ClientTemplateRow extends ClientTemplate {
    actions: React.ReactNode;
}

const clientFields: {
    key: keyof ClientRow;
    title: string;
}[] = [
    {
        key: "name",
        title: "Nombre",
    },
    {
        key: "moken",
        title: "Moken",
    },
    {
        key: "type",
        title: "Tipo",
    },
    {
        key: "email",
        title: "Email",
    },
];

const discoverFields: {
    key: keyof DiscoverRow;
    title: string;
}[] = [
    {
        key: "name",
        title: "Nombre",
    },
    {
        key: "rating",
        title: "Valoración",
    },
    {
        key: "actions",
        title: "Acciones",
    },
];

const employeeFields: {
    key: keyof EmployeeData;
    title: string;
}[] = [
    {
        key: "name",
        title: "Nombre",
    },
    {
        key: "role",
        title: "Posición",
    },
    {
        key: "email",
        title: "Email",
    },
    {
        key: "phone",
        title: "Teléfono",
    },
];

const reservationFields: {
    key: keyof ClientReservationData;
    title: string;
}[] = [
    {
        key: "travel_start_date",
        title: "Fechas",
    },
    {
        key: "reference",
        title: "Referencia",
    },
    {
        key: "destinations",
        title: "Destino",
    },
    {
        key: "state",
        title: "Estado",
    },
];

const attachmentFields: {
    key: keyof AttachmentRow;
    title: string;
}[] = [
    {
        key: "name",
        title: "Nombre",
    },
    {
        key: "size",
        title: "Tamaño",
    },
    {
        key: "timestamp",
        title: "Fecha de subida",
    },
    {
        key: "actions",
        title: "Acciones",
    },
];

const clientTemplateFields: {
    key: keyof ClientTemplateRow;
    title: string;
}[] = [
    {
        key: "title",
        title: "Título",
    },
    {
        key: "itinerary_days",
        title: "Días",
    },
    {
        key: "countries",
        title: "Destino",
    },
    {
        key: "travel_type",
        title: "Tipo de viaje",
    },
    {
        key: "actions",
        title: "Acciones",
    },
];

interface ClientsScreenProps {
    /**
     * Whether the screen is for providers, or otherwise for all other types of clients (travelers, agencies).
     */
    provider?: boolean;
}

interface SettingProps {
    title: string;
    description?: string;
    setting: React.ReactNode;
}
const Setting: React.FC<SettingProps> = ({ title, description, setting }) => (
    <div className="flex flex-col w-full space-x-5 mt-14">
        <div className="">
            <p className="font-bold">{title}</p>
            {description && <p className="text-grey text-sm mt-2">{description}</p>}
            <div className="mt-6">{setting}</div>
        </div>
    </div>
);

const ClientsScreen: React.FC<ClientsScreenProps> = ({ provider = false }) => {
    const [companyData, setCompanyData] = useState<ContactFullData | null>(null);
    const [clientData, setClientData] = useState<Array<ClientData> | null>(null);
    const [previewDiscovery, setPreviewDiscovery] = useState<ContactData | null>(null);
    const [discoverContacts, setDiscoverContacts] = useState<Array<ContactData>>([]);
    const [openClient, setOpenClient] = useState<ClientData | null>(null);
    const [clientReservations, setClientReservations] = useState<Array<ClientReservationData> | null>(null);
    const [isCreatingClient, setIsCreatingClient] = useState(false);
    const [modifiedData, setModifiedData] = useState<RecursivePartial<ClientData>>({});
    const [currentPage, setCurrentPage] = useState(1); // For pagination, starts at 1
    const [currentPageDiscover, setCurrentPageDiscover] = useState(1); // For pagination, starts at 1
    const [totalRecords, setTotalRecords] = useState(1);
    const [totalRecordsDiscover, setTotalRecordsDiscover] = useState(1);
    const [selectedRows, setSelectedRows] = useState(new Set<number>());
    const [addClientDropdownVisible, setAddClientDropdownVisible] = useState(false);
    // Maps a field name to the error message for that field
    const [fieldErrors, setFieldErrors] = useState(new Map<string, string>());
    const [searchQuery, setSearchQuery] = useState<string | null>(null);
    const [countries, setCountries] = useState<Array<ModelRef>>([]);
    const [interests, setInterests] = useState<Array<ModelRef>>([]);
    const [filesToUpload, setFilesToUpload] = useState<Array<File>>([]);
    const [optionsDropdownVisible, setOptionsDropdownVisible] = useState(false);

    // Client templates
    const [clientTemplates, setClientTemplates] = useState<Array<ClientTemplate> | null>(null);
    const [currentPageTemplates, setCurrentPageTemplates] = useState(1); // For pagination, starts at 1
    const [totalRecordsTemplates, setTotalRecordsTemplates] = useState(1);
    const [isDownloadingPdfModalVisible, setIsDownloadingPdfModalVisible] = useState(false);

    const [isSidePanelOpened, setSidePanelIsopen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { addAlert } = useAlert();
    const { getModelRef } = useModelRef();
    const { getChoiceRef } = useModelRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const apiEndpoint = provider ? "providers" : "clients";
    let filteredcontactTypes = contactTypes;
    let scrolled = false;
    let scrolledTemplates = false;
    let scrolledDiscovery = false;
    let pageHasChanged = false;
    let isSearchActive = false;
    let isSearchTemplatesActive = false;
    let searchString = "";
    let textInputTimer: ReturnType<typeof setTimeout>;
    const states = getChoiceRef("reservationState");

    const isClientTraveler = openClient && openClient.contact.contact_type === "traveler";
    let isClientUser = false;
    if (openClient) {
        isClientUser = openClient.contact.is_user;
    }

    // TO DO: Find better way to do this
    /* const linkQuotations = document.querySelector(".clients");
    const linkReservations = document.querySelector(".providers"); */
    /* linkQuotations?.addEventListener("click", () => {
        setOpenClient(null);
    });
    linkReservations?.addEventListener("click", () => {
        setOpenClient(null);
    }); */

    const showSidePanel = () => {
        isSidePanelOpened === true ? setSidePanelIsopen(false) : setSidePanelIsopen(true);
    };

    const handleConnect = (id: number) => {
        axiosApi
            .get(`/contacts/${id}/connect/`)
            .then(() => addAlert("La solicitud de conexión se ha enviado con éxito.", "success"));
    };

    const buidlClientRow = (object: ClientData): ClientRow => {
        const typeString = getContactTypeLabel(object.contact.contact_type);
        return {
            id: object.id,
            name: getAvatarWithName(
                object.contact.full_name,
                object.contact.initials,
                object.contact.profile_image?.image
            ),
            moken: object.contact.is_user ? "Sí" : "No",
            type: typeString,
            email: object.contact.email,
            rating: <Rating rating={object.contact.average_rating} numRatings={object.contact.num_ratings} />,
        };
    };

    const buildDiscoverRow = (object: ContactData): DiscoverRow => {
        const MuiMenuItem = React.forwardRef(() => (
            <MenuItem
                classes="flex justify-between"
                onClick={() => previewDiscovery && handleConnect(previewDiscovery.id)}
            >
                <span className="pointer-events-none">
                    <IconButton icon="link" extraClass="text-[22px]" />
                </span>
                <span className="px-2">Conectar</span>
            </MenuItem>
        ));
        MuiMenuItem.displayName = "MuiMenuItem";

        const actions = (
            <div>
                <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                    <IconButton icon="more_vert" onClick={handleClick} extraClass="text-[20px]" />
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: "visible",
                            border: "1px solid #DDD",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            "&:before": {
                                content: "''",
                                display: "block",
                                position: "absolute",
                                borderLeft: "1px solid #DDD",
                                borderTop: "1px solid #DDD",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                    <MuiMenuItem />
                </Menu>
            </div>
        );
        return {
            id: object.id,
            name: getAvatarWithName(object.full_name, object.initials, object.profile_image?.image),
            rating: <Rating rating={object.average_rating} numRatings={object.num_ratings} />,
            actions,
        };
    };

    const buildClientReservationRow = (reservation: ClientReservationData): ClientReservationRow => {
        const destinations = (
            <div className="flex space-x-1 items-center justify-center">
                {countries.length === 0 && <p className=" text-grey-light-2">-</p>}
                {reservation.destinations.length > 0 && <Tag label={reservation.destinations[0]} type="yellow" />}
                {/* {reservation.destinations.length > 1 && <Tag label={reservation.destinations[1]} type="yellow" />} */}
                {reservation.destinations.length > 1 && <span>+1</span>}
            </div>
        );
        return {
            id: reservation.id,
            travel_start_date: formatDate(reservation.travel_start_date, false, false),
            reference: reservation.reference || "",
            destinations,
            state: <StateBadge state={reservation.state} states={states} />,
        };
    };

    const buildClientTemplateRow = (template: ClientTemplate): ClientTemplateRow => {
        const actions = (
            <div>
                <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                    <IconButton icon="more_vert" onClick={handleClick} extraClass="text-[20px]" />
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: "visible",
                            border: "1px solid #DDD",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            "&:before": {
                                content: "''",
                                display: "block",
                                position: "absolute",
                                borderLeft: "1px solid #DDD",
                                borderTop: "1px solid #DDD",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                    <MenuItem
                        classes="flex justify-between"
                        onClick={() => {
                            setIsDownloadingPdfModalVisible(true);
                            axiosApi
                                .get(`/templates/${template.id}/print/`, {
                                    responseType: "blob",
                                })
                                .then((response) => {
                                    download(response.data, template.title, response.headers["content-type"]);
                                    setIsDownloadingPdfModalVisible(false);
                                })
                                .catch(() => setIsDownloadingPdfModalVisible(false));
                        }}
                    >
                        <span>
                            <IconButton icon="download" extraClass="text-[22px]" />
                        </span>
                        <span className="px-2 text-sm">Download</span>
                    </MenuItem>
                </Menu>
            </div>
        );
        return {
            ...template,
            actions,
        };
    };

    const createNewClient = (contact_type: ContactType) => {
        setIsCreatingClient(true);
        setOpenClient({
            contact: {
                full_name: `Nuevo cliente. ${getContactTypeLabel(contact_type)}`, // To show in the title
                contact_type,
                interests: new Array<ModelRef>(),
                offices: new Array<OfficeData>(),
                specialties: new Array<ModelRef>(),
                languages: new Array<ModelRef>(),
                destinations: new Array<ModelRef>(),
            } as ContactData,
            attachments: new Array<AttachmentData>(),
        } as ClientData);
        setModifiedData({
            contact: {
                contact_type,
                interests: new Array<ModelRef>(),
            },
        });
    };

    const fetchClients = async () => {
        await axiosApi
            .get(
                `/${apiEndpoint}/?limit=${PAGE_SIZE}&offset=${
                    (scrolled && !isSearchActive ? currentPage : currentPage - 1) * PAGE_SIZE
                }${searchString ? `&query=${searchString}` : ""}
                `
            )
            .then((response) => {
                setTotalRecords(response.data.count);
                setClientData(
                    (!clientData || isSearchActive || pageHasChanged ? [] : clientData).concat(
                        response.data.results.map(
                            (obj: ClientData): ClientData => ({
                                ...obj,
                                // Comes as string from the API, must convert to date object
                                latest_reservation_date:
                                    obj.latest_reservation_date && new Date(obj.latest_reservation_date),
                                contact: { ...obj.contact, initials: getInitials(obj.contact.full_name) },
                            })
                        )
                    )
                );
                scrolled && !isSearchActive ? setCurrentPage(currentPage + 1) : setCurrentPage(1);
            })
            .catch(() => {
                // TODO: Treat error
            });
    };

    const fetchCompanyData = () => {
        axiosApi.get("/contacts/company/").then((response) => {
            const data = response.data as ContactFullData;
            setCompanyData(data);
        });
    };

    const fetchDiscoverContacts = async () => {
        await axiosApi
            .get(
                `/contacts/discover/?type=${provider ? "provider" : "agency"}&limit=${PAGE_SIZE}&offset=${
                    (currentPage - 1) * PAGE_SIZE
                }${searchString ? `&query=${searchString}` : ""}
                `
            )
            .then((response) => {
                setTotalRecordsDiscover(response.data.count);
                setDiscoverContacts(
                    (scrolled || scrolledDiscovery || isSearchActive || pageHasChanged ? [] : discoverContacts).concat(
                        // FIXME: Fix when pagination works
                        response.data.map(
                            (cont: ContactData): ContactData => ({
                                ...cont,
                                initials: getInitials(cont.full_name),
                            })
                        )
                    )
                );
                setCurrentPageDiscover(currentPageDiscover + 1);
            })
            .catch(() => {
                // TODO: Treat error
            });
    };

    const fetchClientReservations = useCallback(() => {
        if (!openClient || isCreatingClient || provider) return;

        axiosApi
            .get(`/clients/${openClient.id}/reservations/`)
            .then((response) => {
                setClientReservations(
                    response.data.map(
                        (reservation: ClientReservationData): ClientReservationData => ({
                            ...reservation,
                            // Start date comes encoded as a string from the API, need to convert it to a Date object
                            travel_start_date: reservation.travel_start_date && new Date(reservation.travel_start_date),
                        })
                    )
                );
            })
            .catch(() => {
                // TODO: Treat error
            });
    }, [openClient, isCreatingClient, provider]);

    const fetchClientTemplates = async (searchQueryString: string) => {
        if (!openClient || isCreatingClient) return;

        // Get the templates from the provider
        await axiosApi
            .get(
                `/client_templates/?limit=${PAGE_SIZE}&offset=${(currentPageTemplates - 1) * PAGE_SIZE}${
                    searchQueryString ? `&query=${searchQueryString}` : ""
                }`
            )
            .then((response) => {
                setTotalRecordsTemplates(response.data.count);
                setClientTemplates(
                    (isSearchTemplatesActive || !clientTemplates ? [] : clientTemplates).concat(response.data.results)
                );
                scrolledTemplates && !isSearchTemplatesActive
                    ? setCurrentPageTemplates(currentPageTemplates + 1)
                    : setCurrentPageTemplates(1);
            });
    };

    const openClientReservation = (id: number) => {
        if (!openClient || !clientReservations) return;

        const reservation = clientReservations.find((res) => res.id === id);
        if (!reservation) return;

        const type =
            reservation.state === "confirmed" || reservation.state === "completed" ? "reservations" : "quotations";
        // Retrieve the full reservation and open it
        axiosApi
            .get(`/reservations/${id}/`)
            .then((response) => {
                navigate(`/${type}/`, { state: { reservation: response.data, keepOpen: true } });
            })
            .catch(() => addAlert("Error al abrir el viaje del cliente.", "error"));
    };

    const createNewReservation = () => {
        if (!openClient || isCreatingClient) return;

        // Navigate to the quotations screen with this client's info. Upon receiving this info, the
        // quotations screen will handle the creation itself.
        navigate("/quotations/", {
            state: { keepOpen: true, client: { id: openClient.id, name: openClient.contact.full_name } },
        });
    };

    const handleErrorResponse = useCallback(
        (err: Error | AxiosError) => {
            if (axios.isAxiosError(err) && err.response) {
                const newFieldsWithError = new Map<string, string>();
                const { data } = err.response;
                switch (err.response.status) {
                    case 400:
                        addAlert("Some required fields are missing or some fields contain incorrect data.", "error");
                        if ("contact" in data) {
                            // If no contact data was provided, the API will just complain about contact being required,
                            // without specifying the actual fields that are required. In this case, just show the notification.
                            if (!Array.isArray(data.contact)) {
                                const wrongFields = Object.entries(data.contact);
                                for (let i = 0; i < wrongFields.length; i += 1) {
                                    const [field, message] = wrongFields[i];
                                    if (Array.isArray(message) && typeof message[0] === "string") {
                                        newFieldsWithError.set(field, message[0]);
                                    }
                                }
                            }
                        }
                        setFieldErrors(newFieldsWithError);
                        break;
                    default:
                        addAlert("An unexpected error occured.", "error");
                        break;
                }
            }
        },
        [setFieldErrors, addAlert]
    );

    const addFilesToUpload = (newFiles: Array<File>) => {
        if (!openClient || !newFiles) return;

        let existsDuplicate = false;
        const filesToUploadAux = filesToUpload.slice(); // Temporary copy to modify before calling setState
        for (let i = 0; i < newFiles.length; i += 1) {
            // Check for name duplicates.
            // If a duplicate is found, show a warning and don't add the file.
            // An alternative we can implement in the future is renaming the file with the duplicate name (e.g. "name (1)"),
            // overwriting the old file or just allowing duplicate names, since the actual names stored in the database are not the
            // original file names
            const newFile = newFiles[i];
            if (
                openClient.attachments.find((attachment) => attachment.name === newFile.name) ||
                filesToUpload.find((file) => file.name === newFile.name)
            ) {
                existsDuplicate = true;
            } else {
                filesToUploadAux.push(newFile);
                dispatch(dataPending());
            }
        }
        setFilesToUpload(filesToUploadAux);
        // Show warning if there were duplicates
        if (existsDuplicate) {
            addAlert("Adding files with duplicate file names is not allowed.", "warning");
        }
    };

    const buildAttachmentRow = (attachment: AttachmentData | File, uploaded = true, id = 0): AttachmentRow => {
        const attachmentData = attachment as AttachmentData;

        const actions = uploaded ? (
            <div className="flex space-x-4">
                <IconButton
                    icon="download"
                    onClick={() => {
                        if (!attachmentData?.fs_file) return;

                        // We can't just use a link with a download attribute to modify the filename because of the
                        // same-origin policy (we are downloading a file from the server), so we have to do some
                        // JS bullshit to retrieve it with the custom filename.
                        // See https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
                        axios({
                            url: (attachment as AttachmentData).fs_file,
                            method: "GET",
                            responseType: "blob",
                        }).then((response) => {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", attachment.name);
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode?.removeChild(link);
                        });
                    }}
                />
                <IconButton
                    icon="delete"
                    iconSize="27px"
                    onClick={() => {
                        // Delete file (and associated attachment record) from the server
                        // TODO: Add verification pop-up, or show as slashed-out and remove when the client is saved,
                        // in a similar way to how upload is done
                        if (id <= 0) return;

                        axiosApi.delete(`/attachments/${id}/`).then(() => {
                            if (openClient) {
                                setOpenClient({
                                    ...openClient,
                                    attachments: openClient.attachments.filter(
                                        (clientAttachment) => clientAttachment !== attachment
                                    ),
                                });
                            }
                        });
                    }}
                />
            </div>
        ) : (
            <div className="flex">
                <IconButton
                    icon="close"
                    onClick={() => {
                        // Remove file from the to-upload list
                        setFilesToUpload(filesToUpload.filter((file) => file !== attachment));
                    }}
                />
            </div>
        );

        if (attachmentData !== undefined && uploaded) {
            return {
                ...attachmentData,
                size: formatBytes(attachmentData.size),
                timestamp: formatDate(new Date(attachmentData.timestamp), true, true),
                actions,
            };
        }
        // File type, meaning it has not been uploaded yet
        return {
            name: attachment.name,
            size: formatBytes(attachment.size),
            timestamp: "",
            id,
            actions,
        };
    };

    const handleSave = async () => {
        if (!openClient) return;

        let success = false;

        if (modifiedData) {
            if (isCreatingClient) {
                // Create new client
                await axiosApi
                    .post(`/${apiEndpoint}/`, modifiedData)
                    .then(() => {
                        success = true;
                        dispatch(saveData());
                    })
                    .catch((err) => handleErrorResponse(err));
            } else {
                // Update existing client
                await axiosApi
                    .patch(`/${apiEndpoint}/${openClient.id}/`, modifiedData)
                    .then(() => {
                        success = true;
                        dispatch(saveData());
                    })
                    .catch((err) => handleErrorResponse(err));
            }
        }

        // Upload attachments
        if (filesToUpload) {
            for (let i = 0; i < filesToUpload.length; i += 1) {
                const formData = new FormData();
                formData.append("name", filesToUpload[i].name);
                formData.append("fs_file", filesToUpload[i], filesToUpload[i].name);
                // client ID will be added on the server side after proper authentication validation
                // eslint-disable-next-line no-await-in-loop
                await axiosApi
                    .post(`/${apiEndpoint}/${openClient.id}/upload/`, formData, {
                        headers: {
                            "content-type": "multipart/form-data",
                        },
                    })
                    .catch(() => addAlert("There was an error uploading one or more files to the server", "error"));
            }

            setFilesToUpload([]);
        }

        if (success) {
            setCurrentPage(1);
            addAlert("Cliente guardado con éxito.", "success");
        }
    };

    const handleDelete = () => {
        const selectedId = openClient?.id;
        if (!selectedId) return;

        if (isClientUser) {
            // Disconnect, instead of deleting the record
            const contactId = openClient?.contact.id;
            axiosApi
                .get(`/contacts/${contactId}/disconnect/`)
                .then(() => {
                    // Trigger refresh so that the created/updated client appears in the list view
                    setCurrentPage(1);
                    setCurrentPageDiscover(1);
                    setClientData(null);
                    setOpenClient(null);
                })
                .catch((err) => handleErrorResponse(err));
        } else {
            axiosApi
                .delete(`/${apiEndpoint}/${selectedId}/`)
                .then(() => {
                    // Trigger refresh so that the created/updated client appears in the list view
                    setCurrentPage(1);
                    setCurrentPageDiscover(1);
                    setClientData(null);
                    setOpenClient(null);
                })
                .catch((err) => handleErrorResponse(err));
        }
    };

    const handleDeleteMultiple = () => {
        if (openClient || selectedRows.size === 0) return;
        // TODO: Allow deleting multiple items with a single query via the API
        // TODO: Show confirmation dialog
        const idArray = Array.from(selectedRows);
        idArray.forEach((arrayItem) => {
            axiosApi
                .delete(`/${apiEndpoint}/${arrayItem}/`)
                .then(() => {
                    // Trigger refresh so that the created/updated client appears in the list view
                    setCurrentPage(1);
                    pageHasChanged = true;
                    setOpenClient(null);
                    fetchClients();
                })
                .catch(() => {
                    // TODO: handle error
                });
        });

        setSelectedRows(new Set());
    };

    const handleAddTag = (id: number) => {
        if (!openClient || isClientUser || !interests) return;
        const interest = interests.find((r) => r.id === id);
        if (interest) {
            const newInterests = openClient.contact.interests.slice();
            newInterests.push(interest);
            setModifiedData({
                ...modifiedData,
                contact: {
                    ...modifiedData.contact,
                    interests: newInterests,
                },
            });
            setOpenClient({
                ...openClient,
                contact: {
                    ...openClient.contact,
                    interests: newInterests,
                },
            });
        }
    };

    const handleDeleteTag = (id: number) => {
        if (!openClient || isClientUser) return;
        const newInterests = openClient.contact.interests.filter((interest) => interest.id !== id);
        setModifiedData({
            ...modifiedData,
            contact: {
                ...modifiedData.contact,
                interests: newInterests,
            },
        });
        setOpenClient({
            ...openClient,
            contact: {
                ...openClient.contact,
                interests: newInterests,
            },
        });
    };

    // Reset everything if we change between clients and providers, to reload the view
    useEffect(() => {
        setClientData(null);
        setDiscoverContacts([]);
        setModifiedData({});
        setOpenClient(null);
        setCurrentPage(1);
        setCurrentPageDiscover(1);
        setSearchQuery(null);
        setFilesToUpload([]);
        dispatch(closeModel());
        fetchCompanyData();
        pageHasChanged = true;
        fetchClients();
        fetchDiscoverContacts();
    }, [provider]);

    useEffect(() => {
        if (openClient && openClient.contact.contact_type !== "provider" && !clientReservations) {
            fetchClientReservations();
        } else if (openClient && openClient.contact.contact_type === "provider" && !clientTemplates) {
            fetchClientTemplates("");
        } else if (!openClient) {
            setClientReservations(null);
            setIsCreatingClient(false);
        }
    }, [openClient, clientReservations, fetchClientReservations, fetchClientTemplates]);

    // Refresh modified data when the open client changes
    useEffect(() => {
        if (!openClient) {
            setFieldErrors(new Map());
            // Set an empty contact, in case the form is sent empty, so that we get the required fields.
            // Otherwise we send contact as null and we just get that contact is required, without getting
            // the required fields from it
            setModifiedData({ contact: {} });
            setFilesToUpload([]);
        }
    }, [openClient]);

    // Fetch countries and interests only once, only if we've accessed the client's detail view
    useEffect(() => {
        if (openClient && countries.length === 0) {
            getModelRef("countries").then(
                (retrievedCountries) => retrievedCountries && setCountries(retrievedCountries)
            );
        }
        if (openClient && interests.length === 0) {
            getModelRef("interests").then(
                (retrievedInterests) => retrievedInterests && setInterests(retrievedInterests)
            );
        }
    }, [openClient, countries, interests, getModelRef]);

    // When a search query is submitted, reset the list and re-fetch clients using the query
    useEffect(() => {
        setCurrentPage(1);
        setCurrentPageDiscover(1);
        setClientData(null);
        setDiscoverContacts([]);
    }, [searchQuery]);

    // Initial load of clients
    useEffect(() => {
        if (currentPage === 1) {
            fetchClients();
        }
        if (currentPageDiscover === 1) {
            fetchDiscoverContacts();
        }
    }, [currentPage]);

    useEffect(() => {
        dispatch(saveData()); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const screenTitle = provider ? "Proveedores" : "Clientes";

    const loadingScreen = (
        <ScreenTemplate title={screenTitle}>
            <div className="flex items-center justify-center h-full w-full">
                <Spinner />
            </div>
        </ScreenTemplate>
    );
    if (!clientData && !openClient) {
        return loadingScreen;
    }

    const handleChangeClient = <
        K extends keyof RecursivePartial<ClientData>,
        P extends RecursivePartial<ClientData>[K]
    >(
        field: K,
        value: string | number
    ) => {
        const modified = {
            ...modifiedData,
        };
        modified[field] = value as P;
        setModifiedData(modified);
    };

    const handleChangeContact = <
        K extends keyof RecursivePartial<ContactData>,
        P extends RecursivePartial<ContactData>[K]
    >(
        field: K,
        value: string | number
    ) => {
        const modified = {
            ...modifiedData,
            contact: { ...modifiedData.contact },
        };
        modified.contact[field] = value as P;
        setModifiedData(modified);
    };

    const handleM2oContact = <
        K extends keyof RecursivePartial<ContactData>,
        P extends RecursivePartial<ContactData>[K]
    >(
        field: K,
        selected: ModelRef
    ) => {
        const modified = {
            ...modifiedData,
            contact: { ...modifiedData.contact },
        };
        modified.contact[field] = selected as P;
        setModifiedData(modified);
    };

    const handleChangeOfficeInfo = <
        K extends keyof RecursivePartial<OfficeData>,
        P extends RecursivePartial<OfficeData>[K]
    >(
        field: K,
        ev: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>,
        idx: number
    ) => {
        const modified = {
            ...modifiedData,
            contact: { ...modifiedData.contact },
        };
        const office = modified.contact.offices?.at(idx);
        if (office) {
            office[field] = ev.currentTarget.value as P;
        }
        setModifiedData(modified);
    };

    const handleCreateObjects = (templates: Array<ClientTemplate> | null) => {
        const filteredTemplates: Array<ClientTemplate> | null = [];
        templates &&
            templates.forEach((template: ClientTemplate) => {
                template.provider_name !== companyData?.full_name && filteredTemplates.push(template);
            });
        return filteredTemplates.map((template) => buildClientTemplateRow(template));
    };

    const dataTab = openClient && (
        <Tab name="Datos">
            <div className=" gap-5 flex flex-col xl:grid xl:grid-cols-2 gap-5 mt-10 w-full overflow-y-scroll no-scrollbar scroll-smooth mt-6 pt-2">
                <TextInput
                    id="name"
                    type="text"
                    label="Nombre"
                    required
                    defaultValue={modifiedData?.contact?.name || openClient.contact.name}
                    disabled={isClientUser}
                    onChange={(ev) => {
                        const value = ev.currentTarget.value;
                        clearTimeout(textInputTimer);
                        textInputTimer = setTimeout(() => {
                            handleChangeContact("name", value);
                            dispatch(dataPending());
                        }, 600);

                        return () => clearTimeout(textInputTimer);
                    }}
                    errorMessage={fieldErrors.get("name")}
                />
                {isClientTraveler ? (
                    <TextInput
                        id="surname"
                        type="text"
                        label="Apellidos"
                        required
                        defaultValue={modifiedData?.contact?.surname || openClient.contact.surname}
                        disabled={isClientUser}
                        onChange={(ev) => {
                            const value = ev.currentTarget.value;
                            clearTimeout(textInputTimer);
                            textInputTimer = setTimeout(() => {
                                handleChangeContact("surname", value);
                                dispatch(dataPending());
                            }, 600);

                            return () => clearTimeout(textInputTimer);
                        }}
                        errorMessage={fieldErrors.get("surname")}
                    />
                ) : (
                    <TextInput
                        id="vat"
                        type="text"
                        label="CIF/VAT"
                        defaultValue={modifiedData?.contact?.vat || openClient.contact.vat}
                        disabled={isClientUser}
                        onChange={(ev) => {
                            const value = ev.currentTarget.value;
                            clearTimeout(textInputTimer);
                            textInputTimer = setTimeout(() => {
                                handleChangeContact("vat", value);
                                dispatch(dataPending());
                            }, 600);

                            return () => clearTimeout(textInputTimer);
                        }}
                        errorMessage={fieldErrors.get("vat")}
                    />
                )}

                <TextInput
                    id="address"
                    type="text"
                    label={isClientTraveler ? "Dirección" : "Oficinas centrales"}
                    defaultValue={modifiedData?.contact?.address || openClient.contact.address}
                    disabled={isClientUser}
                    onChange={(ev) => {
                        const value = ev.currentTarget.value;
                        clearTimeout(textInputTimer);
                        textInputTimer = setTimeout(() => {
                            handleChangeContact("address", value);
                            dispatch(dataPending());
                        }, 600);

                        return () => clearTimeout(textInputTimer);
                    }}
                    errorMessage={fieldErrors.get("address")}
                />
                <div className="grid grid-cols-2 gap-5">
                    <TextInput
                        id="city"
                        type="text"
                        label="Ciudad"
                        defaultValue={modifiedData?.contact?.city || openClient.contact.city}
                        disabled={isClientUser}
                        onChange={(ev) => {
                            const value = ev.currentTarget.value;
                            clearTimeout(textInputTimer);
                            textInputTimer = setTimeout(() => {
                                handleChangeContact("city", value);
                                dispatch(dataPending());
                            }, 600);

                            return () => clearTimeout(textInputTimer);
                        }}
                        errorMessage={fieldErrors.get("city")}
                    />
                    <Many2one
                        id="country"
                        label="País"
                        onBlur={(selected: ModelRef) => handleM2oContact("country", selected)}
                        defaultValue={(modifiedData?.contact?.country as ModelRef) || openClient.contact.country}
                        disabled={isClientUser}
                        onClick={(selected: ModelRef) => handleM2oContact("country", selected)}
                        data={countries}
                    />
                </div>
                <TextInput
                    id="email"
                    type="text"
                    label="Email"
                    required
                    defaultValue={modifiedData?.contact?.email || openClient.contact.email}
                    disabled={isClientUser}
                    onChange={(ev) => {
                        const value = ev.currentTarget.value;
                        clearTimeout(textInputTimer);
                        textInputTimer = setTimeout(() => {
                            handleChangeContact("email", value);
                            dispatch(dataPending());
                        }, 600);

                        return () => clearTimeout(textInputTimer);
                    }}
                    errorMessage={fieldErrors.get("email")}
                />
                <TextInput
                    id="phone"
                    type="text"
                    label="Teléfono"
                    defaultValue={modifiedData?.contact?.phone || openClient.contact.phone}
                    disabled={isClientUser}
                    onChange={(ev) => {
                        const value = ev.currentTarget.value;
                        clearTimeout(textInputTimer);
                        textInputTimer = setTimeout(() => {
                            handleChangeContact("name", value);
                            dispatch(dataPending());
                        }, 600);

                        return () => clearTimeout(textInputTimer);
                    }}
                    errorMessage={fieldErrors.get("phone")}
                />
                <div className="col-span-2">
                    <TextInput
                        id="observations"
                        type="text"
                        label="Observaciones"
                        lines={8}
                        defaultValue={modifiedData?.observations || openClient.observations}
                        onChange={(ev) => {
                            const value = ev.currentTarget.value;
                            clearTimeout(textInputTimer);
                            textInputTimer = setTimeout(() => {
                                handleChangeClient("observations", value);
                                dispatch(dataPending());
                            }, 600);

                            return () => clearTimeout(textInputTimer);
                        }}
                        errorMessage={fieldErrors.get("observations")}
                    />
                </div>
                {/* {isClientTraveler ? (
                    <div className="col-span-2">
                        <div className="grid grid-cols-2">
                            <DatetimePicker
                                id="date_of_birth"
                                label="Fecha de nacimiento"
                                defaultValue={
                                    (modifiedData?.contact?.date_of_birth as Date) || openClient.contact.date_of_birth
                                }
                                onChange={(selectedDates) => {
                                    if (selectedDates.length === 1) {
                                        setModifiedData({
                                            ...modifiedData,
                                            contact: {
                                                ...modifiedData.contact,
                                                date_of_birth: formatDateISO(selectedDates[0]),
                                            },
                                        });
                                    }
                                }}
                            />
                        </div>
                    </div>
                ) : null} */}
                {provider && openClient && openClient.contact.is_user && (
                    <div>
                        <Setting
                            title="Destinos"
                            description="Destinos que trabaja tu proveedor."
                            setting={
                                <div>
                                    <div className="flex space-x-5 items-center">
                                        <TagGroup type="yellow" values={openClient.contact.destinations} />
                                    </div>
                                </div>
                            }
                        />
                        <Setting
                            title="Especializaciones"
                            description="Áreas de expertise y especialización."
                            setting={
                                <div>
                                    <div className="flex space-x-5 items-center">
                                        <TagGroup type="yellow" values={openClient.contact.specialties} />
                                    </div>
                                </div>
                            }
                        />
                        <Setting
                            title="Idiomas"
                            description="Idiomas disponibles de atención al cliente."
                            setting={
                                <div>
                                    <div className="space-x-5 items-center">
                                        <TagGroup type="yellow" values={openClient.contact.languages} />
                                    </div>
                                </div>
                            }
                        />
                    </div>
                )}
            </div>
        </Tab>
    );

    const reservationsTab =
        !provider && openClient && !isCreatingClient ? (
            <Tab name="Viajes">
                <div className="flex flex-col mt-10 w-full space-y-10 overflow-y-scroll no-scrollbar scroll-smooth mt-6">
                    <div className="max-w-max place-self-end">
                        <Button
                            label="Nuevo viaje"
                            icon="add"
                            onClick={createNewReservation}
                            type="btn_yellow"
                            title="Añadir nuevo viaje"
                        />
                    </div>
                    {clientReservations ? (
                        <List
                            fields={reservationFields}
                            listType="travellist"
                            objects={clientReservations.map((reservation) => buildClientReservationRow(reservation))}
                            onRowClick={openClientReservation}
                        />
                    ) : (
                        <div className="flex items-center justify-center h-full w-full">
                            <Spinner />
                        </div>
                    )}
                </div>
            </Tab>
        ) : null;

    const employeesTab =
        openClient && !isCreatingClient && isClientUser ? (
            <Tab name="Equipo">
                <div className="mt-10 overflow-y-scroll no-scrollbar scroll-smooth mt-6">
                    <List objects={openClient.contact.employees} fields={employeeFields} listType="teamlist" />
                </div>
            </Tab>
        ) : null;

    const preferencesTab = openClient && (
        <Tab name="Preferencias">
            <div className="mt-10 space-y-3 overflow-y-scroll no-scrollbar scroll-smooth mt-6">
                <TagGroup
                    title="Preferencias seleccionadas"
                    values={openClient.contact.interests}
                    type="yellow"
                    onDelete={!isClientUser ? (id) => handleDeleteTag(id) : undefined}
                />
                {!isClientUser && (
                    <TagGroup
                        title="Listado de preferencias"
                        values={interests.filter(
                            (interest) => !openClient.contact.interests.map((i) => i.id).includes(interest.id)
                        )}
                        onClick={handleAddTag}
                        type="platinum"
                    />
                )}
            </div>
        </Tab>
    );

    const documentationTab = openClient && (
        <Tab name="Documentación">
            <div className="mt-10 flex-col w-full space-y-10 overflow-y-scroll no-scrollbar scroll-smooth mt-6">
                {provider && (
                    <div className="w-full">
                        <div>
                            <FileUploadZone onChangeFiles={addFilesToUpload} multiple />
                        </div>
                    </div>
                )}

                {/* TODO: Paginate results */}
                <List
                    listType="documlist"
                    objects={(() => {
                        const attachmentRows = openClient.attachments.map((attachment) =>
                            buildAttachmentRow(attachment, true, attachment.id)
                        );
                        attachmentRows.push(
                            ...filesToUpload.map((attachment, idx) => buildAttachmentRow(attachment, false, -idx))
                        );
                        return attachmentRows;
                    })()}
                    fields={attachmentFields}
                />
            </div>
        </Tab>
    );

    const officesTab = openClient && (
        <Tab name="Facturación">
            <div className="mt-10 flex-col w-full space-y-10 overflow-y-scroll no-scrollbar scroll-smooth mt-6">
                {openClient.contact.offices.map((office, idx) => (
                    <CollapsableSection
                        key={office.id}
                        title={`Oficina ${office.country}`}
                        buttonSide="left"
                        closedByDefault={idx !== 0}
                    >
                        <div className="flex flex-col gap-5 xl:grid xl:grid-cols-2 mt-10">
                            <TextInput
                                id="name"
                                type="text"
                                label="Razón Fiscal"
                                defaultValue={
                                    modifiedData?.contact?.offices?.at(idx)?.name ||
                                    openClient.contact.offices.at(idx)?.name
                                }
                                disabled={isClientUser}
                                onChange={(ev) => handleChangeOfficeInfo("name", ev, idx)}
                            />
                            <TextInput
                                id="vat"
                                type="text"
                                label="CIF/VAT"
                                defaultValue={
                                    modifiedData?.contact?.offices?.at(idx)?.vat ||
                                    openClient.contact.offices.at(idx)?.vat
                                }
                                disabled={isClientUser}
                                onChange={(ev) => handleChangeOfficeInfo("vat", ev, idx)}
                            />
                            <TextInput
                                id="address"
                                type="text"
                                label="Dirección"
                                defaultValue={
                                    modifiedData?.contact?.offices?.at(idx)?.address ||
                                    openClient.contact.offices.at(idx)?.address
                                }
                                disabled={isClientUser}
                                onChange={(ev) => handleChangeOfficeInfo("address", ev, idx)}
                            />
                            <div className="grid grid-cols-2 gap-5">
                                <TextInput
                                    id="city"
                                    type="text"
                                    label="Ciudad"
                                    defaultValue={
                                        modifiedData?.contact?.offices?.at(idx)?.city ||
                                        openClient.contact.offices.at(idx)?.city
                                    }
                                    disabled={isClientUser}
                                    onChange={(ev) => handleChangeOfficeInfo("city", ev, idx)}
                                />
                                <TextInput
                                    id="city"
                                    type="text"
                                    label="País"
                                    defaultValue={
                                        modifiedData?.contact?.offices?.at(idx)?.country ||
                                        openClient.contact.offices.at(idx)?.country
                                    }
                                    disabled={isClientUser}
                                    onChange={(ev) => handleChangeOfficeInfo("country", ev, idx)}
                                />
                            </div>
                            <div className="col-span-2">
                                <TextInput
                                    id="info"
                                    type="text"
                                    label="Información"
                                    required
                                    defaultValue={
                                        modifiedData?.contact?.offices?.at(idx)?.info ||
                                        openClient.contact.offices.at(idx)?.info
                                    }
                                    disabled={isClientUser}
                                    onChange={(ev) => handleChangeOfficeInfo("info", ev, idx)}
                                    lines={7}
                                />
                            </div>
                        </div>
                    </CollapsableSection>
                ))}
            </div>
        </Tab>
    );

    const clientTemplatesTab = openClient && (
        <Tab name="Tarifarios">
            <div className="mt-10 flex-col w-full space-y-10 overflow-y-scroll no-scrollbar scroll-smooth mt-6">
                <div className="w-full mt-5">
                    <SearchBox
                        placeholder="Buscar título o destino..."
                        onChange={(s) => {
                            isSearchTemplatesActive = true;
                            fetchClientTemplates(s);
                        }}
                        hiddenIcon
                    />
                </div>

                <div className="h-96 overflow-auto w-full" id="templateListContainer">
                    {clientTemplates && (
                        <List
                            fields={clientTemplateFields}
                            objects={handleCreateObjects(clientTemplates)}
                            onScrollToEnd={() => {
                                scrolledTemplates = true;
                                fetchClientTemplates("");
                            }}
                            hasMore={clientTemplates.length < totalRecordsTemplates}
                            scrollableTarget="templateListContainer"
                            listType="tarifarios-providers"
                        />
                    )}
                </div>
            </div>
        </Tab>
    );

    const addClientButton = (onClick: (() => void) | undefined = undefined) => (
        <div className="mt-8 sm:mt-0 relative sm:static  h-[56px] sm:h-auto">
            <Button
                type="btn_dark"
                label="Añadir"
                icon="add"
                onClick={onClick}
                title={`Añadir nuevo ${provider ? "proveedor" : "cliente"}`}
                extraClass="w-full h-full"
            />
        </div>
    );
    const addClientButtonOnClick = () => setAddClientDropdownVisible(!addClientDropdownVisible);
    (companyData?.contact_type === "retail_agency" && (filteredcontactTypes = ["traveler"])) ||
        ((companyData?.contact_type === "wholesale_agency" || companyData?.contact_type === "provider") &&
            (filteredcontactTypes = ["retail_agency"]));

    const listView = companyData && (
        <div className="px-8 w-full h-full pt-2">
            <Container
                numSelected={!openClient ? selectedRows.size : undefined}
                actionArea={
                    <Button
                        label={`Eliminar ${selectedRows.size} ${selectedRows.size < 2 ? "item" : "items"}`}
                        onClick={handleDeleteMultiple}
                        type="btn_red"
                    />
                }
            >
                <div className="flex flex-col h-full">
                    <div className="flex flex-col-reverse sm:flex-row  justify-between gap-4 h-full">
                        <div className="flex-1 self-start w-full sm:max-w-[20rem]">
                            <SearchBox
                                placeholder="Buscar nombre, apellido o email..."
                                onChange={(s) => {
                                    isSearchActive = true;
                                    searchString = s;
                                    fetchClients();
                                    fetchDiscoverContacts();
                                }}
                                hiddenIcon
                            />
                        </div>
                        <div className="mt-20 sm:mt-0">
                            <p className="block sm:hidden text-[2.2rem] font-black font-sans text-blue-dark">
                                {screenTitle}
                            </p>
                            <div>
                                {provider ? (
                                    addClientButton(() => {
                                        createNewClient("provider");
                                    })
                                ) : (
                                    <Dropdown
                                        anchor={addClientButton()}
                                        visible={addClientDropdownVisible}
                                        onClick={addClientButtonOnClick}
                                        onBlur={(e) => {
                                            const currentTarget = e.currentTarget;
                                            setTimeout(() => {
                                                if (!currentTarget.contains(document.activeElement)) {
                                                    setAddClientDropdownVisible(false);
                                                }
                                            }, 0);
                                        }}
                                    >
                                        {filteredcontactTypes
                                            .filter((t) => t !== "provider")
                                            .map((t) => (
                                                <DropdownItem
                                                    key={t}
                                                    label={getContactTypeLabel(t)}
                                                    onClick={() => {
                                                        createNewClient(t);
                                                    }}
                                                />
                                            ))}
                                    </Dropdown>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        {clientData && (
                            <div className="flex flex-col space-y-16 pt-8 h-auto sm:h-[calc(100vh-230px)]">
                                <div
                                    className=" max-h-[24rem] sm:max-h-none sm:h-4/6 overflow-auto"
                                    id="clientListContainer"
                                >
                                    <List
                                        objects={clientData.map((client) => buidlClientRow(client))}
                                        fields={clientFields}
                                        listType="clientlist"
                                        multiselection
                                        onRowClick={(id) => {
                                            setPreviewDiscovery(null);
                                            const client = clientData.find((c) => c.id === id) || null;
                                            if (client) {
                                                setOpenClient(clientData.find((c) => c.id === id) || null);
                                                dispatch(
                                                    openModel({
                                                        modelId: client.id,
                                                        modelName: client.contact.full_name,
                                                    })
                                                );
                                                dispatch(showName());
                                            } else {
                                                setOpenClient(null);
                                                dispatch(closeModel());
                                            }
                                        }}
                                        selectedRows={selectedRows}
                                        onSelect={setSelectedRows}
                                        onScrollToEnd={() => {
                                            scrolled = true;
                                            fetchClients();
                                            fetchDiscoverContacts();
                                        }}
                                        hasMore={clientData.length < totalRecords}
                                        scrollableTarget="clientListContainer"
                                    />
                                </div>
                                <div className="max-h-[24rem] sm:max-h-none sm:h-2/6 flex flex-col space-y-2">
                                    <p className="text-grey">Amplia tu red de contactos</p>
                                    <div className=" overflow-auto" id="discoverListContainer">
                                        <List
                                            objects={discoverContacts.map((cont) => buildDiscoverRow(cont))}
                                            fields={discoverFields}
                                            onRowClick={(id) => {
                                                setPreviewDiscovery(discoverContacts.find((c) => c.id === id) || null);
                                            }}
                                            listType="morecontactlist"
                                            onScrollToEnd={() => {
                                                scrolledDiscovery = true;
                                                fetchDiscoverContacts();
                                            }}
                                            hasMore={discoverContacts.length < totalRecordsDiscover}
                                            scrollableTarget="discoverListContainer"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );

    const detailView = openClient && (
        <div className="flex flex-col w-full h-full pl-8 overflow-y-hidden pr-8">
            <div className="flex w-full items-center self-end space-x-5 max-w-min">
                <div className="w-28 h-16">
                    <Dropdown
                        anchor={
                            <Button
                                label="Opciones"
                                icon="more_vert"
                                type="btn_transparent"
                                title={provider ? "Opciones de proveedor" : "Opciones de cliente"}
                            />
                        }
                        visible={optionsDropdownVisible}
                        onClick={() => setOptionsDropdownVisible(!optionsDropdownVisible)}
                        onBlur={(e) => {
                            const currentTarget = e.currentTarget;
                            setTimeout(() => {
                                if (!currentTarget.contains(document.activeElement)) {
                                    setOptionsDropdownVisible(false);
                                }
                            }, 0);
                        }}
                    >
                        <DropdownItem
                            key="delete"
                            label="Eliminar"
                            labelColor="red"
                            onClick={() => {
                                handleDelete();
                            }}
                        />
                    </Dropdown>
                </div>
                <div className="w-28 h-16">
                    <Button
                        label="Guardar"
                        onClick={() => handleSave()}
                        type="btn_dark"
                        title={provider ? "Guardar proveedor" : "Guardar cliente"}
                    />
                </div>
            </div>
            <div className="hidden md:flex w-full justify-end">
                <button
                    type="button"
                    onClick={() => showSidePanel()}
                    title="Mostrar sidebar"
                    className="flex justify-end text-yellow manoli"
                >
                    {isSidePanelOpened ? `Ocultar resumen ${" >"}` : `${"< "} Mostrar resumen`}
                </button>
            </div>
            <div className="flex w-full h-full justify-between h-[calc(100%-120px)]">
                <div className="overflow-y-scroll no-scrollbar w-full mt-20 sm:mt-8">
                    <TabController>
                        {dataTab}
                        {reservationsTab}
                        {isClientTraveler ? preferencesTab : employeesTab}
                        {documentationTab}
                        {!isClientTraveler && provider ? clientTemplatesTab : null}
                        {!isClientTraveler ? officesTab : null}
                    </TabController>
                </div>

                <ClientPreview
                    isSidePanelOpened={isSidePanelOpened}
                    image={openClient.contact.profile_image?.image}
                    altText={getInitials(openClient.contact.full_name)}
                    type={openClient.contact.contact_type}
                    name={openClient.contact.full_name}
                    rating={openClient.contact.average_rating}
                    numRatings={openClient.contact.num_ratings}
                    numReservations={openClient.num_reservations}
                    numConfirmations={openClient.num_confirmed_reservations}
                    lastTrip={openClient.latest_reservation_date}
                    interests={openClient.contact.interests.map((r) => r.name)}
                    specialties={openClient.contact.specialties.map((dt) => dt.name)}
                    languages={openClient.contact.languages.map((dt) => dt.name)}
                    travelerType={openClient.contact.traveler_type}
                    destinations={openClient.contact.destinations.map((dt) => dt.name)}
                />
            </div>
        </div>
    );

    return (
        <ScreenTemplate
            title={screenTitle}
            backButtonVisible={openClient !== null}
            onBack={() => {
                pageHasChanged = true;
                fetchClients();
                setOpenClient(null);
                dispatch(closeModel());
                dispatch(saveData());
            }}
        >
            <div className="flex w-full">
                {openClient ? detailView : listView}
                {/* WILL BE SHOWN IN THE FUTURE */}
                {/* {!openClient && previewDiscovery && (
                    <ClientPreview
                        isSidePanelOpened={isSidePanelOpened}
                        image={previewDiscovery.profile_image?.image}
                        altText={getInitials(previewDiscovery.full_name)}
                        type={previewDiscovery.contact_type}
                        name={previewDiscovery.full_name}
                        rating={previewDiscovery.average_rating}
                        numRatings={previewDiscovery.num_ratings}
                        specialties={previewDiscovery.specialties.map((dt) => dt.name)}
                        languages={previewDiscovery.languages.map((dt) => dt.name)}
                        destinations={previewDiscovery.destinations.map((dt) => dt.name)}
                        connectButton={
                            <Button
                                label="CONECTAR"
                                type="btn_yellow"
                                icon={faLink}
                                onClick={() => handleConnect(previewDiscovery.id)}
                            />
                        }
                        onClose={() => setPreviewDiscovery(null)}
                    />
                )} */}
            </div>
            {/* Modals */}
            <LoadingModal
                visible={isDownloadingPdfModalVisible}
                text="El PDF se está generando. La descarga comenzará en breve..."
            />
        </ScreenTemplate>
    );
};

export default ClientsScreen;
