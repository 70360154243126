/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import Button from "../Button";
import Modal from "../Modal";
import List from "../List/List";
import { ModelRef } from "../../types";
import SearchBox from "../SearchBox";

export interface SelectionModalProps {
    /**
     * Whether the modal is visible or not.
     */
    visible: boolean;

    /**
     * Function for setting the visible state passed by the parent
     */
    visibleHandler?: (visible: boolean) => void;

    /**
     * Currently selected entry
     */
    selected?: number;

    /**
     * Whether multiple entries can be selected or not.
     */
    multiselection?: boolean;

    /**
     * Callback when the accept button is pressed, with all the selected entries.
     */
    onAccept: (selected: ModelRef[]) => void;

    /**
     * Title of the dialog.
     */
    title: string;

    /**
     * Description shown under the dialog title.
     */
    description?: string;

    /**
     * Title for the list header.
     */
    header: string;

    /**
     * Full list of values to choose from.
     */
    values: ModelRef[];

    /**
     * Placeholder text to show in the search box.
     */
    searchBoxPlaceholder?: string;
}

/**
 * A simple modal whose only purpose is to show a list of values from a m2o field,
 * for the user to choose one or more from, with a search box to filter by name.
 *
 * A list with a single column is shown, which should be the value of the ModelRef.
 */
const SelectionModal: React.FC<SelectionModalProps> = ({
    visible,
    visibleHandler,
    selected,
    onAccept,
    title,
    description = "",
    header,
    values,
    multiselection = false,
    searchBoxPlaceholder = "",
}) => {
    const [valuesFiltered, setValuesFiltered] = useState<ModelRef[]>(values);
    const [selectedRows, setSelectedRows] = useState<Set<number> | undefined>(
        selected ? new Set<number>([selected]) : undefined
    );
    const [searchQuery, setSearchQuery] = useState<string | null>(null);

    const listFields: {
        key: keyof ModelRef;
        title: string;
    }[] = [
        {
            key: "name",
            title: header,
        },
    ];

    const filterValues = () => {
        if (searchQuery) {
            const fuse = new Fuse(values, { keys: ["name"], ignoreLocation: true });
            setValuesFiltered(fuse.search(searchQuery).map((entry) => entry.item));
        } else {
            setValuesFiltered(values);
        }
    };

    const onSaveHandler = () => {
        if (selectedRows) {
            onAccept(values.filter((value: ModelRef) => selectedRows.has(value.id)));
        }
        window.dispatchEvent(new Event("closeModal"));
    };

    const mitmVisibleHandler = (isVisible: boolean) => {
        visibleHandler && visibleHandler(isVisible);
        setSearchQuery(null);
    };

    // When the search query changes, or the original data changes, filter using a fuzzy search
    useEffect(() => {
        filterValues();
    }, [values, searchQuery]);

    useEffect(() => {
        if (visible) {
            // Reset selected rows to the default ones
            setSelectedRows(selected ? new Set<number>([selected]) : undefined);
        }
    }, [visible, selected, setSelectedRows]);

    return (
        <Modal visible={visible} visibleHandler={mitmVisibleHandler}>
            <div className="flex flex-col items-center justify-center w-fullh-full p-10 space-y-5">
                <div className="flex flex-col w-full">
                    <h2 className="text-2xl font-bold font-sans pb-2">{title}</h2>
                    <span className="text-sm font-bold font-sans text-blue-light-2">{description}</span>
                </div>
                <div className="w-full mt-5">
                    <SearchBox
                        placeholder={searchBoxPlaceholder}
                        // placeholder="Buscar por nombre de cliente o agencia"
                        onChange={(s) => setSearchQuery(s)}
                    />
                </div>

                <div className="h-96 overflow-auto w-full" id="countryListContainer">
                    <List
                        selectedRows={selectedRows}
                        onSelect={setSelectedRows}
                        fields={listFields}
                        objects={valuesFiltered}
                        onRowClick={(id: number) => setSelectedRows(new Set([id]))}
                        multiselection={multiselection}
                        scrollableTarget="countryListContainer"
                    />
                </div>
                <div className="flex flex-col-reverse sm:flex-row w-full items-center justify-end gap-2 flex-wrap">
                    <div className="w-full sm:w-auto">
                        <Button
                            label="Cancelar"
                            type="btn_transparent"
                            onClick={() => window.dispatchEvent(new Event("closeModal"))}
                            extraClass="w-full h-[54px] sm:h-auto"
                        />
                    </div>
                    <div className="w-full sm:w-auto">
                        <Button
                            type="btn_dark"
                            label="Guardar"
                            onClick={onSaveHandler}
                            extraClass="w-full h-[54px] sm:h-auto"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SelectionModal;
