import React from "react";

export interface TabProps {
    /**
     * Content of the tab view. It's what will be shown under the tab header
     * when this tab is selected.
     */
    children?: React.ReactNode;

    /**
     * Name of the tab, which will be shown on the header where the user can choose
     * which tab to select.
     */
    name: string;
}

/**
 * The contents of a tab. It is meant to be used within a TabController,
 * as the controller will control which is the currently visible tab, and hide
 * the others, as well as give a way to switch between tabs using buttons at the top.
 */
const Tab: React.FC<TabProps> = ({ children }) => <> {children}</>;

export default Tab;
