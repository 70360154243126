import classNames from "classnames";
import React from "react";

export interface ContainerProps {
    /**
     * Child view of the container, typically a list/detail model view, a configuration
     * page, a dashboard card, etc.
     */
    children?: React.ReactNode;

    /**
     * If the container is used in a list view, the number of list items selected to display in the bottom banner.
     */
    numSelected?: number;

    /**
     * Whether the container should use a background color or not
     */
    bg?: boolean;

    /**
     * Whether the container should cover as much of the screen as possible or only what it needs
     */
    fullscreen?: boolean;

    /**
     * If the container has a list view, the label of the button in the bottom banner that appears when some row is selected.
     */
    labelBanner?: string;

    /**
     * If the container has a list view, this is the action area with buttons to be rendered
     */
    actionArea?: React.ReactNode;

    /**
     * Whether the container acts as a bubble, i.e. it has a visible appearance, with a background color and rounded eges with shadows.
     */
    bubble?: boolean;
}

/**
 * Container for most views in the platform, giving them a 'card' feel by adding some margins,
 * rounded edges, etc.
 */
const Container: React.FC<ContainerProps> = ({
    children,
    numSelected,
    actionArea,
    bg = true,
    bubble = false,
    fullscreen = true,
}) => (
    <div className={classNames(bg && "bg-white", fullscreen && "w-full", "")}>
        <div className={classNames(fullscreen && "w-full h-full", bubble && "bg-white rounded-md shadow-lg")}>
            {children}
        </div>
        <div className={classNames("absolute w-[calc(100vw-380px)] self-end mb-2 bottom-0 z-50")}>
            <div
                className={classNames(
                    numSelected && numSelected > 0 ? "flex justify-between items-center" : "hidden",
                    "bg-yellow text-white py-3 px-8",
                    bubble ? "rounded-b-md" : "rounded-md"
                )}
            >
                <span>
                    <b>{numSelected}</b> {`${numSelected && numSelected < 2 ? "seleccionado" : "seleccionados"}`}
                </span>
                <div className="min-w-max">{actionArea}</div>
            </div>
        </div>
    </div>
);

export default Container;
