import React, { useState } from "react";
import classNames from "classnames";
import Icon from "@mui/material/Icon";
import TabButton from "./TabButton";
import { TabProps } from "./Tab";
import useAlert from "../../useAlert";
import Modal from "../Modal";
import TextInput from "../TextInput";
import Button from "../Button";

export interface TabControllerProps {
    /**
     * Children tab views, which each determine on which tab they
     * will be visible.
     */
    children: Array<React.ReactElement<TabProps> | null>;

    /**
     * If true, the tab view will have the alternative look and feel of a "flow" from left to right, like in
     * the reservation screen.
     */
    flowView?: boolean;

    /**
     * Sends active tab to parent
     */
    sendActiveTab?: (activeTab: number) => void;

    /**
     * If true, it will prevent the switching between tabs when the user clicks on a different tab,
     * effectively locking the user inside the currently selected tab.
     * This can be useful if the user is supposed to finish something, like saving changes, before switching to
     * another tab.
     */
    locked?: boolean;

    /**
     * Optional message to show as a warning alert when the tabs are locked and the user tries to change to
     * a different tab, so they don't think the page is just not responding and get some informative message
     * telling them why they can't switch tabs.
     */
    lockReason?: string;
    /**
     * Handler for creating new tabs
     */
    newTabHandler?: (tabName: string, setActiveTab: (n: number) => void) => void;
    /**
     * Optional change tab listener with the index of the tab that has been selected.
     */
    onChangeTab?: (tab: number) => void;
    /**
     * The tab that is selected by default.
     * If not set, it is the first tab (idx 0).
     */
    defaultTab?: number;
}

/**
 * Tab controller to determine which tab is currently selected and therefore visible.
 * The TabController is supposed to be the parent of all tabs it controls.
 */
const TabController: React.FC<TabControllerProps> = ({
    children,
    flowView = false,
    locked = false,
    lockReason,
    newTabHandler,
    onChangeTab,
    defaultTab = 0,
    sendActiveTab,
}) => {
    const [activeTab, setActiveTab] = useState(defaultTab);
    const [newTabModalVisible, setNewTabModalVisible] = useState(false);
    const [tabName, setTabName] = useState("");
    const handleSendActiveTab = () => {
        sendActiveTab && sendActiveTab(activeTab);
    };
    const { addAlert } = useAlert();

    const showLockReasonAlert = () => {
        if (!locked || !lockReason) return;
        addAlert(lockReason, "warning");
    };

    const onCreateHandler = () => {
        newTabHandler && newTabHandler(tabName, setActiveTab);
        window.dispatchEvent(new Event("closeModal"));
        setTabName("");
    };

    const onTabNameChangeHandler = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setTabName(e.target.value);
    };

    return (
        <div onLoad={handleSendActiveTab} className="h-full w-full">
            <div className="w-full h-full flex flex-col">
                {/* Show clickable tab headers to allow switching between tabs */}
                <div className={classNames("flex items-center flex-wrap", flowView && "justify-center")}>
                    {React.Children.map(children as React.ReactElement<TabProps> | null, (child, idx) => {
                        const isActive = idx === activeTab;
                        const isNextActive = idx + 1 === activeTab;

                        return (
                            <>
                                {child && (
                                    <TabButton
                                        title={child.props.name}
                                        selected={isActive}
                                        onClick={
                                            locked
                                                ? () => showLockReasonAlert()
                                                : () => {
                                                      setActiveTab(idx);
                                                      onChangeTab && onChangeTab(idx);
                                                  }
                                        }
                                    />
                                )}
                                {flowView && idx < children.length - 1 && (
                                    <Icon
                                        className={classNames((isActive || isNextActive) && "text-yellow")}
                                        sx={{ fontSize: "19px" }}
                                    >
                                        chevron_right
                                    </Icon>
                                )}
                            </>
                        );
                    })}
                    {newTabHandler && (
                        <TabButton
                            title="+"
                            selected={false}
                            type="primary"
                            onClick={() => setNewTabModalVisible(true)}
                        />
                    )}
                </div>
                {/* Only show the contents of the selected tab view */}
                {children[activeTab]}
            </div>
            <Modal visible={newTabModalVisible} visibleHandler={setNewTabModalVisible}>
                <div className="flex flex-col items-center justify-center h-full p-10 space-y-5">
                    <h1>Nueva pestaña</h1>
                    <TextInput id="tab_name" label="Nombre" value={tabName} onChange={onTabNameChangeHandler} />
                    <div className="flex flex-row w-full items-center justify-end space-x-3">
                        <Button
                            label="Cancelar"
                            type="btn_transparent"
                            onClick={() => {
                                setTabName("");
                                window.dispatchEvent(new Event("closeModal"));
                            }}
                        />
                        <Button label="Crear" onClick={onCreateHandler} />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default TabController;
