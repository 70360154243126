import classNames from "classnames";
import React from "react";
import { ModelRef } from "../types";
import Tag from "./Tag";

export interface TagGroupProps {
    /**
     * Title that is shown on top of the tags, and describes what the tags are about.
     */
    title?: string;

    /**
     * Values for the tags that exist in the group, which can be just labels (strings) or
     * model refs (id and label).
     */
    values: Array<ModelRef> | Array<string>;

    /**
     * Defines the style of the tags in the group.
     */
    type?: "yellow" | "grey" | "platinum";

    /**
     * If defined, this handler will be passed to each tag with the corresponding id information
     * associated.
     */
    onClick?: (id: number) => void;

    /**
     * If defined, all the tags in the group will have a cross on the right that when
     * clicked will call this handler.
     */
    onDelete?: ((id: number) => void) | false;

    /**
     * If true, the label will be shown to the left of the tag group instead of on top.
     */
    inline?: boolean;

    /**
     * If present, only tags up to this number will be shown, and if there are more, the remaining number
     * of tags will be shown as a +x where x is the remainder.
     */
    maxTags?: number;
}

/**
 * Shows a list of labels as tags under a title, in sequence and with line wrap, under a small title.
 */
const TagGroup: React.FC<TagGroupProps> = ({
    title,
    values,
    type = "grey",
    onClick,
    onDelete,
    inline = false,
    maxTags,
}) => (
    <div className={classNames(inline ? "flex flex-col pr-2" : "flex-col")}>
        {title && <p className={classNames("font-semibold text-grey text-sm mt-1")}>{title}</p>}
        <div className="flex flex-wrap mt-2">
            {values &&
                values.map((value: ModelRef | string, idx: number) => {
                    if (maxTags !== undefined && maxTags > 0 && idx >= maxTags) {
                        return null;
                    }

                    let label = "";
                    let id = 0;
                    if (typeof value === "string") {
                        label = value;
                    } else if ((value as ModelRef) !== undefined) {
                        label = value.name;
                        id = value.id;
                    }
                    return (
                        <div key={id > 0 ? id : label} className="mr-2 mb-2">
                            <Tag
                                label={label}
                                type={type}
                                onClick={onClick && (() => onClick(id))}
                                onDelete={onDelete && (() => onDelete(id))}
                            />
                        </div>
                    );
                })}
        </div>
    </div>
);

export default TagGroup;
