/* eslint-disable react/display-name */
/* eslint-disable no-param-reassign */
import React from "react";
import Icon from "@mui/material/Icon";
import classNames from "classnames";
import MuiButton from "@mui/material/Button";

export interface ButtonProps {
    /**
     * Text shown within the button.
     */
    label: string;

    /**
     * Tooltip of the button
     */
    title?: string;

    /**
     * Defines the background color and text color.
     * Yellow buttons are the default and most common, and have a yellow gold background
     * with dark grey text.
     * Grey buttons have a dark grey background and white text.
     * Dark buttons have a gradient of blue to dark blue and have white text.
     * Transparent buttons have no background color and have grey text.
     */
    type?: "btn_yellow" | "btn_dark" | "btn_transparent" | "btn_red" | "btn_disabled";

    smallIcon?: boolean;

    /**
     * Optional icon which will appear to the right of the label.
     */
    icon?: string;

    /**
     *
     * Optional click handler.
     */
    onClick?: () => void;

    /**
     * Whether this button can be used to submit the info in the form it"s in,
     * given that the form has an onSubmit handler.
     */
    submit?: boolean;

    /**
     * Whether the icon, if any, should be shown on the right of the button label or on the
     * left. Right by default.
     */
    iconSide?: "right" | "left";

    /**
     * Extra class slot for adding custom css properties
     */
    extraClass?: string;
}

/**
 * Primary UI component for user interaction.
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ label, icon, onClick, submit, type, title, iconSide = "right", smallIcon, extraClass }, ref) => {
        const iconTag = icon ? (
            <Icon
                sx={{ fontSize: "18px" }}
                className={`${smallIcon ? "text-xs" : "text-sm"}  font-light ml-3 relative bottom-[1px] left-[2px]`}
            >{`${icon}`}</Icon>
        ) : null;

        return (
            <MuiButton
                variant={type}
                type={submit ? "submit" : "button"}
                onClick={onClick}
                ref={ref}
                title={title}
                className={classNames("shadow", extraClass)}
                sx={{ minWidth: 115 }}
            >
                {iconSide === "left" && iconTag}
                {label ? <span className="whitespace-nowrap">{label}</span> : null}
                {iconSide === "right" && iconTag}
            </MuiButton>
        );
    }
);

export default Button;
