import React, { MouseEvent, useState } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { dataPending } from "../redux/reservations";
import { EmployeeData } from "../types";
import DropdownItem from "./Dropdown/DropdownItem";
import Dropdown from "./Dropdown/Dropdown";
import Avatar from "./Avatar";
import { getInitials } from "../utils";

export interface AssigneeSelectionProps {
    /**
     * Employee options and their data.
     */
    choices: EmployeeData[];
    /**
     * Default selected value when the user hasn't explicitly selected a value, empty by default
     */
    defaultValue?: EmployeeData;
    /**
     * Optional onChange handler.
     */
    onChange?: (choice: EmployeeData) => void;
}

const AssigneeSelection: React.FC<AssigneeSelectionProps> = ({ choices, defaultValue, onChange }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [selected, setSelected] = useState<EmployeeData | undefined>(defaultValue || undefined);

    const avatarText = selected ? getInitials(selected.full_name) : "";
    const dispatch = useDispatch();
    const onClickHandler = (assignee: EmployeeData) => {
        setSelected(assignee);
        setDropdownVisible(false);
        onChange && onChange(assignee);
        dispatch(dataPending());
    };

    const optionElements = choices.map((assignee) => (
        <DropdownItem
            key={assignee.id}
            label={assignee.full_name}
            onClick={() => onClickHandler(assignee)}
            // this lets us call onClick before onBlur
            onMouseDown={(e: MouseEvent<HTMLButtonElement>) => e.preventDefault()}
        />
    ));

    const anchor = (
        <button
            type="button"
            tabIndex={0}
            className={classNames(
                "flex flex-col relative w-full justify-start cursor-pointer text-blue-light-2 focus-within:text-yellow"
            )}
        >
            <div className="flex items-center space-x-2 text-black">
                <Avatar image={selected?.profile_image?.image} altText={avatarText} />

                <div className="flex-col space-y-1 text-left">
                    <div className="grid">
                        <p className="relative top-1 whitespace-nowrap truncate">
                            {selected?.full_name || "Sin asignar"}
                        </p>
                    </div>

                    <p className="text-sm text-grey">{selected?.role}</p>
                </div>
            </div>
        </button>
    );

    return (
        <Dropdown
            anchor={anchor}
            sameWidth
            visible={dropdownVisible}
            onClick={() => setDropdownVisible(!dropdownVisible)}
            onBlur={(e) => {
                const currentTarget = e.currentTarget;
                setTimeout(() => {
                    if (!currentTarget.contains(document.activeElement)) {
                        setDropdownVisible(false);
                    }
                }, 0);
            }}
        >
            {optionElements}
        </Dropdown>
    );
};

export default AssigneeSelection;
