/* eslint-disable import/no-extraneous-dependencies */
import "../mokenCalendar.css";

import React from "react";
import Flatpickr, { DateTimePickerProps } from "react-flatpickr";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import TextInput from "./TextInput";
import { formatTime } from "../utils";

export interface DatetimePickerProps {
    /**
     * Identifies the input, and allows it to be referenced when contained in a form.
     */
    id: string;
    /**
     * Text describing the input field, shown on top of it.
     */
    label?: string;
    /**
     * Placeholder text shown in the input, in a lighter color.
     */
    placeholder?: string;
    /**
     * Whether the text input is required in the form it lives in.
     */
    required?: boolean;
    /**
     * Whether the input is disabled or not.
     */
    disabled?: boolean;
    /**
     * Whether the component should allow date, datetime or time selection
     */
    mode?: "date" | "datetime" | "time";
    /**
     * Default value of the input, in case it should be pre-filled.
     */
    defaultValue?: Date;
    /**
     * Value of the input, in case it should be managed by the parent.
     */
    value?: Date;
    /**
     * Whether the input field is editable directly
     */
    editable?: boolean;
    /**
     * Optional onChange handler.
     */
    onChange?: DateTimePickerProps["onChange"];
}

/**
 * Input field which the user can use to select dates, times and datetimes.
 */
const DatetimePicker: React.FC<DatetimePickerProps> = ({
    id,
    label,
    placeholder,
    required,
    disabled,
    mode = "date",
    defaultValue,
    value,
    editable,
    onChange,
}) => {
    let fmt = "d/m/Y";
    if (mode === "datetime") {
        fmt += " H:i";
    } else if (mode === "time") {
        fmt = "H:i";
    }

    const formatDateForInput = (date: Date | undefined): string => {
        let dateStr = "";
        if (date) {
            // Dates could be passed as strings and JS would not complain until it tries to call the functions below,
            // so make it a date explicitly just in case
            const dateVal = new Date(date);
            if (mode === "time") {
                dateStr = formatTime(date);
            } else {
                dateStr = `${dateVal.getDate().toString().padStart(2, "0")}/${(dateVal.getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}/${dateVal.getFullYear()}`;
            }
        }
        return dateStr;
    };

    const opts = {
        wrap: true,
        allowInput: editable,
        enableTime: mode === "datetime" || mode === "time",
        dateFormat: fmt,
        noCalendar: mode === "time",
        time_24hr: true,
    };

    return (
        <div>
            <Flatpickr options={opts} onChange={onChange}>
                <TextInput
                    id={id}
                    type="text"
                    label={label}
                    placeholder={placeholder}
                    required={required}
                    disabled={disabled}
                    // Component has to either be controlled or uncontrolled, can't be both at the same time.
                    // For more info see https://reactjs.org/docs/forms.html#controlled-components
                    defaultValue={!value ? formatDateForInput(defaultValue) : undefined}
                    value={value && formatDateForInput(value)}
                    innerIcon={faCalendar}
                />
            </Flatpickr>
        </div>
    );
};

export default DatetimePicker;
