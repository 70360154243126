import React from "react";
import Star from "./Star";

export interface RatingProps {
    /**
     * Average rating to be displayed. It is a float in the range [1, 5] U {0}.
     * It will snap to the closest half-star, meaning a rating of e.g. 3.42 will
     * appear in as 3 stars and a half.
     * If the rating is 0 it means there are no ratings yet, and nothing will be shown.
     */
    rating: number;

    /**
     * The number of ratings, which will appear to the right of the stars in parentheses.
     */
    numRatings: number;
}

/**
 * Shows the average rating a company has as well as the number of ratings.
 * It will show the average with 5 stars which can be colored (yellow) or gray.
 * For example, 3 colored stars and 2 gray stars means an average rating of around 3/5.
 * Next to the stars, the total number of ratings on which the average is based is shown.
 */
const Rating: React.FC<RatingProps> = ({ rating, numRatings }) => {
    const getStars = () => {
        // Add the needed stars in order
        let remainingStars = Math.round(rating * 2) / 2;
        const stars = [];
        for (let i = 0; i < 5; i += 1) {
            if (remainingStars >= 1) {
                stars.push(<Star key={i} type="full" />);
            } else if (remainingStars > 0) {
                stars.push(<Star key={i} type="half" />);
            } else {
                stars.push(<Star key={i} type="empty" />);
            }
            remainingStars -= 1;
        }
        return stars;
    };

    return (
        <div className="flex items-center">
            <div className="flex">{numRatings > 0 ? getStars() : null}</div>
            <span className="pl-1 font-sans text-xs text-grey-light-1">
                {numRatings > 0 ? `(${numRatings})` : null}
            </span>
        </div>
    );
};

export default Rating;
