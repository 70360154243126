import React from "react";
import classNames from "classnames";
import Icon from "@mui/material/Icon";
import { Choice, ReservationState } from "../types";
import { getChoice } from "../utils";

export interface StateBadgeProps {
    /**
     * The state that the badge represents.
     * Determines the label, icon and color of the badge.
     */
    state: ReservationState;

    /**
     * The list of possible states.
     */
    states: Choice[];
}

/**
 * A colored badge representing a reservation state.
 */
const StateBadge: React.FC<StateBadgeProps> = ({ state, states }) => {
    let icon = "clock";
    let textColor;
    let borderColor;

    const stateChoice = getChoice(state, states);
    if (stateChoice === undefined) return null;

    if (stateChoice) {
        switch (stateChoice.value as ReservationState) {
            case "in_progress":
            case "res_in_progress":
                icon = "offline_bolt";
                textColor = "text-blue-dark";
                borderColor = "bg-blue-light-2";
                break;
            case "sent":
            case "res_sent":
                icon = "check_circle";
                textColor = "text-green-dark-2";
                borderColor = "bg-green-light-3";
                break;
            case "lost":
                icon = "cancel";
                textColor = "text-red-dark-1";
                borderColor = "bg-red-light-3";
                break;
            case "cancelled":
                icon = "cancel";
                textColor = "text-red-dark-1";
                borderColor = "bg-red-light-3";
                break;
            case "completed":
                icon = "check_circle";
                textColor = "text-green-dark-2";
                borderColor = "bg-green-light-3";
                break;
            case "new":
            case "confirmed":
                icon = "stars";
                textColor = "text-yellow-dark-4";
                borderColor = "bg-yellow-light-2";
                break;
            default:
                icon = "offline_bolt";
                textColor = "text-red-dark-1";
                borderColor = "bg-red-light-3";
                break;
        }
    }

    return (
        <div
            className={classNames(
                "flex w-max min-w-[110px] px-2.5 py-1 space-x-2 items-center   rounded-md text-xs",
                borderColor
            )}
        >
            <Icon
                sx={{ fontSize: "20px" }}
                baseClassName="material-icons-outlined"
                className={textColor}
            >{`${icon}`}</Icon>
            <p className={classNames(textColor, "relative top-[1px]")}>{stateChoice.display}</p>
        </div>
    );
};

export default StateBadge;
