/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { dataPending } from "../../redux/reservations";
import Button from "../Button";
import Modal from "../Modal";
import List from "../List/List";
import axiosApi from "../../axiosApi";
import { getAvatarWithName } from "../../utils";
import { ContactMinimalData, ContactType, MokenImage } from "../../types";
import SearchBox from "../SearchBox";

const PAGE_SIZE = 10;

export interface ClientSelectModalProps {
    /**
     * Whether the modal is visible or not.
     */
    visible: boolean;

    /**
     * Function for setting the visible state passed by the parent
     */
    visibleHandler?: (visible: boolean) => void;

    /**
     * Currently selected client
     */
    selected?: number;

    /**
     * Callback for setting the reservation's client
     */
    onAccept: (selected: ContactMinimalData | undefined) => void;

    /**
     * Optional filter by contact type. If used, it will show only contacts
     * of that type.
     */
    contact_type?: ContactType;
}

interface ClientData {
    id: number;
    full_name: string;
    is_user: string;
    client_type: string;
    profile_image?: MokenImage;
}

interface ClientDataRow {
    id: number;
    full_name: React.ReactNode;
    is_user: string;
    client_type: string;
    profile_image?: MokenImage;
}

const clientFields: {
    key: keyof ClientData;
    title: string;
}[] = [
    {
        key: "full_name",
        title: "Nombre",
    },
    {
        key: "is_user",
        title: "Moken",
    },
    {
        key: "client_type",
        title: "Tipo",
    },
];

const ClientSelectModal: React.FC<ClientSelectModalProps> = ({
    visible,
    visibleHandler,
    selected,
    onAccept,
    contact_type,
}) => {
    const [clientData, setClientData] = useState<Array<ClientData> | null>(null);
    const [currentPage, setCurrentPage] = useState(1); // For pagination, starts at 1
    const [totalRecords, setTotalRecords] = useState(1);
    const [selectedRows, setSelectedRows] = useState<Set<number> | undefined>(
        selected ? new Set<number>([selected]) : undefined
    );
    const dispatch = useDispatch();
    let scrolled = false;
    let isSearchActive = false;

    const getContactTypeLabel = (contactType: string) => {
        // TODO: Translate names
        switch (contactType) {
            case "retail_agency":
                return "Agencia minorista";
            case "wholesale_agency":
                return "Agencia mayorista";
            case "provider":
                return "Proveedor";
            case "traveler":
            default:
                return "Particular";
        }
    };

    const onSaveHandler = () => {
        if (clientData && selectedRows) {
            const selectedClient = clientData.filter((client: ClientData) => selectedRows.has(client.id))[0];
            onAccept({
                id: selectedClient.id,
                full_name: selectedClient.full_name,
                profile_image: selectedClient.profile_image,
            });
        } else {
            onAccept(undefined);
        }
        dispatch(dataPending());
        window.dispatchEvent(new Event("closeModal"));
    };

    const mitmVisibleHandler = (isVisible: boolean) => {
        visibleHandler && visibleHandler(isVisible);
    };

    const fetchClients = async (s: string) => {
        await axiosApi
            .get(
                `/clients/selection/?limit=${PAGE_SIZE}&offset=${
                    (scrolled ? currentPage : currentPage - 1) * PAGE_SIZE
                }${s ? `&query=${s}` : ""}${contact_type ? `&contact_type=${contact_type}` : ""}`
            )
            .then((response) => {
                setTotalRecords(response.data.count);
                setClientData((!clientData || isSearchActive ? [] : clientData).concat(response.data.results));
                scrolled && !isSearchActive ? setCurrentPage(currentPage + 1) : setCurrentPage(1);
            });
    };

    // Initial load of clients
    useEffect(() => {
        if (currentPage === 1) {
            fetchClients("");
        }
    }, [currentPage]);

    useEffect(() => {
        if (visible) {
            // Reset selected rows to the default ones
            setSelectedRows(selected ? new Set<number>([selected]) : undefined);
        }
    }, [visible, selected, setSelectedRows]);

    return (
        <Modal visible={visible} visibleHandler={mitmVisibleHandler}>
            <div className="flex flex-col  justify-center w-full h-full p-10 space-y-5">
                <div className="flex flex-col justify-center w-full">
                    <h2 className="text-2xl font-bold font-sans">Selecciona un cliente</h2>
                </div>
                <div className="w-full mt-5 flex justify-end md:justify-between flex-wrap">
                    <div className="basis-full md:basis-[300px]">
                        <SearchBox
                            placeholder="Buscar nombre de cliente o agencia"
                            onChange={(s) => {
                                isSearchActive = true;
                                fetchClients(s);
                            }}
                            hiddenIcon
                        />
                    </div>

                    <button
                        type="button"
                        onClick={() => {
                            setSelectedRows(undefined);
                        }}
                        className="text-red border border-red px-4 rounded-[4px] h-[40px] mt-4 md:mt-0"
                        title="Anular cliente seleccionado"
                    >
                        Anular selección
                    </button>
                </div>
                <div className="h-96 overflow-auto w-full" id="clientListContainer">
                    {clientData && clientFields && (
                        <List
                            selectedRows={selectedRows}
                            onSelect={setSelectedRows}
                            fields={clientFields}
                            listType="userlist"
                            objects={clientData.map(
                                (client: ClientData): ClientDataRow => ({
                                    ...client,
                                    full_name: getAvatarWithName(client?.full_name, null, client.profile_image?.image),
                                    is_user: client.is_user ? "Sí" : "No",
                                    client_type: getContactTypeLabel(client.client_type),
                                })
                            )}
                            onRowClick={(id: number) => setSelectedRows(new Set([id]))}
                            onScrollToEnd={() => {
                                scrolled = true;
                                fetchClients("");
                            }}
                            hasMore={clientData.length < totalRecords}
                            scrollableTarget="clientListContainer"
                        />
                    )}
                </div>
                <div className="pt-8">
                    <p>
                        ¿No encuentras tu cliente?{" "}
                        <a className="text-yellow" href="./clients">
                            Aquí
                        </a>{" "}
                        puedes crearlo.
                    </p>
                </div>
                <div className="flex flex-col-reverse sm:flex-row w-full items-center justify-end pt-8 flex-wrap gap-2">
                    <div className="w-full sm:w-auto">
                        <Button
                            label="Cancelar"
                            type="btn_transparent"
                            onClick={() => window.dispatchEvent(new Event("closeModal"))}
                            extraClass="w-full h-[54px] sm:h-auto"
                        />
                    </div>

                    <div className="w-full sm:w-auto">
                        <Button
                            label="Guardar"
                            onClick={onSaveHandler}
                            type="btn_dark"
                            extraClass="w-full h-[54px] sm:h-auto"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ClientSelectModal;
