import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { dataPending, saveData } from "../../redux/reservations";
import Button from "../Button";
import DatetimePicker from "../DatetimePicker";
import Modal from "../Modal";
import Selection from "../Selection";
import TagGroup from "../TagGroup";
import TextInput from "../TextInput";
import { ModelRef, ReservationDetailData } from "../../types";
import useModelRef from "../../useModelRef";
import { formatDateISO, getChoice } from "../../utils";
import { ReservationRequestData } from "../../screens/InboxScreen";
import ClientSelectModal from "./ClientSelectModal";
import CountrySelectModal from "./CountrySelectModal";
import useAlert from "../../useAlert";
import axiosApi from "../../axiosApi";

export interface RequestModalProps {
    /**
     * Whether the modal is visible or not.
     */
    visible: boolean;

    /**
     * Function for setting the visible state passed by the parent
     */
    visibleHandler?: (visible: boolean) => void;

    requestData: Partial<ReservationRequestData>;

    reservationId: number;

    newRequest?: boolean;

    defaultProvider?: ModelRef;

    onSubmit?: (data: ReservationDetailData) => void;
}

const RequestModal: React.FC<RequestModalProps> = ({
    visible,
    visibleHandler,
    requestData,
    reservationId,
    defaultProvider,
    newRequest = false,
    onSubmit,
}) => {
    const [countries, setCountries] = useState<ModelRef[]>([]);
    const [interests, setInterests] = useState<ModelRef[]>([]);
    const [data, setData] = useState<Partial<ReservationRequestData>>(requestData);
    const [isProviderSelectModalVisible, setIsProviderSelectModalVisible] = useState(false);
    const [isCountrySelectModalVisible, setIsCountrySelectModalVisible] = useState(false);
    const [provider, setProvider] = useState<ModelRef | undefined>(defaultProvider);
    const [destinations, setDestinations] = useState<Set<ModelRef>>(new Set());

    const { getChoiceRef, getModelRef } = useModelRef();
    const { addAlert } = useAlert();

    const travelTypes = getChoiceRef("travelType");
    const travelerTypes = getChoiceRef("travelerType");
    const dispatch = useDispatch();
    let textInputTimer: ReturnType<typeof setTimeout>;

    const handleAddTag = (id: number) => {
        const interest = interests.find((r) => r.id === id);
        if (interest && data.interests) {
            const newInterests = data.interests.slice();
            newInterests.push(interest);
            setData({ ...data, interests: newInterests });
        }
    };

    const handleDeleteTag = (id: number) => {
        if (data.interests) {
            const newInterests = data.interests.filter((interest) => interest.id !== id);
            setData({ ...data, interests: newInterests });
        }
    };

    const handleSubmit = () => {
        if (!provider) {
            addAlert("Selecciona un proveedor al que solicitar el itinerario.", "warning");
            return;
        }

        if (destinations.size === 0) {
            addAlert("Selecciona al menos un destino.", "warning");
            return;
        }

        axiosApi
            .post(`/reservations/${reservationId}/create_request/`, {
                provider: provider.id,
                request: {
                    ...data,
                    travel_start_date: formatDateISO(new Date(data.travel_start_date || "")),
                    destinations: Array.from(destinations),
                },
            })
            .then((response) => {
                addAlert("Se ha enviado la solicitud al proveedor.", "success");
                onSubmit && onSubmit(response.data);
            })
            .catch(() => {
                addAlert("Ha habido un error enviado la solicitud.", "error");
                // TODO
            })
            .finally(() => {
                visibleHandler && visibleHandler(false);
            });
    };

    // Fetch countries and interests only once, only if we've accessed the client's detail view
    useEffect(() => {
        if (countries.length === 0) {
            getModelRef("countries").then(
                (retrievedCountries) => retrievedCountries && setCountries(retrievedCountries)
            );
        }
        if (interests.length === 0) {
            getModelRef("interests").then(
                (retrievedInterests) => retrievedInterests && setInterests(retrievedInterests)
            );
        }
    }, [countries, interests, getModelRef]);

    return (
        <Modal visible={visible}>
            <div className="flex flex-col items-center justify-center h-full p-4 sm:p-8 space-y-10">
                <div className="flex flex-col space-y-5 w-full">
                    <h2 className="text-2xl font-bold font-sans">
                        {newRequest ? <>Solicitar itinerario a proveedor</> : <>Solicitud enviada al proveedor</>}
                    </h2>
                    {newRequest && (
                        <p className="text-xs text-grey">
                            Selecciona a tu receptivo de confianza y solicita un presupuesto a medida.
                        </p>
                    )}
                </div>
                <div className="grid grid-cols-6 gap-4 w-full">
                    <div className="col-span-6 md:col-span-3">
                        <TextInput
                            id="provider"
                            type="text"
                            label="Proveedor"
                            placeholder="Busca tu proveedor"
                            value={provider?.name || ""}
                            onInteract={() => {
                                setIsProviderSelectModalVisible(!isProviderSelectModalVisible);
                            }}
                            disabled={!newRequest}
                        />
                    </div>
                    <div className="col-span-6 md:col-span-3 flex justify-between">
                        <TagGroup
                            title="Destinos"
                            values={Array.from(destinations)}
                            type="yellow"
                            onDelete={
                                newRequest &&
                                ((id) => {
                                    const country = countries.find((c) => c.id === id);
                                    if (country) {
                                        const newDestinations = new Set(
                                            Array.from(destinations).filter((dest) => dest.id !== country.id)
                                        );
                                        setDestinations(newDestinations);
                                    }
                                })
                            }
                        />
                        {newRequest && (
                            <div className="bg-yellow rounded-md flex justify-center items-center shadow px-4 h-8 min-w-[124px]">
                                <button
                                    className="text-white text-xs"
                                    type="button"
                                    onClick={() => setIsCountrySelectModalVisible(true)}
                                >
                                    Añadir destino <span className="text-lg relative top-0.5 left-0.5"> + </span>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="col-span-3 md:col-span-1">
                        <TextInput
                            id="amount_travelers"
                            type="number"
                            defaultValue={data.amount_travelers?.toString()}
                            label="Nº Personas"
                            onChange={(ev) => {
                                const value = ev.currentTarget.value;
                                clearTimeout(textInputTimer);
                                textInputTimer = setTimeout(() => {
                                    setData({ ...data, amount_travelers: parseInt(value, 10) });
                                }, 600);

                                return () => clearTimeout(textInputTimer);
                            }}
                            disabled={!newRequest}
                        />
                    </div>
                    <div className="col-span-3 md:col-span-1">
                        <Selection
                            id="travel_type"
                            size="small"
                            label="Tipo de viaje"
                            choices={travelTypes}
                            defaultValue={getChoice(data.travel_type || "", travelTypes)}
                            onChange={(choice) => {
                                setData({ ...data, travel_type: choice.value });
                            }}
                            disabled={!newRequest}
                        />
                    </div>
                    <div className="col-span-3 md:col-span-1">
                        <Selection
                            id="traveler_type"
                            size="small"
                            label="Tipo de viajero"
                            choices={travelerTypes}
                            defaultValue={getChoice(data.traveler_type || "", travelerTypes)}
                            onChange={(choice) => {
                                setData({ ...data, traveler_type: choice.value });
                            }}
                            disabled={!newRequest}
                        />
                    </div>
                    <div className="col-span-3 md:col-span-1">
                        <DatetimePicker
                            id="travel_start_time"
                            label="Fecha de viaje"
                            defaultValue={data.travel_start_date}
                            onChange={(selectedDates) => {
                                if (selectedDates.length === 1) {
                                    setData({
                                        ...data,
                                        travel_start_date: new Date(formatDateISO(selectedDates[0])),
                                    });
                                }
                            }}
                            disabled={!newRequest}
                        />
                    </div>
                    <div className="col-span-3 md:col-span-1">
                        <TextInput
                            id="travel_days"
                            type="number"
                            label="Nº Días"
                            defaultValue={requestData.travel_days?.toString() || "1"}
                            onChange={(ev) => {
                                const value = parseInt(ev.currentTarget.value, 10);
                                clearTimeout(textInputTimer);
                                textInputTimer = setTimeout(() => {
                                    setData({ ...data, travel_days: value });
                                    dispatch(dataPending());
                                }, 600);

                                return () => clearTimeout(textInputTimer);
                            }}
                            disabled={!newRequest}
                        />
                    </div>
                    <div className="col-span-3 md:col-span-1">
                        <TextInput
                            id="budget"
                            type="number"
                            label="Presupuesto por persona"
                            defaultValue={data.budget?.toString()}
                            onChange={(ev) => {
                                const value = parseFloat(ev.currentTarget.value);
                                clearTimeout(textInputTimer);
                                textInputTimer = setTimeout(() => {
                                    setData({ ...data, budget: value });
                                    dispatch(dataPending());
                                }, 600);

                                return () => clearTimeout(textInputTimer);
                            }}
                            disabled={!newRequest}
                        />
                    </div>
                    <div className="col-span-6 md:col-span-3">
                        <div className="flex flex-col space-y-4">
                            <TextInput
                                id="reference"
                                type="text"
                                label="Referencia"
                                defaultValue={requestData.reference}
                                onChange={(ev) => {
                                    const value = ev.currentTarget.value;
                                    clearTimeout(textInputTimer);
                                    textInputTimer = setTimeout(() => {
                                        setData({ ...data, reference: value });
                                        dispatch(dataPending());
                                    }, 600);

                                    return () => clearTimeout(textInputTimer);
                                }}
                                disabled={!newRequest}
                            />
                            <TextInput
                                id="comments"
                                type="text"
                                label="Comentarios"
                                defaultValue={requestData.comments}
                                lines={6}
                                onChange={(ev) => {
                                    const value = ev.currentTarget.value;
                                    clearTimeout(textInputTimer);
                                    textInputTimer = setTimeout(() => {
                                        setData({ ...data, comments: value });
                                        dispatch(dataPending());
                                    }, 600);

                                    return () => clearTimeout(textInputTimer);
                                }}
                                disabled={!newRequest}
                            />
                        </div>
                    </div>
                    <div className="col-span-6 md:col-span-3">
                        <div className="flex flex-col">
                            <TagGroup
                                title="Preferencias del cliente"
                                values={data.interests || []}
                                type="yellow"
                                onDelete={newRequest ? (id) => handleDeleteTag(id) : undefined}
                            />
                            <TagGroup
                                title="Selecciona otras preferencias"
                                values={interests.filter(
                                    (interest) => !data.interests?.map((i) => i.id).includes(interest.id)
                                )}
                                type="grey"
                                onClick={newRequest ? (id) => handleAddTag(id) : undefined}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col-reverse sm:flex-row w-full justify-end flex-wrap gap-2">
                    <div className="w-full sm:w-auto">
                        <Button
                            label="Cancelar"
                            type="btn_transparent"
                            onClick={() => {
                                dispatch(saveData());
                                visibleHandler && visibleHandler(false);
                            }}
                            extraClass="w-full h-[54px] sm:h-auto"
                        />
                    </div>
                    {newRequest && (
                        <div className="w-full sm:w-auto">
                            <Button
                                type="btn_dark"
                                label="Enviar"
                                onClick={handleSubmit}
                                extraClass="w-full h-[54px] sm:h-auto"
                            />
                        </div>
                    )}
                </div>
            </div>

            {/* Submodals */}
            <ClientSelectModal
                visible={isProviderSelectModalVisible}
                visibleHandler={setIsProviderSelectModalVisible}
                contact_type="provider"
                onAccept={(selected) => setProvider({ id: selected.id, name: selected.full_name })}
            />
            <CountrySelectModal
                visible={isCountrySelectModalVisible}
                visibleHandler={setIsCountrySelectModalVisible}
                multiselection
                onAccept={(selected: ModelRef[]) => {
                    const newDestinations = new Set(destinations);
                    selected.forEach((c) => newDestinations.add(c));
                    setDestinations(newDestinations);
                }}
            />
        </Modal>
    );
};

export default RequestModal;
