import jwtDecode, { JwtPayload } from "jwt-decode";
import React, { createContext, useContext, useState } from "react";
import axiosApi, { TOKEN_KEY } from "./axiosApi";
import { LoginInfo, RegisterInfo } from "./types";

interface Token {
    access: string;
    refresh: string;
}

interface MokenJwtPayload extends JwtPayload {
    user_id: number;
    contact_id: number;
    company_admin: boolean;
}

interface AuthContext {
    token: Token | null;
    logIn: (username: string, password: string) => Promise<LoginInfo>;
    logOut: () => void;
    register: (
        password: string,
        name: string,
        surname: string,
        email: string,
        phone: string,
        company_name: string,
        official_name: string,
        vat: string,
        company_country: number,
        company_size: string,
        company_type: string
    ) => Promise<RegisterInfo>;
    reset: (email: string) => Promise<LoginInfo>;
    resetConfirm: (resetToken: string, password: string) => Promise<LoginInfo>;
    emailConfirm: (confirmToken: string) => Promise<LoginInfo>;
    invite: (name: string, surname: string, email: string) => Promise<LoginInfo>;
    user: number | null;
    contact: number | null;
    isCompanyAdmin: boolean;
}

const authContext = createContext<AuthContext>({} as AuthContext);

/**
 * Hook for components to get the user object and re-render when it changes.
 */
export const useAuth = (): AuthContext => useContext(authContext);

/**
 * Provider hook to handle auth state and methods for login, logout, etc.
 */
const useAuthProvider = () => {
    // User is stored as the JWT token (access and refresh)
    const cachedToken = localStorage.getItem(TOKEN_KEY);
    const parsedToken: Token | null = cachedToken && JSON.parse(cachedToken);
    const tokenPayload: MokenJwtPayload | null = parsedToken && jwtDecode<MokenJwtPayload>(parsedToken.access);
    const [token, setToken] = useState<Token | null>(parsedToken);
    const [user, setUser] = useState<number | null>(tokenPayload && tokenPayload.user_id); // User ID gotten from the JWT payload
    const [contact, setContact] = useState<number | null>(tokenPayload && tokenPayload.contact_id); // Contact ID gotten from the JWT payload
    const [isCompanyAdmin, setIsCompanyAdmin] = useState(tokenPayload ? tokenPayload.company_admin : false);

    const logIn = async (username: string, password: string) =>
        axiosApi
            .post("/token/", { username, password })
            .then((response) => {
                if (response.data.access) {
                    localStorage.setItem(TOKEN_KEY, JSON.stringify(response.data));
                    setToken(response.data);
                    const payload = jwtDecode<MokenJwtPayload>(response.data.access);
                    setUser(payload.user_id);
                    setContact(payload.contact_id);
                    setIsCompanyAdmin(payload.company_admin);
                    return { success: true };
                }
                // TODO: Check badly formatted response
                return {
                    success: false,
                    error: "No ha sido posible autenticar, pruebe otra vez en unos minutos o contacte con soporte",
                };
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 400) {
                        if (err.response.data.username) {
                            return { success: false, error: "El campo 'nombre de usuario' no puede estar vacío" };
                        }
                        if (err.response.data.password) {
                            return { success: false, error: "El campo 'contraseña' no puede estar vacío" };
                        }
                        return { success: false, error: "Datos formateados incorrectamente" };
                    }
                    if (err.response.status === 401) {
                        return { success: false, error: "Credenciales incorrectas" };
                    }
                }
                return { success: false, error: "Error procesando datos, revisa el formulario" };
            });

    const logOut = () => {
        localStorage.removeItem(TOKEN_KEY);
        setToken(null);
    };

    const register = async (
        password: string,
        name: string,
        surname: string,
        email: string,
        phone: string,
        company_name: string,
        official_name: string,
        vat: string,
        company_country: number,
        company_size: string,
        company_type: string
    ) =>
        axiosApi
            .post("/user/register/", {
                password,
                name,
                surname,
                email,
                phone,
                company_name,
                official_name,
                vat,
                company_country,
                company_size,
                company_type,
            })
            .then(() => {
                window.location.href = "/login";
                return { success: true };
            })
            .catch((err) => ({ success: false, error: err.response }));

    const reset = async (email: string) =>
        axiosApi
            .post("/user/password_reset/", {
                email,
            })
            .then(() => ({ success: true }))
            .catch((err) => ({ success: false, error: err.message }));

    const resetConfirm = async (resetToken: string, password: string) =>
        axiosApi
            .post("/user/password_reset/confirm/", {
                token: resetToken,
                password,
            })
            .then(() => ({ success: true }))
            .catch((err) => ({ success: false, error: err.message }));

    const emailConfirm = async (confirmToken: string) =>
        axiosApi
            .post("/user/email_confirm/", {
                token: confirmToken,
            })
            .then(() => ({ success: true }))
            .catch((err) => ({ success: false, error: err.message }));

    const invite = async (name: string, surname: string, email: string) =>
        axiosApi
            .post("/user/invite/", {
                name,
                surname,
                email,
            })
            .then(() => ({ success: true }))
            .catch((err) => ({ success: false, error: err.message }));

    return {
        token,
        logIn,
        logOut,
        register,
        reset,
        resetConfirm,
        emailConfirm,
        invite,
        user,
        contact,
        isCompanyAdmin,
    };
};

interface AuthProviderProps {
    children: React.ReactNode;
}

/**
 * Provider component that makes the auth object available to all children components.
 */
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const auth = useAuthProvider();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
