import React from "react";
import { ContactType } from "../types";
import Avatar from "./Avatar";
import { ButtonProps } from "./Button";
import Rating from "./Rating";
import SidePanel from "./SidePanel";
import TagGroup from "./TagGroup";

export interface ClientPreviewProps {
    /**
     * Tells if side panel is open or not
     */
    isSidePanelOpened: boolean;
    /**
     * Optional URL of the image to show in the avatar.
     */
    image?: string;

    /**
     * Alternative text for the avatar.
     */
    altText: string;

    /**
     * Type of client. Determines the format and fields that will appear.
     */
    type: ContactType;

    /**
     * Name shown on top of the container in a large font.
     */
    name: string;

    /**
     * Rating in stars from 1 to 5.
     */
    rating?: number;

    /**
     * Number of ratings to show next to the stars.
     */
    numRatings?: number;

    /**
     * The company's description, shown below the name and avatar.
     */
    description?: string;

    /**
     * List of interests. For traveler type clients only.
     */
    interests?: Array<string>;

    /**
     * Travel type. For traveler type clients only.
     */
    travelType?: string;

    /**
     * Traveler type. For traveler type clients only.
     */
    travelerType?: string;

    /**
     * Number of reservations with this client. For travelers, or agencies (if the user
     * is a provider).
     */
    numReservations?: number;

    /**
     * Number of confirmed reservations with this client. For travelers, or agencies (if the user
     * is a provider).
     */
    numConfirmations?: number;

    /**
     * Latest reservation with this client. For travelers, or agencies (if the user
     * is a provider).
     */
    lastTrip?: Date;

    /**
     * List of specialties. For companies (agencies and providers).
     */
    specialties?: Array<string>;

    /**
     * List of languages. For companies (agencies and providers).
     */
    languages?: Array<string>;

    /**
     * List of destinations. For providers only.
     */
    destinations?: Array<string>;

    acceptButton?: React.ReactElement<ButtonProps>;
    cancelButton?: React.ReactElement<ButtonProps>;
    connectButton?: React.ReactElement<ButtonProps>;

    /**
     * What happens when the cross button on the top right is clicked.
     */
    onClose?: () => void;
}

/**
 * A fixed width container which contains some info about a client or provider.
 */
const ClientPreview: React.FC<ClientPreviewProps> = ({
    isSidePanelOpened,
    image,
    altText,
    type,
    name,
    rating,
    numRatings,
    description,
    interests,
    travelType,
    travelerType,
    numReservations,
    numConfirmations,
    lastTrip,
    specialties,
    languages,
    destinations,
    acceptButton,
    cancelButton,
    connectButton,
}) => {
    const lastTripStr =
        lastTrip &&
        `${lastTrip.toLocaleString("default", { month: "long" })} '${lastTrip.getFullYear().toString().substring(2)}`;
    return (
        <SidePanel isSidePanelOpened={isSidePanelOpened}>
            <div className="flex flex-col">
                <div className="flex items-center space-x-5 mb-5">
                    <Avatar image={image} altText={altText} size="lg" />
                    <div className="flex flex-col">
                        <h3 className="text-xl font-black">{name}</h3>
                        {type !== "traveler" && rating !== undefined && numRatings !== undefined && (
                            <div className="pl-2 mt-0.5">
                                <Rating rating={rating} numRatings={numRatings} />
                            </div>
                        )}
                    </div>
                </div>

                {type !== "traveler" && description && <p className="text-sm mb-7">{description}</p>}

                {type !== "provider" &&
                    numReservations !== undefined &&
                    numConfirmations !== undefined &&
                    lastTrip !== undefined && (
                        <div className="grid grid-cols-3 gap-3 mb-10">
                            <span className="text-xs text-grey font-semibold">Solicitudes</span>
                            <span className="text-xs text-grey font-semibold">Confirmaciones</span>
                            <span className="text-xs text-grey font-semibold">Último viaje</span>
                            <span className="font-bold">{numReservations || "-"}</span>
                            <span className="font-bold">{numConfirmations || "-"}</span>
                            <span className="font-bold">{lastTripStr || "-"}</span>
                        </div>
                    )}

                {type === "traveler" && (
                    <div className="space-y-4">
                        {interests && interests.length > 0 && (
                            <TagGroup title="Preferencias" values={interests} type="yellow" />
                        )}
                        {travelType && <TagGroup title="Tipo de viaje" values={[travelType]} type="yellow" />}
                        {travelerType && <TagGroup title="Tipo de viajero" values={[travelerType]} type="yellow" />}
                    </div>
                )}

                {type !== "traveler" && (
                    <div className="space-y-4">
                        {type === "provider" && destinations && destinations.length > 0 && (
                            <TagGroup title="Destinos" values={destinations} type="yellow" />
                        )}
                        {specialties && specialties.length > 0 && (
                            <TagGroup title="Especialistas en" values={specialties} type="yellow" />
                        )}
                        {languages && languages.length > 0 && (
                            <TagGroup title="Atendemos en" values={languages} type="yellow" />
                        )}
                    </div>
                )}

                <div className="flex grow items-end justify-evenly mt-10 space-x-5">
                    {connectButton || (
                        <>
                            {cancelButton}
                            {acceptButton}
                        </>
                    )}
                </div>
            </div>
        </SidePanel>
    );
};

export default ClientPreview;
